import { combineReducers } from 'redux';
import merge from 'lodash/merge';
import paginate from 'composer-blocks/lib/reducers/paginate';
import * as siteActions from 'composer-blocks/lib/actions/site';
import * as otherDataActions from '../actions/otherData';
import { COMPOSER_SETTINGS_SUCCESS } from "composer-blocks/lib/actions/site";
import { UPDATE_ALLOW_INDEXING } from '../actions/allowSearchEngineIndexing';

const whiteListedEntities = ['event_files'];

const composerCategory = (state = null, action) => {
  switch (action.type) {
    case siteActions.COMPOSER_SETTINGS_SUCCESS:
      return Object.values(action.response.entities.composer_settings)[0].composerCategory;
    default:
      return state;
  }
};

const useEventTimezone = (state = null, action) => {
  switch (action.type) {
    case siteActions.COMPOSER_SETTINGS_SUCCESS:
      return Object.values(action.response.entities.composer_settings)[0].useEventTimezone;
    default:
      return state;
  }
};

const allowSearchEngineIndexing = (state = null, action) => {
  switch (action.type) {
    case UPDATE_ALLOW_INDEXING:
      return action.value
    case COMPOSER_SETTINGS_SUCCESS:
      return Object.values(action.response.entities.composer_settings)[0].allowSearchEngineIndexing;
    default:
      return state;
  }
};


const entities = (state = {}, action) => {
  if (action.response && action.response.entities) {
    const fetchedData = whiteListedEntities.reduce((result, key) => {
      result[key] = action.response.entities[key];
      return result;
    }, {});
    return merge({}, state, fetchedData);
  }

  return state;
};

const pagination = combineReducers({
  event_files: paginate([ otherDataActions.EVENT_FILES_REQUEST, otherDataActions.EVENT_FILES_SUCCESS, otherDataActions.EVENT_FILES_FAILURE ]),
});

export default combineReducers({
  composerCategory,
  useEventTimezone,
  allowSearchEngineIndexing,
  entities,
  pagination
});
