import { INSERT_FOOTER, DELETE_FOOTER } from "../actions/blocks";
import { COMPOSER_SETTINGS_SUCCESS } from "composer-blocks/lib/actions/site";
import { getEntityFromResponse } from 'composer-blocks/lib/helpers/api';

const initialState = {
  blocks: [],
};
const footers = (state = initialState, action) => {
  switch (action.type) {
    case INSERT_FOOTER: {
      const { block } = action;
      const { id } = block;
      let newBlockList = state.blocks ? state.blocks.slice() : [];
      const index = newBlockList.length;
      newBlockList.splice(index, 0, id);

      return {
        ...state,
        blocks: newBlockList
      };
    }

    case DELETE_FOOTER: {
      const { id } = action;
      let newBlockList = state.blocks.slice();
      const oldIndex = newBlockList.indexOf(id);

      newBlockList.splice(oldIndex, 1);

      return {
        ...state,
        blocks: newBlockList
      };
    }

    case COMPOSER_SETTINGS_SUCCESS: {
      return getEntityFromResponse(action, 'footers') || state;
    }

    default:
      return state;
  }
};

export default footers;
