import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FindInPage from '@material-ui/icons/FindInPage';
import Done from '@material-ui/icons/Done';
import TranslatableIconButton from '../components/TranslatableIconButton';
import { changeAllowSearchEngineIndexingState, updateAllowSearchEngineIndexing } from "../actions/allowSearchEngineIndexing";
import injectSheet from 'react-jss';
import { translate } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import { isNullOrUndefined } from '../helpers/misc'



const styles = theme => ({
  settingHeader:{
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .setting-name':{
      paddingLeft: '1rem',
      fontSize: '24px'
    },
  },
  settingInputs:{
    marginBottom: '2rem',
    '& .top-panel':{
      display: 'flex',
      justifyContent: 'center',
    },
  }
});


class IndexingSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      allowIndexing: isNullOrUndefined(props.allowSearchEngineIndexing) ? true : props.allowSearchEngineIndexing,
      hasChanged: false
    };
  }

  componentDidUpdate(prevProps){
    if(isNullOrUndefined(prevProps.allowSearchEngineIndexing) && !isNullOrUndefined(this.props.allowSearchEngineIndexing)){
      this.initialFetchAllowIndexing();
    }
  }

  componentDidMount() {
    this.initialFetchAllowIndexing();
  }

  isNullOrUndefined= (element) => {
    return element === null || typeof element === 'undefined'
  }

  initialFetchAllowIndexing = () => {
    if(!isNullOrUndefined(this.props.allowSearchEngineIndexing)){
      this.setState({
        allowIndexing: this.props.allowSearchEngineIndexing
      });
    }
  }

  changeCheckboxState = (event) => this.setState({ allowIndexing: event.target.checked, hasChanged: true })

  handleUpdate = () => {
    const { allowIndexing } = this.state;
    this.props.changeAllowSearchEngineIndexingState(allowIndexing);
    this.props.updateAllowSearchEngineIndexing();
    this.setState({ hasChanged: false })
  }

  render(){
    const { classes, t, accountId } = this.props;

    // HACK
    if (['10039'].includes(accountId)) return null;

    return(
      <React.Fragment>
        <div className={classes.settingHeader}>
          <p className='font-icon'><FindInPage/> </p>
          <p className='setting-name'>{t('labels.indexing')}</p>
        </div>
        <div className={classes.settingInputs}>
          <div className='top-panel'>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    classes={{root: classes.root}}
                    checked={this.state.allowIndexing}
                    onChange={this.changeCheckboxState}
                  />
                }
                label={t('labels.allow_indexing')}
              />
            </FormGroup>
            <TranslatableIconButton
              tPath={"buttons.update"}
              onClick={this.handleUpdate}
              Icon={Done}
              buttonProps={{ disabled: (!this.state.hasChanged)}}
              divButtonProps={{ style: {display:'inline-block', height: '48px', marginLeft: '1rem'}}}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ otherData, site: { entities: { events } } }) => ({
  allowSearchEngineIndexing: otherData.allowSearchEngineIndexing,
  accountId: events && events[Object.keys(events)[0]].account.id,
})


const mapDispatchToProps = {
  changeAllowSearchEngineIndexingState,
  updateAllowSearchEngineIndexing
}

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(translate('Forms')(IndexingSettings)));
