import { CUSTOM } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'custom_6',
  type: CUSTOM,
  name: 'Custom with background image',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/custom/custom_6.png',
  rootContent: {
    type: contentTypes.ROW,
    properties: {
      backgroundImage: {type: 'css', value: 'url(https://eventtia-composer.s3.amazonaws.com/assets/images/large/landscape_lake.png)', editable:true},
      backgroundRepeat: {type: 'css', value: 'no-repeat'},
      backgroundSize: {type: 'css', value:'cover'},
      color: {type: 'css', value: ''},
      fontWeight: {type: 'css', value: 'bold'},
      padding: {type: 'css', value: '0'},
      display: {type: 'css', value: 'flex'},
      justifyContent: {type: 'css', value: 'flex-start'}
    },
    contents: [

      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 5},
          medium: {type: 'responsive', value:12 },
          small: {type: 'responsive', value: 12},
          backgroundColor: {type: 'css', value: 'rgba(49, 100, 109,0.4)'},
          padding: {type: 'css', value: '0 0 0 20%'},
          margin: {type: 'css', value: 0},
          display: {type: 'css', value:'flex'},
          justifyContent: {type: 'css', value:'flex-end'}
        },
        contents: [
          {
            type: contentTypes.TEXT,
            value: 'OUR SERVICES',
            properties: {
              color: {type: 'css', value: '#57E2C2', editable: true},
              fontWeight: {type: 'css', value: 'normal'},
              padding: {type: 'css', value: '0 2rem 1rem 0'},
              fontSize: {type: 'css', value: '28px'},
              margin: {type:'css', value: '15rem 1rem 20% 0'},
              }
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s',
            properties: {
              margin: {type: 'css', value: '0 2rem 2rem 0'},
              color: {type: 'css', value: 'white', editable: true},
              fontWeight: {type: 'css', value: 'normal'},
              padding: {type: 'css', value: '0 2rem 1rem 0'},
              fontSize: {type: 'css', value: '18px'},
              borderStyle: {type:'css', value:'none none solid none'},
              borderColor: {type:'css' , value: '#57E2C2', editable: true}
            }
          }
        ]
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 5},
          medium: {type: 'responsive', value: 12},
          small: {type: 'responsive', value: 12},
          backgroundColor: {type: 'css', value: 'rgba(255,255,255,0.8)'},
          margin: {type:'css', value: '10rem 0 5rem 0'}
        },
        contents: [
          {
            type: contentTypes.ROW,
            properties: {

            },
            contents: [
              {
                type: contentTypes.COLUMN,
                properties: {
                  large: {type: 'responsive', value: 6},
                  medium: {type: 'responsive', value: 6},
                  small: {type: 'responsive', value: 12},
                  margin: {type:'css', value: 0},
                  padding: {type:'css', value: '1rem'}
                },
                contents: [
                  {
                    type: contentTypes.IMAGE,
                    properties: {
                      src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/icons/icon_king.png', editable:true},
                      objectFit: {type: 'css', value: 'contain'},
                      width: {type: 'css', value:'4rem'},
                      height: {type: 'css', value:'4rem'},
                      margin: {type: 'css', value: '0 1rem 1rem 1rem'},
                    },
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'BRANDING',
                    properties: {
                      margin: {type: 'css', value: '0 1rem 1rem 1rem'},
                      color: {type: 'css', value: 'black', editable: true},
                      fontWeight: {type: 'css', value: 'bold'},
                      padding: {type: 'css', value: '0 2rem 1rem 0'},
                      fontSize: {type: 'css', value: '24px'},
                      borderStyle: {type:'css', value:'none none solid none'},
                      borderColor: {type:'css' , value: '#57E2C2', editable: true}
                    }
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.',
                    properties: {
                      margin: {type: 'css', value: '1rem'},
                      color: {type: 'css', value: '#565656', editable: true},
                      fontWeight: {type: 'css', value: 'normal'},
                      padding: {type: 'css', value: '0 2rem 1rem 0'},
                      fontSize: {type: 'css', value: '14px'}
                    }

                  }
                ]
              },
              {
                type: contentTypes.COLUMN,
                properties: {
                  large: {type: 'responsive', value: 6},
                  medium: {type: 'responsive', value: 6},
                  small: {type: 'responsive', value: 12},
                  margin: {type:'css', value: 0},
                  padding: {type:'css', value: '1rem'}
                },
                contents: [
                  {
                    type: contentTypes.IMAGE,
                    properties: {
                      src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/icons/icon_camera.png', editable:true},
                      objectFit: {type: 'css', value: 'contain'},
                      width: {type: 'css', value:'4rem'},
                      height: {type: 'css', value:'4rem'},
                      margin: {type: 'css', value: '0 1rem 1rem 1rem'}
                    },
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'PHOTOGRAPHY',
                    properties: {
                      margin: {type: 'css', value: '0 1rem 1rem 1rem'},
                      color: {type: 'css', value: 'black', editable: true},
                      fontWeight: {type: 'css', value: 'bold'},
                      padding: {type: 'css', value: '0 2rem 1rem 0'},
                      fontSize: {type: 'css', value: '24px'},
                      borderStyle: {type:'css', value:'none none solid none'},
                      borderColor: {type:'css' , value: '#57E2C2', editable: true}
                    }
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.',
                    properties: {
                      margin: {type: 'css', value: '1rem'},
                      color: {type: 'css', value: '#565656', editable: true},
                      fontWeight: {type: 'css', value: 'normal'},
                      padding: {type: 'css', value: '0 2rem 1rem 0'},
                      fontSize: {type: 'css', value: '14px'}
                    }

                  }
                ]
              },
              {
                type: contentTypes.COLUMN,
                properties: {
                  large: {type: 'responsive', value: 6},
                  medium: {type: 'responsive', value: 6},
                  small: {type: 'responsive', value: 12},
                  margin: {type:'css', value: 0},
                  padding: {type:'css', value: '1rem'}
                },
                contents: [
                  {
                    type: contentTypes.IMAGE,
                    properties: {
                      src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/icons/icon_gear.png', editable:true},
                      objectFit: {type: 'css', value: 'contain'},
                      width: {type: 'css', value:'4rem'},
                      height: {type: 'css', value:'4rem'},
                      margin: {type: 'css', value: '0 1rem 1rem 1rem'}
                    },
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'GENERATE IDEAS',
                    properties: {
                      margin: {type: 'css', value: '0 1rem 1rem 1rem'},
                      color: {type: 'css', value: 'black', editable: true},
                      fontWeight: {type: 'css', value: 'bold'},
                      padding: {type: 'css', value: '0 2rem 1rem 0'},
                      fontSize: {type: 'css', value: '24px'},
                      borderStyle: {type:'css', value:'none none solid none'},
                      borderColor: {type:'css' , value: '#57E2C2', editable: true}
                    }
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.',
                    properties: {
                      margin: {type: 'css', value: '1rem'},
                      color: {type: 'css', value: '#565656', editable: true},
                      fontWeight: {type: 'css', value: 'normal'},
                      padding: {type: 'css', value: '0 2rem 1rem 0'},
                      fontSize: {type: 'css', value: '14px'}
                    }

                  }
                ]
              },
              {
                type: contentTypes.COLUMN,
                properties: {
                  large: {type: 'responsive', value: 6},
                  medium: {type: 'responsive', value: 6},
                  small: {type: 'responsive', value: 12},
                  margin: {type:'css', value: 0},
                  padding: {type:'css', value: '1rem'}
                },
                contents: [
                  {
                    type: contentTypes.IMAGE,
                    properties: {
                      src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/icons/icon_computer.png', editable:true},
                      objectFit: {type: 'css', value: 'contain'},
                      width: {type: 'css', value:'4rem'},
                      height: {type: 'css', value:'4rem'},
                      margin: {type: 'css', value: '0 1rem 1rem 1rem'}
                    },
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'PROGRAMMING',
                    properties: {
                      margin: {type: 'css', value: '0 1rem 1rem 1rem'},
                      color: {type: 'css', value: 'black', editable: true},
                      fontWeight: {type: 'css', value: 'bold'},
                      padding: {type: 'css', value: '0 2rem 1rem 0'},
                      fontSize: {type: 'css', value: '24px'},
                      borderStyle: {type:'css', value:'none none solid none'},
                      borderColor: {type:'css' , value: '#57E2C2', editable: true}
                    }
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.',
                    properties: {
                      margin: {type: 'css', value: '1rem'},
                      color: {type: 'css', value: '#565656', editable: true},
                      fontWeight: {type: 'css', value: 'normal'},
                      padding: {type: 'css', value: '0 2rem 1rem 0'},
                      fontSize: {type: 'css', value: '14px'}
                    }

                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
}
