import { REGISTRATION_TYPES } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'registration_types_4',
  type: REGISTRATION_TYPES,
  name: 'Registration type rows',
    thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/registration_types/registration_types_4.jpg',
  rootContent: {

        type: contentTypes.ROW,
        contents: [{

          type: contentTypes.COLUMN,
          properties: {
            medium: {type: 'responsive', value: 12},
            large: {type: 'responsive', value: 12}
          },
          contents: [
            {
              type: contentTypes.REGISTRATION_TYPE_ROWS,
              properties: {
                buttonColor: {type: 'custom', value: '#00be4e', editable: true},
                buttonTextColor: {type: 'custom', value: '#FFFFFF', editable: true},
                color: {type: 'custom', value: '#303030', editable: true},
                accentColor: {type: 'custom', value: '#00be4e', editable: true},
                backgroundColor: {type: 'custom', value: '#FFFFFF', editable: true},
                horizontalLineColor: {type: 'custom', value: '#dcd8d8', editable: true},
                showPrice: {type: 'custom', value: true, editable: true},
                showVAT: {type: 'custom', value: true, editable: true},
                showAvailability: {type: 'custom', value: true, editable: true},
                registerButtonLabel: {type: 'custom', value: '', editable: true},

              }
            }

          ]
        }]


  }
}
