import React, { Component } from 'react';
import injectSheet from 'react-jss';

const styles = {
  overlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'absolute',
    zIndex: 1900,
    top: 0,
    backgroundColor: 'rgba(0,0,0,0.6)'
  },
  container: {
    margin: 'auto',
    padding: 16,
    backgroundColor: 'white'
  }
};

class Modal extends Component {

  componentDidMount() {
    document.onkeydown = (event) => {
      if (event.keyCode === 27) this.props.onCancel();
    };
  }

  componentWillUnmount() {
    document.onkeydown = null;
  }

  render() {
    const { classes, title, message, onAccept, onCancel } = this.props;

    return (
      <div className={classes.overlay}>
        <div className={classes.container}>
          <h1>{title}</h1>
          <p>{message}</p>
          <button onClick={onAccept}>Yes</button>
          <button onClick={onCancel}>No</button>
        </div>
      </div>
    );
  }
}

export default injectSheet(styles)(Modal);
