import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import injectSheet from 'react-jss';
import BlockPlaceholder from '../components/BlockPlaceholder';

const styles = {
  BlockDropZone: {
    position: 'relative',
    overflow: 'visible',
    border: ({ canDrop, hovered }) => (canDrop && hovered) ? '5px solid rgba(50, 100, 255, 0.5)' : 'none',
  },
};

const targetSpec = {
  drop(props, monitor, component) {
    const { index, page } = props;
    if (!monitor.didDrop()) {
      return { index, page };
    }
  },
  canDrop(props, monitor) {
    return monitor.getItem().blockIndex !== props.index;
  }
};

const collectDrop = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop(),
    hovered: monitor.isOver(),
    otherItemIndex: monitor.getItem() && monitor.getItem().blockIndex,
    itemType: monitor.getItemType()
  }
};

const propTypes = {
  index: PropTypes.number.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  canDrop: PropTypes.bool.isRequired,
  hovered: PropTypes.bool.isRequired,
  isPlaceholder: PropTypes.bool,
};

class BlockDropZone extends Component {

  render() {
    const { hovered, canDrop, connectDropTarget, isPlaceholder, index, otherItemIndex, isNavbarDropZone, classes, isFooterDropZone} = this.props;
    const showPlaceholder = (isPlaceholder) || (isPlaceholder && isNavbarDropZone) || (isPlaceholder && isFooterDropZone) || (hovered && canDrop);
    const showPlaceholderAfter = showPlaceholder && otherItemIndex !== undefined && otherItemIndex < index;
    const showPlaceholderBefore = showPlaceholder && !showPlaceholderAfter;
    return connectDropTarget(
      <div className={classes.BlockDropZone}>
        {showPlaceholderBefore && <BlockPlaceholder isNavbarPlaceholder={isNavbarDropZone} isFooterPlaceholder={isFooterDropZone} />}
        {this.props.children}
        {showPlaceholderAfter && <BlockPlaceholder isNavbarPlaceholder={isNavbarDropZone} isFooterPlaceholder={isFooterDropZone}/>}
      </div>
    );
  }

}

BlockDropZone.propTypes = propTypes;

const droppableTypes = ({ isNavbarDropZone, isFooterDropZone }) => (isNavbarDropZone ? ['navbar-thumbnail'] : isFooterDropZone ? ['footer-thumbnail'] : ['thumbnail', 'block']);

const enhance = compose(
  DropTarget(droppableTypes, targetSpec, collectDrop),
  injectSheet(styles)
);

export default enhance(BlockDropZone);
