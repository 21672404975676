import React, { Component } from 'react';
import { connect } from 'react-redux';
import Gallery from '../components/Gallery';
import { fetchEventFiles } from '../actions/otherData';
import CircularProgress from '@material-ui/core/CircularProgress';

class UserImagesGallery extends Component {
  componentWillMount() {
    this.props.fetchEventFiles();
  }

  render() {
    const { eventFiles, pagination } = this.props;
    const { isFetching, idsByPage, links } = pagination;
    const currentPage = links.self;
    const images = !!idsByPage && !!idsByPage[currentPage] && idsByPage[currentPage].filter(id => eventFiles[id].isImage).map(id => ({
      name: eventFiles[id].name,
      thumb: eventFiles[id].urls.small,
      url: eventFiles[id].urls,
    }));

    return isFetching ? (
      <CircularProgress color="inherit" size={50}/>
    ) : !images ? (
      <div>There are no files associated with this event.</div>
    ) : (
      <Gallery
        {...this.props}
        fetchPage={this.props.fetchEventFiles}
        links={links}
        images={images}
      />
    );
  }
}


const mapStateToProps = ({ otherData }) => ({
  eventFiles: otherData.entities.event_files,
  pagination: otherData.pagination.event_files
});

const mapDispatchToProps = {
  fetchEventFiles
};

export default connect(mapStateToProps, mapDispatchToProps)(UserImagesGallery);
