import { NAVBAR } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'navbar_20',
  type: NAVBAR,
  name: 'Navbar 20',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/navbar/navbar_20.png',
  rootContent: {
    type: contentTypes.NAVBAR,
    properties: {
      backgroundColor: {type: 'css', value: '#B5B5B5',editable: true},
      color: {type: 'css', value: '#000000', editable: true},
      padding: {type: 'css', value: '0.5rem 1rem'},
      fontSize: {type: 'css', value: 'small', editable: true},
      logoSrc: {type: 'custom', value: '', editable: true},
      logoHeight: {type: 'custom', value: '3.5rem'},
      navItemHoverBorder: {type: 'custom', value: '#000000'},
      navItemActiveTextColor: {type: 'custom', value: '#ffffff'},
      navItemActiveBackground: {type: 'custom', value: '#000000'},
      navItemPadding: {type: 'custom', value: '0.5rem 1rem'},
      logoPosition: {type: 'custom', value: 'top'},
      socialMedia: {type: 'custom', value: true, editable: true},
      facebookLink: {type: 'custom', value: 'facebook.com', editable: true},
      twitterLink: {type: 'custom', value: 'twitter.com', editable: true},
      linkedInLink: {type: 'custom', value: 'linkedin.com', editable: true},
      instagramLink: {type: 'custom', value: 'instagram.com', editable: true},
      socialMediaIconColor: {type: 'custom', value: '#000000', editable: false},
    },
    contents: []
  }
}
