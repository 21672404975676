import { WORKSHOPS } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'workshops_5',
  type: WORKSHOPS,
  name: 'Activity names',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/workshops/workshops_5.jpg',
  rootContent: {
    type: contentTypes.WORKSHOPS_BY_NAME,
    properties: {
      workshopAccentColor: {type: 'custom', value: '#8677FB', editable: true},
    },
  }
}
