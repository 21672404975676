import { DELETE_PAGE } from "../actions/pages";
import { INSERT_BLOCK, DELETE_BLOCK, INSERT_NAVBAR, DELETE_NAVBAR, INSERT_FOOTER, DELETE_FOOTER } from "../actions/blocks";
import { INSERT_CONTENT, MOVE_CONTENT, DELETE_CONTENT } from "../actions/contents";
import { PUBLISH_MODIFIED_ITEMS, SAVE_MODIFIED_ITEMS } from "../actions/app";
import { COMPOSER_SETTINGS_SUCCESS } from "composer-blocks/lib/actions/site";
import { deleteWhere } from 'composer-blocks/lib/helpers/hasMany';
import { getEntityFromResponse } from 'composer-blocks/lib/helpers/api';

const byId = (state = {}, action) => {
  switch (action.type) {
    case INSERT_CONTENT: {
      let { contents, contentId, parentId, index } = action;
      let newChildContents = !!state[parentId].childContents ? state[parentId].childContents.slice() : [];
      if (!index) index = newChildContents.length;
      newChildContents.splice(index, 0, contentId);

      const newParent = {
        [parentId]: {
          ...state[parentId],
          childContents: newChildContents
        }
      }
      return {
        ...state,
        ...newParent,
        ...contents
      }
    }
    case MOVE_CONTENT: {
      const { oldParentId, newParentId, newIndex, id } = action;

      let newChildrenOfOldParent = state[oldParentId].childContents.slice();

      const oldIndex = newChildrenOfOldParent.indexOf(id);
      if (oldIndex === -1) {
        return state;
      }

      if (newParentId === oldParentId) {
        newChildrenOfOldParent.splice(oldIndex, 1);
        newChildrenOfOldParent.splice(newIndex, 0, id);
        return {
          ...state,
          [oldParentId]: {
            ...state[oldParentId],
            childContents: newChildrenOfOldParent
          },
        };
      } else {
        let newChildrenOfNewParent = state[newParentId].childContents.slice();
        newChildrenOfOldParent.splice(oldIndex, 1);
        newChildrenOfNewParent.splice(newIndex, 0, id);
        return {
          ...state,
          [id]: {
            ...state[id],
            parentId: newParentId
          },
          [oldParentId]: {
            ...state[oldParentId],
            childContents: newChildrenOfOldParent
          },
          [newParentId]: {
            ...state[newParentId],
            childContents: newChildrenOfNewParent
          },
        };
      }
    }
    case INSERT_NAVBAR:
    case INSERT_FOOTER:
    case INSERT_BLOCK: {
      const { contents } = action;

      return {
        ...state,
        ...contents
      };
    }
    case PUBLISH_MODIFIED_ITEMS:
    case SAVE_MODIFIED_ITEMS:
      return {
        ...state,
        ...action.modifiedContents
      };
    case DELETE_CONTENT: {
      const { id, childContents, parentId } = action;
      const newParent = {
        ...state[parentId],
      }
      const newChildContents = newParent.childContents.slice();
      const index = newChildContents.indexOf(id);
      newChildContents.splice(index, 1);
      newParent.childContents = newChildContents;
      return {
        ...deleteWhere(state, 'id', childContents),
        [parentId]: newParent
      };
    }
    case DELETE_PAGE:
    case DELETE_NAVBAR:
    case DELETE_FOOTER:
    case DELETE_BLOCK: {
      const { childContents } = action;
      return deleteWhere(state, 'id', childContents);
    }
    case COMPOSER_SETTINGS_SUCCESS: {
      return getEntityFromResponse(action, 'contents') || state;
    }
    default:
      return state;
  }
};

export default byId;
