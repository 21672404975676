import { saveComposerSetting } from './app';


export const updateAllowSearchEngineIndexing = () => {
  return (dispatch, getState) => {
    dispatch(saveComposerSetting(false));
  }
};

export const SET_ACTIVE_CONTENT = 'SET_ACTIVE_CONTENT';
export const setActiveContent = (contentId) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_ACTIVE_CONTENT,
      activeContentId: contentId
    });
  };
};

export const UPDATE_ALLOW_INDEXING = 'UPDATE_ALLOW_INDEXING';
export const changeAllowSearchEngineIndexingState = (value) => {
  return {
    type: UPDATE_ALLOW_INDEXING,
    value
  }
}
