import React, { Component } from 'react';
import injectSheet from 'react-jss';
import ColorLens from '@material-ui/icons/ColorLens';
import Done from '@material-ui/icons/Done';
import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import { SketchPicker } from 'react-color';
import { translate } from 'react-i18next';
import _ from 'lodash';
import TranslatableIconButton from '../TranslatableIconButton';
import TranslatableButton from '../TranslatableButton';
import FullScreenModal from '../FullScreenModal';

const COLOURS = 'Colours';

const styles = theme => ({
  ColorManager: {
    display: 'inline-block',
  },
  iconButton: {
    marginRight: '-0.75rem'
  },
  container: {
    display: 'flex',
    justifyContent:'flex-start',
    '& .left-panel':{
      padding:'1rem',
      width:'20rem',
    },
    '& .right-panel':{
      padding:'1rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    }
  },
  rowColor:{
    display: 'flex',
    justifyContent: 'space-between',
    width: '9.27rem',
    padding: '0.5rem 1rem',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: '5px',
    '& .color-preview': {
      width:'1.5rem',
      height:'1.5rem',
      borderRadius: '1rem',
      border: '0.125rem solid white',
    },
  }
});

class ColorManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      currentTab: COLOURS,
      inputValue: props.value || [{color: {r:0,g:0,b:0,a:1}}],
      currentIndex: 0,
    };
  }

  openModal = () => this.setState({ open: true })
  closeModal = () => this.setState({ open: false })
  setCurrentTab = (tabId) => () => this.setState({ currentTab: tabId })

  fillPreviewColor = (i) => {
    const { inputValue } = this.state;
    if(!!inputValue && !!inputValue[i]){
      const { r, g, b, a } = inputValue[i];
      return {backgroundColor: `rgba(${r},${g},${b},${a})`}
    }
    return {}
  }

  setColorPicker = (i) => {
    this.setState({
      currentIndex: i,
    })
  }

  onChange = () => {
    this.props.onChange(this.state.inputValue);
    this.closeModal();
  }

  discardChanges = () => {
    this.setState({
      inputValue: this.props.value || [{r:0,g:0,b:0,a:1}],
      currentIndex: 0,
    });
    this.closeModal();
  }

  addColor = () => {
    this.setState(oldState => {
      const newValue = _.cloneDeep(oldState.inputValue);
      newValue.push({r:0,g:0,b:0,a:1});
      return { inputValue: newValue, currentIndex: newValue.length - 1};
    });
  }

  deleteColor = (index) => () => {
    this.setState(oldState => {
      const newValue = _.cloneDeep(oldState.inputValue);
      newValue.splice(index, 1);
      return { inputValue: newValue, currentIndex:0 };
    });
  }

  changeState = (index) => (val) => {
    const value = val.rgb;
    this.setState(oldState => {
      const newValue = _.cloneDeep(oldState.inputValue);
      newValue[index] = value;
      return { inputValue: newValue };
    });
  }

  render() {
    const { classes, t } = this.props;
    const { currentTab, inputValue } = this.state;

    const tabs = {
      [COLOURS]: (
        <div className={classes.container}>
          <div className='left-panel'>
            {inputValue.length > 0 && (
              <SketchPicker
                label={t('labels.color')}
                color={this.state.inputValue[this.state.currentIndex]}
                onChange={this.changeState(this.state.currentIndex)}
                value={this.state.inputValue[this.state.currentIndex]}
              />
            )}
          </div>
          <div className='right-panel'>
            {inputValue.map((item, index) => (
              <div
                className={classes.rowColor}
                key={index}
                style={{backgroundColor: index === this.state.currentIndex ? '#3F4652' : '#242a34'}}
                onClick={() => this.setColorPicker(index)}
                >
                <div
                  className='color-preview'
                  style={this.fillPreviewColor(index)}
                  >
                </div>
                <TranslatableIconButton
                  tPath="buttons.delete"
                  onClick={this.deleteColor(index)}
                  Icon={Close}
                />
              </div>
            ))}
            <div className='button-panel'>
              <TranslatableButton
                onClick={this.addColor}
                leftIcon={<Add />}
                tPath="buttons.add_color"
              />
              <TranslatableButton
                onClick={this.onChange}
                leftIcon={<Done />}
                tPath="buttons.apply_changes"
              />
              <TranslatableButton
                onClick={this.discardChanges}
                leftIcon={<Close />}
                tPath="buttons.discard_changes"
              />
            </div>
          </div>
        </div>
      ),
    }

    return (
      <div className={classes.ColorManager}>
        <TranslatableIconButton tPath="buttons.manage_colours" onClick={this.openModal} Icon={ColorLens} buttonProps={{className: classes.iconButton}} />
        {this.state.open && (
          <FullScreenModal
            tabs={tabs}
            currentTab={currentTab}
            closeModal={this.closeModal}
            setCurrentTab={this.setCurrentTab}
          />
        )}
      </div>
    );
  }

}

export default translate('Forms')(injectSheet(styles)(ColorManager));
