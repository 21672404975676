import React from 'react';
import { translate } from 'react-i18next';
import CodeEditor from '../CodeEditor';
import 'codemirror/mode/htmlmixed/htmlmixed';

const HtmlEditor = ({ t, ...props }) => (
  <CodeEditor
    {...props}
    mode="htmlmixed"
    tabName="HTML"
    note={t('labels.scoped_css_note')}
  />
);

export default translate('Forms')(HtmlEditor);
