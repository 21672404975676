import React from 'react';
import LanguageIcon from '@material-ui/icons/Language';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import languages from '../constants/Languages';
import DropdownMenu from '../components/DropdownMenu';

const LanguagesMenu = ({ currentLocale }) => (
  <DropdownMenu
    menuId="lang"
    icon={<LanguageIcon />}
    menuItems={Object.keys(languages).map(k => languages[k]).map(
      ({ locale, name }) => (
        <MenuItem
          key={locale}
          component={Link}
          to={`/${locale}`}
          selected={locale === currentLocale}
        >
          {name}
        </MenuItem>
      )
    )}
  />
);

export default LanguagesMenu;
