import React, { Component } from 'react';
import Lock from '@material-ui/icons/Lock';
import injectSheet from 'react-jss';
import { translate } from 'react-i18next';
import PasswordSettings from '../containers/PasswordSettings';
import AgeGateSettings from '../containers/AgeGateSettings';
const styles = theme => ({
  settingHeader:{
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .setting-name':{
      paddingLeft: '1rem',
      fontSize: '24px'
    },
  },
});

class SecuritySettings extends Component {
  render(){
    const { classes, t } = this.props;
    return(
      <React.Fragment>
        <div className={classes.settingHeader}>
          <p className='font-icon'><Lock/> </p>
          <p className='setting-name'>{t('labels.security')}</p>
        </div>
        <PasswordSettings />
        <AgeGateSettings />
      </React.Fragment>
    )
  }
}

export default injectSheet(styles)(translate('Forms')(SecuritySettings));
