import { FOOTER } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'footer_1',
  type: FOOTER,
  name: 'Footer 1',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/footer/footer_1.jpg',
  rootContent: {
    type: contentTypes.FOOTER,
    properties: {
      minHeight: {type: 'css', value: '15rem', editable: false},
      backgroundColor: {type: 'css', value: '#212121',editable: true},
      color: {type: 'css', value: '#f4f4f4', editable: true},
      padding: {type: 'css', value: '1.5rem 0rem'},
      fontSize: {type: 'css', value: 'small'},
      logoSrc: {type: 'custom', value: '', editable: true},
      logoHeight: {type: 'custom', value: '6rem'},
      socialMedia: {type: 'custom', value: true, editable: true},
      hideCopyrightMsg: {type: 'custom', value: false, editable: true},
      facebookLink: {type: 'custom', value: 'facebook.com', editable: true},
      socialMediaIconColor: {type: 'custom', value: '#f4f4f4', editable: true},
      socialMediaIconSize: {type: 'custom', value: '1.5rem', editable: true},
    },
    contents: []
  }
}
