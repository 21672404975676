import { REGISTRATION } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'registration_iframe',
  type: REGISTRATION,
  name: 'Registration iFrame',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/registration/registration_iframe.png',
  rootContent: {
    type: contentTypes.REGISTRATION_IFRAME,
  }
}
