import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { SketchPicker } from 'react-color';
import checkers from '../assets/input/checkers.png';
import TranslatableButton from './TranslatableButton';

const transparentRegex = /(?:transparent|rgba\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*,\s*0\s*\))/i;

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  ColorSettings: {
    display: 'inline-block',
    height: '1.5rem',
    width: '1.5rem',
    background: `url(${checkers})`,
    borderRadius: '1rem',
    border: '0.125rem solid white',
    boxSizing: 'border-box',
    position: 'relative'
  },
  colorSample: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  colorPickerOpenerLabel:{
    fontSize: '0.76rem',
    color: '#ccced4',
    marginBottom: 12,
  },
  cover:{
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  sketchPicker: {
    position: 'absolute',
    zIndex: 10,
  },
});

class ColorSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  openModal = () => this.setState({ open: true })
  closeModal = () => this.setState({ open: false })

  getBackgroundStyle = () => {
    const { r, g, b, a } = this.props.value;
    return { background: `rgba(${r},${g},${b},${a})` }
  }

  onChange = (color) => {
    const {a, ...rgb} = color.rgb;
    const wasTransparent = transparentRegex.test(this.getBackgroundStyle().background);
    rgb.a = wasTransparent ? 1 : a;
    this.props.onChange(rgb);
  }

  render() {
    const { classes, value, label } = this.props;
    const { r, g, b, a } = value;
    const previewBackgroundColor = this.getBackgroundStyle();
    return (
      <div className={classes.container}>
        {label && (
          <span className={classes.colorPickerOpenerLabel}>{label}</span>
        )}
        <div
          className={classes.ColorSettings}
        >
          <div
            className={classes.colorSample}
            onClick={this.openModal}
            style={previewBackgroundColor}
          />
          {this.state.open && (
            <div>
              <div className={classes.cover} onClick={this.closeModal}></div>
              <SketchPicker
                color={value}
                onChange={this.onChange}
                className={classes.sketchPicker}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default injectSheet(styles)(ColorSettings);
