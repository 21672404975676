import { SPEAKERS } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'speakers_4',
  type: SPEAKERS,
  name: 'Speakers carousel',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/speakers/speakers_4.png',
  rootContent: {
    type: contentTypes.SPEAKER_CAROUSEL,
    properties: {
      speakerPaginationSize: {type: 'custom', value: 8},
      minHeight: {type:'css', value:'33rem'},
      blockBackgroundColor: {type: 'custom', value:'#e2e2e2', editable: true},
      speakerCardBackgroundColor: {type:'custom', value:'#ffffff', editable: true},
      speakerTextColor: {type:'custom', value:'#000000', editable: true},
      speakerSubTextColor: {type: 'custom', value:'#3f3f3f', editable: true},
      speakerCardSocialMediaColor: {type: 'custom', value: '#F84A4A', editable:true},
    }
  }
}
