import { SPONSORS } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'sponsors_1',
  type: SPONSORS,
  name: 'Sponsors logos',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/sponsors/sponsors_1.jpg',
  rootContent: {
    type: contentTypes.SPONSOR_LOGOS,
    properties: {
      backgroundColor: {type: 'custom', value: '#F6F6FA', editable: true},
      color: {type: 'custom', value: '#555555', editable: true},
      descriptionText: {type: 'custom', value: '', editable: true},
      itemsPerRowDesktop: {type: 'custom', value: 3, editable: true},
      itemsPerRowTablet: {type: 'custom', value: 6, editable: true},
      itemsPerRowMobile: {type: 'custom', value: 12, editable: true},
    }
  }
}
