import { CUSTOM } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'custom_8',
  type: CUSTOM,
  name: 'Custom with image cards',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/custom/custom_8.png',
  rootContent: {
    type: contentTypes.ROW,
    properties: {
      backgroundColor: {type: 'css', value: '#FFFFFF', editable: true},
      color: {type: 'css', value: ''},
      fontWeight: {type: 'css', value: 'bold'},
      padding: {type: 'css', value: '0'},
      display: {type: 'css', value: 'flex'},
      justifyContent: {type: 'css', value: 'center'}
    },
    contents: [
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 3},
          medium: {type: 'responsive', value: 9},
          backgroundImage: {type: 'css', value: 'url(https://eventtia-composer.s3.amazonaws.com/assets/images/medium/person_laptop.png)', editable:true},
          backgroundRepeat: {type: 'css', value: 'no-repeat'},
          backgroundSize: {type: 'css', value:'cover'},
          padding: {type: 'css', value: 0},
          margin: {type: 'css', value: '2rem 1rem 2rem 1rem'},
          borderRadius: {type: 'css', value: '10px'}
        },
        contents: [
          {
            type: contentTypes.TEXT,
            value: 'See more',
            properties: {
              margin: {type: 'css', value: '15rem 1rem 1rem 1rem'},
              padding: {type: 'css', value: '2px 2rem 2px 2rem'},
              color: {type: 'css', value: 'white', editable: true},
              backgroundColor: {type: 'css', value: '#FC5054', editable: true},
              fontWeight: {type: 'css', value: 'normal'},
              width: {type: 'css', value: '20%'},
              borderRadius: {type: 'css', value: '5rem'},
              textAlign: {type: 'css', value: 'center'},
              minHeight: {type: 'css', value: '2rem'},
              lineHeight: {type: 'css', value: '2rem'}
            },
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy',
            properties: {
              padding: {type: 'css', value: '2rem 2rem 2px 2rem'},
              color: {type: 'css', value: 'white', editable: true},
              fontWeight: {type: 'css', value: 'bold'},
              fontSize: {type: 'css', value: '22px'}
            },
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            properties: {
              padding: {type: 'css', value: '2rem'},
              color: {type: 'css', value:'white', editable: true},
              fontWeight: {type: 'css', value: 'normal'},
              lineHeight: {type: 'css', value: '1.6'}
            },
          }

        ]
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 3},
          medium: {type: 'responsive', value: 9},
          backgroundImage: {type: 'css', value: 'url(https://eventtia-composer.s3.amazonaws.com/assets/images/medium/people_car.png)', editable:true},
          backgroundRepeat: {type: 'css', value: 'no-repeat'},
          backgroundSize: {type: 'css', value:'cover'},
          padding: {type: 'css', value: 0},
          margin: {type: 'css', value: '2rem 1rem 2rem 1rem'},
          borderRadius: {type: 'css', value: '10px'}
        },
        contents: [
          {
            type: contentTypes.TEXT,
            value: 'See more',
            properties: {
              margin: {type: 'css', value: '15rem 1rem 1rem 1rem'},
              padding: {type: 'css', value: '2px 2rem 2px 2rem'},
              color: {type: 'css', value: 'white', editable: true},
              backgroundColor: {type: 'css', value: '#FC5054', editable: true},
              fontWeight: {type: 'css', value: 'normal'},
              width: {type: 'css', value: '20%'},
              borderRadius: {type: 'css', value: '5rem'},
              textAlign: {type: 'css', value: 'center'},
              minHeight: {type: 'css', value: '2rem'},
              lineHeight: {type: 'css', value: '2rem'}
            },
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy',
            properties: {
              padding: {type: 'css', value: '2rem 2rem 2px 2rem'},
              color: {type: 'css', value: 'white', editable: true},
              fontWeight: {type: 'css', value: 'bold'},
              fontSize: {type: 'css', value: '22px'}
            },
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            properties: {
              padding: {type: 'css', value: '2rem'},
              color: {type: 'css', value:'white', editable: true},
              fontWeight: {type: 'css', value: 'normal'},
              lineHeight: {type: 'css', value: '1.6'}
            },
          }

        ]
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 3},
          medium: {type: 'responsive', value: 9},
          backgroundImage: {type: 'css', value: 'url(https://eventtia-composer.s3.amazonaws.com/assets/images/medium/people_beach.png)', editable:true},
          backgroundRepeat: {type: 'css', value: 'no-repeat'},
          backgroundSize: {type: 'css', value:'cover'},
          padding: {type: 'css', value: 0},
          margin: {type: 'css', value: '2rem 1rem 2rem 1rem'},
          borderRadius: {type: 'css', value: '10px'}
        },
        contents: [
          {
            type: contentTypes.TEXT,
            value: 'See more',
            properties: {
              margin: {type: 'css', value: '15rem 1rem 1rem 1rem'},
              padding: {type: 'css', value: '2px 2rem 2px 2rem'},
              color: {type: 'css', value: 'white', editable: true},
              backgroundColor: {type: 'css', value: '#FC5054', editable: true},
              fontWeight: {type: 'css', value: 'normal'},
              width: {type: 'css', value: '20%'},
              borderRadius: {type: 'css', value: '5rem'},
              textAlign: {type: 'css', value: 'center'},
              minHeight: {type: 'css', value: '2rem'},
              lineHeight: {type: 'css', value: '2rem'}
            },
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy',
            properties: {
              padding: {type: 'css', value: '2rem 2rem 2px 2rem'},
              color: {type: 'css', value: 'white', editable: true},
              fontWeight: {type: 'css', value: 'bold'},
              fontSize: {type: 'css', value: '22px'}
            },
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            properties: {
              padding: {type: 'css', value: '2rem'},
              color: {type: 'css', value:'white', editable: true},
              fontWeight: {type: 'css', value: 'normal'},
              lineHeight: {type: 'css', value: '1.6'}
            },
          }

        ]
      }



    ]
  }
}
