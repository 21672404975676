import React, { Component } from 'react';
import TabDropZone from '../containers/TabDropZone';
import { DragSource } from 'react-dnd';

class DraggableTab extends Component {

  render() {
    const { children, connectDragSource, index } = this.props;
    return connectDragSource(
      <div>
        <TabDropZone index={index}>
          {children}
        </TabDropZone>
      </div>
    );
  }

}

const sourceSpec = {
  beginDrag(props) {
    return {
      tabIndex: props.tabIndex,
    }
  },
  endDrag(props, monitor) {
    if (monitor.didDrop()) {
      const result = monitor.getDropResult();
      props.moveAction(result.index);
    }
  }
};

const collectDrag = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }
};

export default DragSource('page-tab', sourceSpec, collectDrag)(DraggableTab);
