import { REGISTRATION } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'resume_registration',
  type: REGISTRATION,
  name: 'Resume Registration',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/registration/resume_registration.jpg',
  rootContent: {
    type: contentTypes.RESUME_REGISTRATION,
    properties: {
      accentColor: {type: 'custom', value: '#612f62', editable: true},
    }
  }
}
