import { CUSTOM } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'custom_2',
  type: CUSTOM,
  name: 'Custom with rectangle',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/custom/custom_2.png',
  rootContent: {
    type: contentTypes.ROW,
    properties: {
      backgroundColor: {type: 'css', value: '#FCFCFC', editable: true},
      color: {type: 'css', value: ''},
      padding: {type: 'css', value: '1rem 0 3rem 0'},
      display: {type: 'css', value: 'flex'},
      justifyContent: {type: 'css', value: 'center'}
    },
    contents: [
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 4},
          medium: {type: 'responsive', value: 5},
          small: {type: 'responsive', value: 12},
          margin: {type: 'css', value: '0 0 0 0'},
        },
        contents: [
              {
                type: contentTypes.IMAGE,
                properties: {
                  src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/medium/old_man.png', editable:true},
                  objectFit: {type: 'css', value: 'cover'},
                  width: {type: 'css', value:'100%'},
                  height: {type: 'css', value:'100%'},
                  padding: {type: 'css', value:'5% 0 5% 0'}
                },
          }
        ]
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 4},
          medium: {type: 'responsive', value: 6},
          small: {type: 'responsive', value: 12}

        },
        contents: [
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy',
            properties: {
              linkColor: {type: 'custom', value: '#4642A7', editable: true},
              color: {type: 'css', value: '#4642A7', editable: true},
              fontSize: {type: 'css', value: '30px'},
              padding:{type: 'css', value: '1rem 0 0 1rem'},
              margin:{type: 'css', value: '0 1rem 1rem 0'},
              width: {type: 'css', value: '60%'},
              fontWeight: {type: 'css', value: 'bold', editable:false}
            },
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            properties: {
              linkColor: {type: 'custom', value: '#4642A7', editable: true},
              color: {type: 'css', value: '#9F9D9D', editable: true},
              fontWeight: {type: 'css', value: 'normal',editable:false},
              margin:{type: 'css', value: '1rem 1rem 1rem 0'},
              padding:{type: 'css', value: '1rem 0 0 1rem'},

            },
          },
          {
            type: contentTypes.TEXT,
            value: 'Read more...',
            properties: {
              linkColor: {type: 'custom', value: '#4642A7', editable: true},
              color: {type: 'css', value: '#4642A7', editable: true},
              letterSpacing: {type: 'css', value: '1px'},
              fontSize: {type:'css', value: '18px'},
              margin:{type: 'css', value: '1rem 1rem 1rem 0'},
              padding:{type: 'css', value: '1rem 0 0 1rem'},
              fontWeight: {type: 'css', value: 'normal', editable:false}
            },
          }
        ]
      }

    ]
  }
}
