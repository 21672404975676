import { REGISTRATION_TYPES } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'registration_types_1',
  type: REGISTRATION_TYPES,
  name: 'Registration type cards',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/registration_types/registration_types_1.png',
  rootContent: {
    type: contentTypes.REGISTRATION_TYPE_CARDS,
    properties: {
      showAvailability: {type: 'custom', value: true, editable: true},
      showPrice: {type: 'custom', value: true, editable: true},
      showVAT: {type: 'custom', value: true, editable: true},
    }
  }
}
