import React, { Component } from 'react';
import injectSheet from 'react-jss';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ListAlt from '@material-ui/icons/ListAlt';
import Done from '@material-ui/icons/Done';
import Close from '@material-ui/icons/Close';
import without from 'lodash/without';
import union from 'lodash/union';
import TranslatableIconButton from '../TranslatableIconButton';
import TranslatableButton from '../TranslatableButton';
import FullScreenModal from '../FullScreenModal';
import AttendeeTypesList from '../../containers/AttendeeTypesList';

const ATTENDEE_TYPES = 'Attendee Types';

const styles = theme => ({
  AttendeeTypesSelector: {
    display: 'inline-block',
  },
  attendeeTypeLabel: {
    display: 'flex',
  },
  iconButton: {
    marginRight: '-0.75rem'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

class AttendeeTypesSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      currentTab: ATTENDEE_TYPES,
      inputValue: props.value || [],
    };
  }

  openModal = () => this.setState({ open: true })
  closeModal = () => this.setState({ open: false })
  setCurrentTab = (tabId) => () => this.setState({ currentTab: tabId })

  onChange = () => {
    this.props.onChange(this.state.inputValue);
    this.closeModal();
  }

  discardChanges = () => {
    this.setState({
      inputValue: this.props.value || [],
    });
    this.closeModal();
  }

  handleChangeState = ({ target: { checked, value } }) => {
    this.setState(currentState => ({
      inputValue: checked ? union(currentState.inputValue, [value]) : without(currentState.inputValue, value),
    }));
  }

  render() {
    const { classes } = this.props;
    const { currentTab, inputValue } = this.state;

    const tabs = {
      [ATTENDEE_TYPES]: (
        <div className={classes.container}>
          <FormGroup>
            <AttendeeTypesList
              renderItem={attendeeType => (
                <FormControlLabel
                  key={attendeeType.id}
                  classes={{root: classes.attendeeTypeLabel}}
                  control={
                    <Checkbox
                      checked={inputValue.includes(attendeeType.id)}
                      onChange={this.handleChangeState}
                      value={attendeeType.id}
                    />
                  }
                  label={attendeeType.name}
                />
              )}
            />
          </FormGroup>
          <div className='button-panel'>
            <TranslatableButton
              onClick={this.onChange}
              leftIcon={<Done />}
              tPath="buttons.apply_changes"
            />
            <TranslatableButton
              onClick={this.discardChanges}
              leftIcon={<Close />}
              tPath="buttons.discard_changes"
            />
          </div>
        </div>
      ),
    }

    return (
      <div className={classes.AttendeeTypesSelector}>
        <TranslatableIconButton tPath="buttons.manage_attendee_types" onClick={this.openModal} Icon={ListAlt} buttonProps={{className: classes.iconButton}} />
        {this.state.open && (
          <FullScreenModal
            tabs={tabs}
            currentTab={currentTab}
            closeModal={this.closeModal}
            setCurrentTab={this.setCurrentTab}
          />
        )}
      </div>
    );
  }

}

export default injectSheet(styles)(AttendeeTypesSelector);
