import { DELETE_PAGE } from "../actions/pages";
import { INSERT_BLOCK, DELETE_BLOCK, INSERT_NAVBAR, DELETE_NAVBAR, INSERT_FOOTER, DELETE_FOOTER} from "../actions/blocks";
import { COMPOSER_SETTINGS_SUCCESS } from "composer-blocks/lib/actions/site";
import { deleteWhere } from 'composer-blocks/lib/helpers/hasMany';
import { getEntityFromResponse } from 'composer-blocks/lib/helpers/api';

const blocks = (state = {}, action) => {
  switch (action.type) {
    case INSERT_NAVBAR:
    case INSERT_FOOTER:
    case INSERT_BLOCK: {
      const { block } = action;

      return {
        ...state,
        [block.id]: block
      };
    }

    case DELETE_NAVBAR:
    case DELETE_FOOTER:
    case DELETE_BLOCK: {
      const { id } = action;

      return deleteWhere(state, 'id', id);
    }

    case DELETE_PAGE: {
      const { childBlocks } = action;

      return deleteWhere(state, 'id', childBlocks);
    }

    case COMPOSER_SETTINGS_SUCCESS: {
      return getEntityFromResponse(action, 'blocks') || state;
    }

    default:
      return state;
  }
};

export default blocks;
