import { HEADERS } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'header_3',
  type: HEADERS,
  name: 'Header Blue Glasses',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/header/header_3.png',
  rootContent: {
    type: contentTypes.ROW,
    properties: {
      backgroundImage: {type: 'css', value: 'url(https://eventtia-composer.s3.amazonaws.com/assets/images/large/background_man.png)', editable: true},
      backgroundSize: {type: 'css', value: 'cover'},
      padding: {type: 'css', value: '2rem 0'},
      minHeight: {type: 'css', value: '500px'}
    },
    contents: [
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 8, editable: true },
          offsetLarge: {type: 'responsive', value: 4, editable: true },
          medium: {type: 'responsive', value: 9, editable: true },
          offsetMedium: {type: 'responsive', value: 3, editable: true },
          },
        contents: [
          {
            type: contentTypes.EVENT_NAME,
            properties: {
              margin: {type: 'css', value: '0 2rem'},
              fontWeight: {type: 'css', value: 'bold'},
              textAlign: {type: 'css', value: 'right'},
              justifySelf:{type: 'css', value: 'end'},
              color: {type: 'css', value: '#ffffff'},
              fontFamily: {type: 'css', value: 'inherit'},
              overflowWrap: {type: 'css', value: 'break-word'},
            },
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            properties: {margin: {type: 'css', value: '0 2rem'},
              textAlign: {type: 'css', value: 'right'},
              color: {type: 'css', value: '#ffffff', editable: true},
              fontFamily: {type: 'css', value: 'inherit'},
              fontSize: {type: 'css', value: '1.6rem'},
              marginTop: {type: 'css', value: '1rem'},
            },
          },
          {
            type: contentTypes.EVENT_ADDRESS,
            properties: {margin: {type: 'css', value: '0 2rem'},
              textAlign: {type: 'css', value: 'right'},
              color: {type: 'css', value: '#ffffff'},
              fontFamily: {type: 'css', value: 'inherit'},
              fontWeight: {type: 'css', value: 'Bold'},
              marginTop: {type: 'css', value: '1rem'},
            },
          },
          {
            type: contentTypes.BUTTON,
            value: 'Register',
            properties: {
              color: {type: 'css', value: '#ffffff', editable: true},
              borderColor: {type: 'css', value:'#ffffff', editable: true},
              borderRadius: {type: 'css', value:'130px', editable: true},
              backgroundColor: {type: 'css', value:'rgba(0,0,0,0)', editable: true},
              margin: {type: 'css', value: '1rem 2rem'},
              alignSelf: {type: 'css', value: 'flex-end', editable: true},
              href: {type: 'custom', value: 'javascript:alert()', editable: true},

            }
          },
          {
            type: contentTypes.COUNTDOWN,
            properties: {
              margin: {type: 'css', value: '0 2rem'},
              color: {type: 'css', value: '#ffffff'},
              fontFamily: {type: 'css', value: 'inherit'},
              textAlign: {type: 'css', value: 'right'},
            },
          },
        ]
      },
    ]
  }
}
