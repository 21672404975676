import { SPEAKERS } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'speakers_3',
  type: SPEAKERS,
  name: 'Speakers squares with hover',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/speakers/speakers_3.png',
  rootContent: {
    type: contentTypes.SPEAKER_SQUARE_HOVER,
    properties: {
      backgroundColor: {type: 'css', value: '#FFFFFF', editable: true},
      padding: { type: 'css', value: 24 },
      speakerTextColor: {type: 'custom', value: '#3f3f3f', editable: true},
      speakerSubTextColor: {type: 'custom', value: '#5b5b5b', editable: true},
      speakerCardBorderColor: {type: 'custom', value: '#F5F5F5', editable: true},
      speakerCardBackgroundColor: {type: 'custom', value: '#FFFFFF', editable: true},
      speakerHoverTextColor: {type: 'custom', value: '#F84A4A', editable: true},
      speakerHoverSubTextColor: {type: 'custom', value: '#5b5b5b', editable: true},
      speakerCardHoverBorderColor: {type: 'custom', value: '#F84A4A', editable: true},
      speakerCardHoverBackgroundColor: {type: 'custom', value: '#FFFFFF', editable: true},
      speakerCardSocialMediaColor: {type: 'custom', value: '#F84A4A', editable: true},
      speakerPaginationSize: {type: 'custom', value: 8, editable: true},
      itemsPerRowDesktop: {type: 'custom', value: 3, editable: true},
      itemsPerRowTablet: {type: 'custom', value: 6, editable: true},
      itemsPerRowMobile: {type: 'custom', value: 12, editable: true},

    }
  }
}
