import React, { Component } from 'react';
import injectSheet from 'react-jss';
import Slideshow from '@material-ui/icons/Slideshow';
import Done from '@material-ui/icons/Done';
import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
// import { unwrapWithUrl } from 'composer-blocks/lib/helpers/backgrounds';
import TranslatableIconButton from '../TranslatableIconButton';
import TranslatableButton from '../TranslatableButton';
import FullScreenModal from '../FullScreenModal';
import StockImagesGallery from '../../containers/StockImagesGallery';
import UserImagesGallery from '../../containers/UserImagesGallery';

const SLIDES = 'Slides';

const styles = theme => ({
  SlidesManager: {
    display: 'inline-block',
  },
  uploaderContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  customUrlContainer: {
    textAlign: 'center',
  },
  iconButton: {
    marginRight: '-0.75rem'
  },
  container: {
    margin: 'auto',
  },
  inputGroup: {
    padding: '1rem 0',
    display: 'flex',
  },
  inputRoot: {
    width: '25em',
    marginRight: '2rem',
  }
});

class SlidesManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      currentTab: SLIDES,
      inputValue: props.value || [{image: '', text: ''}]
    };
  }

  openModal = () => this.setState({ open: true })
  closeModal = () => this.setState({ open: false })
  setCurrentTab = (tabId) => () => this.setState({ currentTab: tabId })
  setCustomUrl = () => {
    this.onChange(this.state.inputValue);
  }

  onChange = (url) => {
    this.props.onChange(this.state.inputValue);
    this.closeModal();
  }

  discardChanges = (url) => {
    this.setState({
      inputValue: this.props.value || [{image: '', text: ''}]
    });
    this.closeModal();
  }

  onChangeInput = (event) => this.setState({ inputValue: event.target.value })

  addSlide = () => {
    this.setState(oldState => {
      const newValue = _.cloneDeep(oldState.inputValue);
      newValue.push({image: '', text: ''});
      return { inputValue: newValue };
    });
  }

  deleteSlide = (index) => () => {
    this.setState(oldState => {
      const newValue = _.cloneDeep(oldState.inputValue);
      newValue.splice(index, 1);
      return { inputValue: newValue };
    });
  }

  changeState = (index, key) => (event) => {
    const value = event.target.value;
    this.setState(oldState => {
      const newValue = _.cloneDeep(oldState.inputValue);
      newValue[index][key] = value;
      return { inputValue: newValue };
    });
  }

  render() {
    const { classes, value } = this.props;
    const { currentTab, inputValue } = this.state;
    // const selectedImage = unwrapWithUrl(value);

    const tabs = {
      [SLIDES]: (
        <div className={classes.container}>
          {inputValue.map((item, index) => (
            <div className={classes.inputGroup} key={index}>
              <TextField
                label="Image URL"
                onChange={this.changeState(index, 'image')}
                classes={{root: classes.inputRoot}}
                value={item.image}
              />
              <TextField
                label="Text (Optional)"
                onChange={this.changeState(index, 'text')}
                classes={{root: classes.inputRoot}}
                value={item.text}
                multiline
              />
              <TranslatableIconButton
                tPath="buttons.delete"
                onClick={this.deleteSlide(index)}
                Icon={Close}
              />
            </div>
          ))}
          <TranslatableButton
            onClick={this.addSlide}
            leftIcon={<Add />}
            tPath="buttons.add_slide"
          />
          <TranslatableButton
            onClick={this.onChange}
            leftIcon={<Done />}
            tPath="buttons.apply_changes"
          />
          <TranslatableButton
            onClick={this.discardChanges}
            leftIcon={<Close />}
            tPath="buttons.discard_changes"
          />
        </div>
      ),
    }

    return (
      <div className={classes.SlidesManager}>
        <TranslatableIconButton tPath="buttons.manage_slides" onClick={this.openModal} Icon={Slideshow} buttonProps={{className: classes.iconButton}} />
        {this.state.open && (
          <FullScreenModal
            tabs={tabs}
            currentTab={currentTab}
            closeModal={this.closeModal}
            setCurrentTab={this.setCurrentTab}
          />
        )}
      </div>
    );
  }

}

export default injectSheet(styles)(SlidesManager);
