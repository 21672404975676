import humps from 'humps';

export const inputTypes = {
  TEXT: 'TEXT',
  COLOR: 'COLOR',
  NUMBER: 'NUMBER',
  SELECT: 'SELECT',
  URL: 'URL',
  IMAGE: 'IMAGE',
  CHECKBOX: 'CHECKBOX',
  ALLOWED_CONTENTS: 'ALLOWED_CONTENTS',
  HTML: 'HTML',
  JS: 'JS',
  CAROUSEL_SLIDES: 'CAROUSEL_SLIDES',
  LINK_SELECTOR: 'LINK_SELECTOR',
  FONT_FAMILY_SELECTOR: 'FONT_FAMILY_SELECTOR',
  COLOURS_SELECTOR: 'COLOURS_SELECTOR',
  PARTICIPANT_INFORMATION: 'PARTICIPANT_INFORMATION',
  BORDERS_SELECTOR: 'BORDERS_SELECTOR',
  ATTENDEE_TYPES_SELECTOR: 'ATTENDEE_TYPES_SELECTOR',
  WORKSHOP_CATEGORY_SELECTOR: 'WORKSHOP_CATEGORY_SELECTOR',
  IFRAME_SETTINGS: 'IFRAME_SETTINGS'
};

const rangeOfOptions = (start, end) => [...Array(end - start + 1).keys()].map(num => ({label: (num + start).toString(), value: (num + start)}));
const columnLengths = rangeOfOptions(1, 12);
const columnOffsets = rangeOfOptions(0, 11);

const aspectRatios = [
  {label: '4:3', value: '4:3'},
  {label: '16:9', value: '16:9'},
];

const elementPositions = [
  {label: 'Fixed', value: 'fixed'},
  {label: 'Normal', value: 'static'},
];

const textAlignOptions = [
  {label: 'left', value: 'left'},
  {label: 'center', value: 'center'},
  {label: 'right', value: 'right'},
  {label: 'justify', value: 'justify'},
];

const speakerPaginationSizes = [
  {label: '2', value: 2 },
  {label: '4', value: 4 },
  {label: '8', value: 8 },
  {label: '12', value: 12},
  {label: '16', value: 16},
  {label: '20', value: 20},
  {label: '24', value: 24},
];

const companiesPerRowMobile = [
  {label: '1', value: 12 },
  {label: '2', value: 6 },
  {label: '3', value: 4 },
];
const companiesPerRowTablet = [
  {label: '2', value: 6 },
  {label: '3', value: 4 },
  {label: '4', value: 3 },
];
const companiesPerRowDesktop = [
  {label: '2', value: 6 },
  {label: '3', value: 4 },
  {label: '4', value: 3 },
  {label: '6', value: 2 },
];

const participantsPerRowMobile = [
  {label: '1', value: 1 },
  {label: '2', value: 2 },
  {label: '3', value: 3 },
];
const participantsPerRowTablet = [
  {label: '2', value: 2 },
  {label: '3', value: 3 },
  {label: '4', value: 4 },
];
const participantsPerRowDesktop = [
  {label: '3', value: 3 },
  {label: '4', value: 4 },
  {label: '6', value: 6 },
];

const fontFamilyOptions = [
  {label: 'Same as parent', value: 'inherit'},
  {label: 'Quattrocento Sans', value: 'Quattrocento Sans, sans-serif'},
  {label: 'Quattrocento, serif', value: 'Quattrocento, serif'},
  {label: 'Roboto', value: 'Roboto, sans-serif'},
  {label: 'Roboto, serif', value: 'Roboto, serif'},
  {label: 'Arial', value: 'Arial, sans-serif'},
  {label: 'Calibri', value: 'Calibri, sans-serif'},
];

const socialMediaIconSizes = [
  {label: 'small', value: '1.5rem'},
  {label: 'medium', value: '2rem'},
  {label: 'big', value: '2.5rem'},

];

const alignSelfOptions = [
  {label: 'left', value: 'flex-start'},
  {label: 'right', value: 'flex-end'},
  {label: 'center', value: 'center'},
  {label: 'full width', value: 'stretch'},
];

const targetOptions = [
  {label: 'same tab', value: '_self'},
  {label: 'new tab', value: '_blank'},
];

const PropertyInfo = {
  itemOuterShadow: {inputType: inputTypes.COLOR, label: 'shadow color'},
  itemBackgroundColor: {inputType: inputTypes.COLOR, label: 'item background color'},
  secondaryBackgroundColor: {inputType: inputTypes.COLOR, label: 'secondary background color'},
  backgroundColor: {inputType: inputTypes.COLOR, label: 'background color'},
  bordersSelector: {inputType: inputTypes.BORDERS_SELECTOR, label: 'Manage Borders'},
  hoveredBackgroundColor: {inputType: inputTypes.COLOR, label: 'hovered background color'},
  borderColor: {inputType: inputTypes.COLOR, label: 'border color'},
  hoveredBorderColor: {inputType: inputTypes.COLOR, label: 'hovered border color'},
  backgroundImage: {inputType: inputTypes.IMAGE, label: 'background image'},
  src: {inputType: inputTypes.IMAGE, label: 'image'},
  iframeSrc: {inputType: inputTypes.IFRAME_SETTINGS, label: 'Iframe settings'},
  color: {inputType: inputTypes.COLOR, label: 'text color'},
  hoveredColor: {inputType: inputTypes.COLOR, label: 'hovered text color'},
  linkColor: {inputType: inputTypes.COLOR, label: 'link color'},
  privacyPolicyUrl: {inputType: inputTypes.TEXT, label: 'Privacy policy Url'},
  secondaryButtonLabel: {inputType: inputTypes.TEXT, label: 'Customize secondary button text'},
  alternateText: {inputType: inputTypes.TEXT, label: 'Alternate text'},
  navPosition: {inputType: inputTypes.SELECT, label:'Navbar position' , options: elementPositions},
  navItemHoverBackground: {inputType: inputTypes.COLOR, label: 'navigation item hover background'},
  navItemHoverBorder: {inputType: inputTypes.COLOR, label: 'navigation item hover border'},
  navItemHoverUnderline: {inputType: inputTypes.COLOR, label: 'navigation item hover underline'},
  navItemHoverTextColor: {inputType: inputTypes.COLOR, label: 'navigation item hover text color'},
  navItemActiveBackground: {inputType: inputTypes.COLOR, label: 'navigation item active background'},
  navItemActiveBorder: {inputType: inputTypes.COLOR, label: 'navigation item active border'},
  navItemActiveUnderline: {inputType: inputTypes.COLOR, label: 'navigation item active underline'},
  navItemActiveTextColor: {inputType: inputTypes.COLOR, label: 'navigation item active text color'},
  socialMediaIconColor: {inputType: inputTypes.COLOR, label: 'color of social media icons'},
  socialMediaIconSize: {inputType: inputTypes.SELECT, label: 'size of social media icons', options:socialMediaIconSizes},
  countdownNumberColor: {inputType: inputTypes.COLOR, label: 'color of countdown number'},
  countdownTextColor: {inputType: inputTypes.COLOR, label: 'color of countdown text'},
  workshopTextColor: {inputType: inputTypes.COLOR, label: 'workshop text color'},
  workshopColor: {inputType: inputTypes.COLOR, label: 'workshop color'},
  workshopAccentColor: {inputType: inputTypes.COLOR, label: 'workshop accent color'},
  speakerTextColor: {inputType: inputTypes.COLOR, label: 'speaker text color'},
  speakerSubTextColor: {inputType: inputTypes.COLOR, label: 'speaker secondary text color'},
  speakerCardBorderColor: {inputType: inputTypes.COLOR, label: 'speaker card border color'},
  speakerCardBackgroundColor: {inputType: inputTypes.COLOR, label: 'speaker card background color'},
  speakerHoverTextColor: {inputType: inputTypes.COLOR, label: 'speaker hover text color'},
  speakerHoverSubTextColor: {inputType: inputTypes.COLOR, label: 'speaker hover secondary text color'},
  speakerCardHoverBorderColor: {inputType: inputTypes.COLOR, label: 'speaker card hover border color'},
  speakerCardHoverBackgroundColor: {inputType: inputTypes.COLOR, label: 'speaker card hover background color'},
  speakerCardSocialMediaColor: {inputType: inputTypes.COLOR, label: 'speaker card social media color'},
  hideSearchField: {inputType: inputTypes.CHECKBOX, label: 'Hide keywords filter'},
  accentColor: {inputType: inputTypes.COLOR, label: 'Accent color'},
  lightBackgroundColor: {inputType: inputTypes.COLOR, label: 'Light background color'},
  darkBackgroundColor: {inputType: inputTypes.COLOR, label: 'Dark background color'},
  blockBackgroundColor: {inputType: inputTypes.COLOR, label: 'background Color'},
  horizontalLineColor: {inputType: inputTypes.COLOR, label: 'Horizontal Line color'},
  showPrice: {inputType: inputTypes.CHECKBOX, label: 'Show price'},
  showVAT: {inputType: inputTypes.CHECKBOX, label: 'Show price with VAT'},
  showAvailability: {inputType: inputTypes.CHECKBOX, label: 'Show Availability'},
  videoUrl: {inputType: inputTypes.TEXT, label: 'Video URL'},
  aspectRatio: {inputType: inputTypes.SELECT, label: 'Aspect Ratio', options: aspectRatios},
  registerButtonLabel: {inputType: inputTypes.TEXT, label: 'Customize register button text'},
  principalText: {inputType: inputTypes.TEXT, label: 'Customize text'},
  descriptionText: {inputType: inputTypes.TEXT, label: 'Description text'},
  buttonLabel: {inputType: inputTypes.TEXT, label: 'Customize button text'},
  hideSearchBar: {inputType: inputTypes.CHECKBOX, label: 'Hide search bar'},
  hideZoneFilter: {inputType: inputTypes.CHECKBOX, label: 'Hide zone filter'},
  hideCompanyFilter: {inputType: inputTypes.CHECKBOX, label: 'Hide company filter'},
  hideCategoryFilter: {inputType: inputTypes.CHECKBOX, label: 'Hide category filter'},
  itemsPerRowDesktop: {inputType: inputTypes.SELECT, label: 'Items per row for desktop', options: companiesPerRowDesktop},
  itemsPerRowTablet: {inputType: inputTypes.SELECT, label: 'Items per row for tablet', options: companiesPerRowTablet},
  itemsPerRowMobile: {inputType: inputTypes.SELECT, label: 'Items per row for mobile', options: companiesPerRowMobile},
  titleColor: {inputType: inputTypes.COLOR, label: 'title color'},
  participantsPerRowDesktop: {inputType: inputTypes.SELECT, label: 'Participants per row for desktop', options: participantsPerRowDesktop},
  participantsPerRowTablet: {inputType: inputTypes.SELECT, label: 'Participants per row for tablet', options: participantsPerRowTablet},
  participantsPerRowMobile: {inputType: inputTypes.SELECT, label: 'Participants per row for mobile', options: participantsPerRowMobile},
  buttonColor: {inputType: inputTypes.COLOR, label: 'button color'},
  buttonTextColor: {inputType: inputTypes.COLOR, label: 'button text color'},
  small: {inputType: inputTypes.SELECT, label: 'width in mobile', options: columnLengths},
  medium: {inputType: inputTypes.SELECT, label: 'width in tablet', options: columnLengths},
  large: {inputType: inputTypes.SELECT, label: 'width in desktop', options: columnLengths},
  offsetSmall: {inputType: inputTypes.SELECT, label: 'offset in mobile', options: columnOffsets},
  offsetMedium: {inputType: inputTypes.SELECT, label: 'offset in tablet', options: columnOffsets},
  offsetLarge: {inputType: inputTypes.SELECT, label: 'offset in desktop', options: columnOffsets},
  textAlign: {inputType: inputTypes.SELECT, label: 'text alignment', options: textAlignOptions},
  fontFamily: {inputType: inputTypes.FONT_FAMILY_SELECTOR, label: 'font family', options: fontFamilyOptions},
  target: {inputType: inputTypes.SELECT, label: 'open link in', options: targetOptions},
  alignSelf: {inputType: inputTypes.SELECT, label: 'alignment within container', options: alignSelfOptions},
  allowedContents: {inputType: inputTypes.ALLOWED_CONTENTS},
  logoSrc: {inputType: inputTypes.URL, label: 'Logo URL'},
  homeUrl: {inputType: inputTypes.URL, label: 'Home URL'},
  socialMedia: {inputType: inputTypes.CHECKBOX, label: 'Show Social Media Icons'},
  hideCopyrightMsg: {inputType: inputTypes.CHECKBOX, label: 'Hide Copyright Message'},
  languageSelector: {inputType: inputTypes.CHECKBOX, label: 'Show language selector'},
  expandedByDefault: {inputType: inputTypes.CHECKBOX, label: 'expanded by default'},
  countdownFontSize: {inputType: inputTypes.NUMBER, label: 'countdown size'},
  fontSize: {inputType: inputTypes.NUMBER, label: 'text size'},
  titleFontSize: {inputType: inputTypes.NUMBER, label: 'title size'},
  html: {inputType: inputTypes.HTML, label: 'HTML'},
  js: {inputType: inputTypes.JS, label: 'JavaScript'},
  carouselSlides: {inputType: inputTypes.CAROUSEL_SLIDES, label: 'Slides'},
  linkSelector: {inputType: inputTypes.LINK_SELECTOR, label: 'Add Links'},
  coloursSelector: {inputType: inputTypes.COLOURS_SELECTOR, label: 'Manage Colors'},
  participantInformation: {inputType: inputTypes.PARTICIPANT_INFORMATION, label: 'Manage Participant Information'},
  speakerPaginationSize: {inputType: inputTypes.SELECT, label: 'Speakers per page', options: speakerPaginationSizes},
  showFilters: {inputType: inputTypes.CHECKBOX, label: 'show filters'},
  displayedAttendeeTypes: {inputType: inputTypes.ATTENDEE_TYPES_SELECTOR, label: 'Displayed attendee types'},
  displayedWorkshopCategories: {inputType: inputTypes.WORKSHOP_CATEGORY_SELECTOR, label: 'Displayed workshop categories'},
};

export default (propertyName) => {
  return PropertyInfo[propertyName] || {inputType: inputTypes.TEXT, label: humps.decamelize(propertyName, { separator: ' ' })};
};
