import React, { Component } from 'react';
import injectSheet from 'react-jss';
import BorderStyle from '@material-ui/icons/BorderStyle';
import Done from '@material-ui/icons/Done';
import Close from '@material-ui/icons/Close';
import { SketchPicker } from 'react-color';
import { translate } from 'react-i18next';
import _ from 'lodash';
import TranslatableIconButton from '../TranslatableIconButton';
import TranslatableButton from '../TranslatableButton';
import FullScreenModal from '../FullScreenModal';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


const BORDERS = 'Borders';

const styles = theme => ({
  BorderManager: {
    display: 'inline-block',
  },
  colorSample: {
    height: '1.5rem',
    width: '1.5rem',
    borderRadius: '50%',
    cursor: 'pointer',
    border: '0.125rem solid white',
    marginLeft: '4px',
  },
  sketchBox:{
    zIndex: '1994',
  },
  iconButton: {
    marginRight: '-0.75rem'
  },
  container: {
    display: 'flex',
    justifyContent:'flex-start',
  },
  left:{
    minWidth: '20rem',
    marginTop: '2rem',
  },
  right:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .border-preview':{
      marginTop: '2rem',
      marginBottom: '2rem',
      height:'6rem',
      width: '5rem',
    }
  },
  rowInputs:{
    display: 'flex',
    marginTop: '2rem',
    marginBottom: '1rem',
  },
  colorInput:{
    width:'5rem',
    '& p':{
      fontSize: '0.84rem',
      color: 'rgba(255,255,255,0.7)',
      marginTop: '0',
      marginLeft: '0.2rem',
    }
  },
  selectInput:{
    marginLeft:'1.8rem',
    width:'8rem',
  },
  inputRoot:{
    marginLeft:'1rem',
    width:'3rem',
  },
  checkboxLabel:{
    marginLeft: '-1.2rem',
    marginRight: '1.2rem',
    marginTop:'1.04rem',
  },
});

class BorderManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      currentTab: BORDERS,
      inputValue: props.value || {
                                  color: {r:255,g:255,b:255,a:1},
                                  borderWidth:'thin',
                                  borderStyle:'solid',
                                  top: false,
                                  right: false,
                                  bottom: false,
                                  left: false,
                                  desktop: false,
                                  tablet: false,
                                  mobile: false
                                },
    };
  }

  openModal = () => this.setState({ open: true })
  closeModal = () => this.setState({ open: false })
  setCurrentTab = (tabId) => () => this.setState({ currentTab: tabId })

  previewBorders = () => {
    const { inputValue } = this.state;
    if(!!inputValue){
      const { r,g,b,a } = inputValue.color;
      const { borderWidth, borderStyle, top, right, bottom, left} = inputValue;
      return {
        borderTopStyle: top ? borderStyle : 'none',
        borderRightStyle: right ? borderStyle : 'none',
        borderBottomStyle: bottom ? borderStyle : 'none',
        borderLeftStyle: left ? borderStyle : 'none',
        borderWidth: borderWidth,
        borderColor: `rgba(${r},${g},${b},${a})`,
      }
    }
    return {}
  }

  fillPreviewColor = () => {
    const { inputValue } = this.state;
    if(!!inputValue && !!inputValue.color){
      const { r, g, b, a } = inputValue.color;
      return {backgroundColor: `rgba(${r},${g},${b},${a})`}
    }
    return {}
  }

  onChange = () => {
    this.props.onChange(this.state.inputValue);
    this.closeModal();
  }

  discardChanges = () => {
    this.setState({
      inputValue: this.props.value || {
                                        color: {r:255,g:255,b:255,a:1},
                                        borderWidth:'thin',
                                        borderStyle:'solid',
                                        top: false,
                                        right: false,
                                        bottom: false,
                                        left: false,
                                        desktop: false,
                                        tablet: false,
                                        mobile: false
                                      },
    });
    this.closeModal();
  }

  changeState = (key,type) => (val) => {
    let value;
    switch (type) {
      case 'colorpicker':
        value = val.rgb;
        break;
      case 'checkbox':
        value = val.target.checked;
        break;
      default:
        value = val.target.value
        break;
    }
    this.setState(oldState => {
      const newValue = _.cloneDeep(oldState.inputValue);
      newValue[key] = value;
      return { inputValue: newValue };
    });
  }

  render() {
    const { classes, t } = this.props;
    const { currentTab, inputValue } = this.state;
    const options = [
      'dashed',
      'dotted',
      'double',
      'solid'
    ];
    const widths = [
      'thin',
      'medium',
      'thick'
    ];
    const tabs = {
      [BORDERS]: (
        <div className={classes.container}>
          <div className={classes.left} >
            <SketchPicker
              label={t('labels.color')}
              color={this.state.inputValue.color}
              onChange={this.changeState('color','colorpicker')}
              value={this.state.inputValue.color}
            />
          </div>

          <div className={classes.right} >
            <div className={classes.rowInputs}>
              <div className={classes.colorInput}>
                <p>Color</p>
                <div
                  className={classes.colorSample}
                  style={this.fillPreviewColor()}
                  ></div>
              </div>
              <TextField
                select
                label='Width'
                classes={{root: classes.selectInput}}
                onChange={this.changeState('borderWidth', 'select')}
                value={this.state.inputValue.borderWidth}
              >
                {widths.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                label='Style'
                classes={{root: classes.selectInput}}
                onChange={this.changeState('borderStyle', 'select')}
                value={this.state.inputValue.borderStyle}
              >
                {options.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div className={classes.checkboxRow}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={this.changeState('top','checkbox')}
                      checked={this.state.inputValue.top}
                    />
                  }
                />
                <p className={classes.checkboxLabel}>Top</p>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={this.changeState('right','checkbox')}
                      checked={this.state.inputValue.right}
                    />
                  }
                />
                <p className={classes.checkboxLabel}>Right</p>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={this.changeState('bottom','checkbox')}
                      checked={this.state.inputValue.bottom}
                    />
                  }
                />
              <p className={classes.checkboxLabel}>Bottom</p>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={this.changeState('left','checkbox')}
                      checked={this.state.inputValue.left}
                    />
                  }
                />
              <p className={classes.checkboxLabel}>Left</p>
              </FormGroup>
            </div>

            <div className={classes.checkboxRow}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={this.changeState('desktop','checkbox')}
                      checked={this.state.inputValue.desktop}
                    />
                  }
                />
              <p className={classes.checkboxLabel}>Desktop</p>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={this.changeState('tablet','checkbox')}
                      checked={this.state.inputValue.tablet}
                    />
                  }
                />
              <p className={classes.checkboxLabel}>Tablet</p>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={this.changeState('mobile','checkbox')}
                      checked={this.state.inputValue.mobile}
                    />
                  }
                />
              <p className={classes.checkboxLabel}>Mobile</p>
              </FormGroup>
            </div>

            <div
              className='border-preview'
              style={this.previewBorders()}
              >
            </div>
            <div className='button-panel'>
              <TranslatableButton
                onClick={this.onChange}
                leftIcon={<Done />}
                tPath="buttons.apply_changes"
              />
              <TranslatableButton
                onClick={this.discardChanges}
                leftIcon={<Close />}
                tPath="buttons.discard_changes"
              />
            </div>
          </div>
        </div>
      ),
    }
    return (
      <div className={classes.BorderManager}>
        <TranslatableIconButton tPath="buttons.manage_colours" onClick={this.openModal} Icon={BorderStyle} buttonProps={{className: classes.iconButton}} />
        {this.state.open && (
          <FullScreenModal
            tabs={tabs}
            currentTab={currentTab}
            closeModal={this.closeModal}
            setCurrentTab={this.setCurrentTab}
          />
        )}
      </div>
    );
  }
}

export default translate('Forms')(injectSheet(styles)(BorderManager));
