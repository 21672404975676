import React from 'react';
import Button from '@material-ui/core/Button';
import injectSheet from 'react-jss';
import { translate } from 'react-i18next';

const styles = {
  root: ({ buttonStyles }) => ({
    ...(buttonStyles && buttonStyles.root)
  }),
  label: ({ buttonStyles }) => ({
    textTransform: 'capitalize',
    ...(buttonStyles && buttonStyles.label)
  }),
  text: {
    margin: '0 1rem',
    textAlign: 'left'
  }
}

export default injectSheet(styles)(translate('Forms')(({ t, tPath, text, onClick, icon, leftIcon, buttonProps, buttonStyles, classes, disabled }) => (
  <Button
    disabled ={disabled}
    onClick={onClick}
    classes={{
      root: classes.root,
      label: classes.label
    }}
    {...buttonProps}
  >
    {leftIcon}
    <span className={classes.text}>{tPath ? t(tPath) : text}</span>
    {icon}
  </Button>
)));
