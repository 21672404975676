import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import injectSheet from 'react-jss';

const styles = {
  input: {
    textAlign: 'right'
  }
}

class TextInput extends Component {
  onChange = (event) => {
    const { value } = event.target;
    this.props.onChange(value);
  }

  render() {
    const { value, classes } = this.props;

    return (
      <TextField
        value={value}
        onChange={this.onChange}
        inputProps={{ className: classes.input }}
      />
    );
  }

}

export default injectSheet(styles)(TextInput);
