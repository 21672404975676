import React, { Component } from 'react';
import { connect } from 'react-redux';
import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import Tabs from '../components/Tabs';
import { insertPage, movePage } from '../actions/pages';
import { setCurrentPage } from 'composer-blocks/lib/actions/site';
import { orderPages } from 'composer-blocks/lib/helpers/pages';
import { setActivePage } from '../actions/app';

class PageTabs extends Component {

  setCurrentPage = (pageId) => () => this.props.setCurrentPage(pageId)
  setActivePage = (pageId) => () => this.props.setActivePage(pageId)
  movePage = (pageId) => (index) => this.props.movePage(pageId, index)

  render() {
    const { pages, pageOrder, currentPageId, insertPage } = this.props;

    const items = orderPages(pages, pageOrder).map(page => {
      return {
        key: page.id,
        onClick: this.setCurrentPage(page.id),
        active: currentPageId === page.id,
        name: page.name,
        draggable: true,
        moveAction: this.movePage(page.id),
        iconButton: {
          onClick: this.setActivePage(page.id),
          label: `Edit ${page.name}`,
          iconClass: Edit,
        }
      }
    })
    items.push({
      key: 'Add Page',
      onClick: insertPage,
      active: false,
      iconButton: {
        onClick: undefined,
        label: 'Add Page',
        iconClass: Add,
      }
    })

    return (
      <Tabs items={items} />
    );
  }
}

const mapStateToProps = ({ site, pages, pageOrder }) => ({
  currentPageId: site.currentPageId,
  pages,
  pageOrder
});

const mapDispatchToProps = {
  insertPage,
  movePage,
  setCurrentPage,
  setActivePage,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageTabs);
