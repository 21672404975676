import { CUSTOM } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'custom_4',
  type: CUSTOM,
  name: 'Custom with cards',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/custom/custom_4.png',
  rootContent: {
    type: contentTypes.ROW,
    properties: {
      backgroundColor: {type: 'css', value: '#FFFFFF', editable: true},
      color: {type: 'css', value: ''},
      fontWeight: {type: 'css', value: 'bold'},
      padding: {type: 'css', value: '0'},
      display: {type: 'css', value: 'flex'},
      justifyContent: {type: 'css', value: 'center'}
    },
    contents: [
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 2},
          medium: {type: 'responsive', value: 5},
          padding: {type: 'css', value: 0},
          margin: {type: 'css', value: '2rem 1rem 2rem 1rem'},
          borderStyle: {type:'css', value:'none solid solid solid'},
          borderColor: {type:'css' , value: '#E4E8E8'}
        },
        contents: [
          {
            type: contentTypes.IMAGE,
            properties: {
              src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/medium/cup.png', editable:true},
              width: {type: 'css', value:'100%'},
              minHeight: {type: 'css', value:'14rem'},
              height: {type: 'css', value:'14rem'},
              borderStyle: {type:'css', value:'none none solid none'},
              borderColor: {type:'css' , value: '#E4E8E8'},
              objectFit: {type:'css', value:'cover'}
            },
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy',
            properties: {
              padding: {type: 'css', value: '1rem'},
              color: {type: 'css', value: '#777777', editable: true},
              fontWeight: {type: 'css', value: 'bold'}
            },
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            properties: {
              padding: {type: 'css', value: '1rem'},
              color: {type: 'css', value: '#777777', editable: true},
              fontWeight: {type: 'css', value: 'normal'},

            },
          }

        ]
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 2},
          medium: {type: 'responsive', value: 5},
          padding: {type: 'css', value: 0},
          margin: {type: 'css', value: '2rem 1rem 2rem 1rem'},
          borderStyle: {type:'css', value:'none solid solid solid'},
          borderColor: {type:'css' , value: '#E4E8E8'}

        },
        contents: [
          {
            type: contentTypes.IMAGE,
            properties: {
              src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/medium/foggy_forest.png', editable:true},
              width: {type: 'css', value:'100%'},
              minHeight: {type:'css', value:'14rem'},
              height: {type: 'css', value:'14rem'},
              objectFit: {type:'css', value:'cover'},
              borderStyle: {type:'css', value:'none none solid none'},
              borderColor: {type:'css' , value: '#E4E8E8'}
            },
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy',
            properties: {
              padding: {type: 'css', value: '1rem'},
              color: {type: 'css', value: '#777777', editable: true},
              fontWeight: {type: 'css', value: 'bold'}},
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            properties: {
              margin: {type: 'css', value: '1rem'},
              color: {type: 'css', value: '#777777', editable: true},
              fontWeight: {type: 'css', value: 'normal'}},
          }

        ]
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 2},
          medium: {type: 'responsive', value: 5},
          padding: {type: 'css', value: 0},
          margin: {type: 'css', value: '2rem 1rem 2rem 1rem'},
          borderStyle: {type:'css', value:'none solid solid solid'},
          borderColor: {type:'css' , value: '#E4E8E8'}


        },
        contents: [
          {
            type: contentTypes.IMAGE,
            properties: {
              src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/medium/clock.png', editable:true},
              width: {type: 'css', value:'100%'},
              minHeight: {type: 'css', value:'14rem'},
              height: {type: 'css', value:'14rem'},
              borderStyle: {type:'css', value:'none none solid none'},
              borderColor: {type:'css' , value: '#E4E8E8'},
              objectFit: {type:'css', value:'cover'},

            },
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy',
            properties: {
              padding: {type: 'css', value: '1rem'},
              color: {type: 'css', value: '#777777', editable: true},
              fontWeight: {type: 'css', value: 'bold'}},
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            properties: {
              margin: {type: 'css', value: '1rem'},
              color: {type: 'css', value: '#777777', editable: true},
              fontWeight: {type: 'css', value: 'normal'}},
          }

        ]
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 2},
          medium: {type: 'responsive', value: 5},
          padding: {type: 'css', value: 0},
          margin: {type: 'css', value: '2rem 1rem 2rem 1rem'},
          borderStyle: {type:'css', value:'none solid solid solid'},
          borderColor: {type:'css' , value: '#E4E8E8'}

        },
        contents: [
          {
            type: contentTypes.IMAGE,
            properties: {
              src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/medium/quay.png', editable:true},
              width: {type: 'css', value:'100%'},
              minHeight: {type: 'css', value:'14rem'},
              height: {type: 'css', value:'14rem'},
              borderStyle: {type:'css', value:'none none solid none'},
              borderColor: {type:'css' , value: '#E4E8E8'},
              objectFit: {type:'css', value:'cover'}

            },
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy',
            properties: {
              padding: {type: 'css', value: '1rem'},
              color: {type: 'css', value: '#777777', editable: true},
              fontWeight: {type: 'css', value: 'bold'}},
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            properties: {
              margin: {type: 'css', value: '1rem'},
              color: {type: 'css', value: '#777777', editable: true},
              fontWeight: {type: 'css', value: 'normal'}},
          }

        ]
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 2},
          medium: {type: 'responsive', value: 5},
          padding: {type: 'css', value: 0},
          margin: {type: 'css', value: '2rem 1rem 2rem 1rem'},
          borderStyle: {type:'css', value:'none solid solid solid'},
          borderColor: {type:'css' , value: '#E4E8E8'}

        },
        contents: [
          {
            type: contentTypes.IMAGE,
            properties: {
              src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/medium/structure.png', editable:true},
              width: {type: 'css', value:'100%'},
              minHeight: {type: 'css', value:'14rem'},
              height: {type: 'css', value:'14rem'},
              borderStyle: {type:'css', value:'none none solid none'},
              borderColor: {type:'css' , value: '#E4E8E8'},
              objectFit: {type:'css', value:'cover'}

            },
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy',
            properties: {
              padding: {type: 'css', value: '1rem'},
              color: {type: 'css', value: '#777777', editable: true},
              fontWeight: {type: 'css', value: 'bold'}},
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            properties: {
              margin: {type: 'css', value: '1rem'},
              color: {type: 'css', value: '#777777', editable: true},
              fontWeight: {type: 'css', value: 'normal'}},
          }
        ]
      }
    ]
  }
}
