import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    type: 'dark',
    primary: { main: '#343D4B' },
    secondary: { main: '#FF9977' },
  },
  typography: {
    useNextVariants: true,
    fontFamily: "'Quattrocento Sans', sans-serif",
  },
});
