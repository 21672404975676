import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Done from '@material-ui/icons/Done';
import TranslatableIconButton from '../components/TranslatableIconButton';
import ColorSettings from '../components/ColorSettings';
import { updateGlobalSetting } from "../actions/globalSettings";
import injectSheet from 'react-jss';
import { translate } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import { isNullOrUndefined } from '../helpers/misc'

const styles = theme => ({
  root:{
    marginTop: '20px',
  },
  settingInputs:{
    marginBottom: '1rem',
    '& .top-panel':{
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      '& .inputs':{
        display: 'flex',
      },
    },
  },
});


class PasswordSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      passwordEnabled: !isNullOrUndefined(props.sitePassword, true),
      loginBackgroundColor: props.loginBackgroundColor || {r: 85, g: 40, b: 85, a: 1},
      sitePassword: isNullOrUndefined(props.sitePassword, true) ? '' : atob(props.sitePassword),
      hasChanged: false,
      errorMsg: null
    };
  }

  componentDidUpdate(prevProps){
    if(isNullOrUndefined(prevProps.sitePassword, true) && !isNullOrUndefined(this.props.sitePassword, true)){
      this.initialFetchProp('sitePassword');
    }
    if(isNullOrUndefined(prevProps.loginBackgroundColor, false) && !isNullOrUndefined(this.props.loginBackgroundColor, false)){
      this.initialFetchProp('loginBackgroundColor');
    }
  }

  componentDidMount() {
    this.initialFetchProp('sitePassword');
    this.initialFetchProp('loginBackgroundColor');
  }

  initialFetchProp = (propType) => {
    if(!isNullOrUndefined(this.props[propType], true)){
      const newState = {};
      if(propType === 'sitePassword') {
        newState[propType] = atob(this.props[propType]);
        newState["passwordEnabled"] = !isNullOrUndefined(this.props[propType], true);
      }
      else newState[propType] = this.props[propType];
      this.setState(newState);
    }
  }

  onChangeCheckboxState = (event) => this.setState({ passwordEnabled: event.target.checked, hasChanged: true, errorMsg: null })

  onChangeTextInput = (name) => (event) => this.setState({ [name]: event.target.value, hasChanged: true, errorMsg: null })

  changeColorState = (event) => this.setState({ loginBackgroundColor: event, hasChanged: true })

  handleUpdate = () => {
    const { t } = this.props;
    const { sitePassword, passwordEnabled, loginBackgroundColor } = this.state;
    if(sitePassword === '' && passwordEnabled){ this.setState({ errorMsg: t('errors.empty_password')}); return; }
    this.props.updateGlobalSetting('sitePassword', passwordEnabled ? btoa(sitePassword) : '');
    this.props.updateGlobalSetting('loginBackgroundColor', loginBackgroundColor );
    this.setState({ hasChanged: false })
  }

  render(){
    const { classes, t } = this.props;
    const { loginBackgroundColor } = this.state;
    const { r, g, b, a = 1 } = loginBackgroundColor;

    return(
      <div className={classes.settingInputs}>
        <div className='top-panel'>
          <FormGroup
            row
            classes={{root: classes.root}}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.passwordEnabled}
                  onChange={this.onChangeCheckboxState}
                />
              }
              label={t('labels.enable_site_password')}
            />
            <TranslatableIconButton
              tPath={"buttons.update"}
              onClick={this.handleUpdate}
              Icon={Done}
              buttonProps={{ disabled: (!this.state.hasChanged)}}
              divButtonProps={{ style: {display:'inline-block', height: '48px', marginLeft: '1rem'}}}
            />
          </FormGroup>
          {this.state.passwordEnabled && (
            <div
              className='inputs'
            >
              <FormControl className={classes.formControl} error aria-describedby="component-error-text">
                <TextField
                  label={t('labels.password')}
                  onChange={this.onChangeTextInput('sitePassword')}
                  value={this.state.sitePassword}
                  disabled={!this.state.passwordEnabled}
                />
                {!!this.state.errorMsg && (
                  <FormHelperText>{this.state.errorMsg}</FormHelperText>
                )}
              </FormControl>
              <ColorSettings
                label={t('labels.login_main_color')}
                value={this.state.loginBackgroundColor}
                onChange={this.changeColorState}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ globalSettings }) => ({
  sitePassword: globalSettings.sitePassword,
  loginBackgroundColor: globalSettings.loginBackgroundColor
})

const mapDispatchToProps = {
  updateGlobalSetting
}

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(translate('Forms')(PasswordSettings)));
