import React, { Component } from 'react';
import Gallery from '../components/Gallery';
import { GALLERY_PAGE_SIZE} from '../constants/api';

const stockImages = [
  // Here audience images
  {name: 'professorship', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/professorship.jpg'},
  {name: 'symposium', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/symposium.jpg'},
  {name: 'Audience', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/audience.jpg'},
  {name: 'Concert', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/concert.jpg'},
  {name: 'Fans', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/fans.jpg'},
  {name: 'Stadium', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/stadium.jpg'},
  // Here diagrams images
  {name: 'explaining charts', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/explaining_charts.jpg'},
  {name: '2d charts', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/2d_charts.jpg'},
  {name: '3d charts', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/3d_charts.jpg'},
  {name: 'office meeting charts', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/office_meeting_charts.jpg'},
  {name: 'Diagram', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/diagram.jpg'},
  // Here meeting images
  {name: 'People coffee', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/people_coffee.png'},
  {name: 'People working', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/people_working.jpg'},
  {name: 'Woman computer', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/woman_computer.png'},
  {name: 'talking office', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/talking_office.jpg'},
  {name: 'corporative meeting', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/corporative_meeting.jpg'},
  {name: 'office ambient', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/office_ambient.jpg'},
  {name: 'comfortable meeting', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/comfortable_meeting.jpg'},
  {name: 'elegant house', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/elegant_house.jpg'},
  {name: 'modern office', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/modern_office.jpg'},
  {name: 'meeting table', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/meeting_table.jpg'},
  {name: 'glass dashboard', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/glass_dashboard.jpg'},
  {name: 'builders meeting', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/builders_meeting.jpg'},
  {name: 'virtual bank', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/virtual_bank.jpg'},
  {name: 'meeting laptops', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/meeting_laptops.jpg'},
  {name: 'new horizonts', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/new_horizonts.jpg'},
  {name: 'laptop working', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/laptop_working.jpg'},
  {name: 'office calling', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/office_calling.jpg'},
  // Here ceremony images
  {name: 'Birthday blue', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/birthday_blue.jpg'},
  {name: 'Bowl breakfast', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/bowl_breakfast.jpg'},
  {name: 'Ceremony 2', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/ceremony_2.jpg'},
  {name: 'Ceremony', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/ceremony.jpg'},
  {name: 'Dessert', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/dessert.jpg'},
  // Here landscape images
  {name: 'Landscape', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/medium/landscape.png'},
  {name: 'Landscape lake', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/landscape_lake.png'},
  {name: 'Bicycle landscape', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/bicycle_landscape.jpg'},
  {name: 'Woman city', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/woman_city.jpg'},
  {name: 'Bicycling', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/bicycling.jpg'},
  // Here another images
  {name: 'Couple', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/medium/couple.png'},
  {name: 'Painting', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/painting.jpg'},
  {name: 'Palette', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/palette.jpg'},
  {name: 'Background blue circles', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/background_blue_circles.png'},
  {name: 'Background house', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/background_house.png'},
  {name: 'Background man', url: 'https://eventtia-composer.s3.amazonaws.com/assets/images/large/background_man.png'},
];
stockImages.forEach(image => image.thumb = image.url.replace('/large/', '/large/thumb/').replace('/medium/', '/medium/thumb/'));

class StockImagesGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
    };
  }

  setPage = (page) => this.setState({ page })
  getLinks = () => {
    const { page } = this.state;
    const last = Math.ceil(stockImages.length / GALLERY_PAGE_SIZE);
    return {
      first: 1,
      prev: (page === 1) ? 0 : (page - 1),
      self: page,
      next: (page === last) ? 0 : (page + 1),
      last,
    }
  }

  render() {
    const page = (this.state.page - 1) * GALLERY_PAGE_SIZE;
    const images = stockImages.slice(page, page + GALLERY_PAGE_SIZE)

    return (
      <Gallery
        {...this.props}
        fetchPage={this.setPage}
        links={this.getLinks()}
        images={images}
      />
    );
  }
}


export default StockImagesGallery;
