import { WORKSHOPS } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'workshops_4',
  type: WORKSHOPS,
  name: 'Activity dropdown',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/workshops/workshops_4.png',
  rootContent: {
    type: contentTypes.WORKSHOP_DROPDOWN,
    properties: {
      backgroundColor: {type: 'css', value: '#FFFFFF', editable: true},
      workshopTextColor: {type: 'custom', value: '#FFFFFF', editable: true},
      workshopColor: {type: 'custom', value: '#545458', editable: true},
      workshopAccentColor: {type: 'custom', value: '#F5A623', editable: true},
    }
  }
}
