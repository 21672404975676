import { EXHIBITORS } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'exhibitors_1',
  type: EXHIBITORS,
  name: 'Exhibitor grid',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/exhibitors/exhibitors_1.png',
  rootContent: {
    type: contentTypes.EXHIBITORS_GRID,
    properties: {
    },
    contents: []
  }
}
