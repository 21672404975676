import React, { Component } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import UploadIcon from '@material-ui/icons/CloudUpload';
import Done from '@material-ui/icons/Done';
import Close from '@material-ui/icons/Close';
import TranslatableButton from '../components/TranslatableButton';
import TranslatableIconButton from '../components/TranslatableIconButton';
import { uploadFile, UPLOAD_FILE_SUCCESS } from '../actions/app';
import { updateGlobalSetting } from "../actions/globalSettings";
import injectSheet from 'react-jss';
import { translate } from 'react-i18next';
import { validateImageURL } from '../helpers/misc';

const styles = theme => ({
  hiddenInput: {
    display: 'none'
  },
  toggleButton:{
    padding:'0.1rem',
    '& span':{
      '& span':{
        margin: '0.6rem',
      }
    }
  },
  toggleButtonDisabled:{
    padding:'0.1rem',
    backgroundColor: '#39404a',
    '& span':{
      '& span':{
        margin: '0.6rem',
      }
    }
  },
  fullWidth:{
    width: '100%',
  },
  formControl:{
    margin:'1rem 1rem 0rem 1rem',
    width: '55%',
    ['@media (max-width: 960px)']: {
      width: '95%',
    },
  },
  formControlSmall:{
    margin:'1rem 1rem 0rem 1rem',
    width: '15%',
    ['@media (max-width: 960px)']: {
      width: '95%',
    },
  },
  iconButton: {
    marginTop: '1.7rem',
  },
  iconButtonUpload: {
    marginBottom: '2rem',
  },
  uploadingImage:{
    marginTop: '2.5rem'
  },
  bottonPanel:{
    padding:'1rem',
    marginBottom: '1rem',
    '& .button-panel':{
      display: 'flex',
      justifyContent: 'center',
      '& .toggle-button':{
        marginRight: '1rem',
      }
    }
  },
  settingHeader:{
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .favicon-loader':{
      width: '24px',
      height: '24px',
    },
    '& .favicon-preview':{
      width: '24px',
      height: '24px',
      objectFit: 'cover',
    },
    '& .setting-name':{
      paddingLeft: '1rem',
      fontSize: '24px'
    },
  },
  settingInputs:{
    '& .top-panel':{
      padding:'1rem',
      display: 'flex',
      justifyContent: 'center',
    },
  }
});


class FaviconSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      inputUrl: '',
      fromUrl: true,
      loadingFavicon: false,
      name: '',
      description: '',
      uploadError: [],
      urlError: null,
      selectedImage: false,
      loadedFaviconUrl: false,
      isUploadingImage: false
    };
    this.faviconRef = React.createRef();
    this.hiddenFileInput = React.createRef();
  }


  componentDidUpdate(){
    this.initialFetchFavicon();
  }

  componentDidMount() {
    this.initialFetchFavicon();
  }

  //Jan 22 - Cristian Ospina
  //Fetch image from url if its exist in redux state
  initialFetchFavicon = () => {
    const { faviconUrl } = this.props;
    if(!!faviconUrl && !this.state.loadedFaviconUrl){
      this.setState({
        loadedFaviconUrl: true,
        inputUrl: faviconUrl,
      });
      this.fetchUrlFavicon(faviconUrl,false);
    }
  }

  triggerInput = () => {
    this.hiddenFileInput.current.click();
  }

  //Jan 22 - Cristian Ospina
  //handle select image event from input
  onChangeFileUploader = () => {
    const file = this.hiddenFileInput.current.files[0];
    const { t } = this.props;
    if (!!file){
      this.setState({ uploadError: [] });
      if(!file.type.match('image.*')){
        this.setState(prevState => ({
          uploadError: [...prevState.uploadError, t('errors.not_image_format')]
        }));
        return;
      }
      if(file.size && file.size > 530000){
        this.setState(prevState => ({
          uploadError: [...prevState.uploadError, t('errors.exceeded_limit')]
        }));
        return;
      }
      let reader = new FileReader();
      reader.onloadend = (e) => {
        const imgDOM = this.faviconRef.current;
        imgDOM.src = e.target.result;
      }
      reader.readAsDataURL(file);
      this.setState({
        selectedImage: true,
      })
    }
  }

  //Jan 22 - Cristian Ospina
  //Call function that fetch image from url
  onChangeUrlInput = (event) => {
    let val = event.target.value;
    this.fetchUrlFavicon(val,true);
  }

  onChangeTextInput = (name) => (event) => this.setState({ [name]: event.target.value })

  //Jan 22 - Cristian Ospina
  //Fetch image from url
  fetchUrlFavicon = (val,changed) => {
    this.setState({ loadingFavicon: true, urlError: null });
    if(val !== ''){
      validateImageURL(val)
      .then((image) => {
        this.setState({ loadingFavicon: false, inputUrl: image },() => {
          const imgDOM = this.faviconRef.current;
          imgDOM.src = image;
        });
        if(changed)
          this.props.updateGlobalSetting('faviconUrl',image);
      })
      .catch((errorMessage) => {
        this.setState({ loadingFavicon: false, urlError: errorMessage, inputUrl: val })
      });
    }
    else{
      this.setState({ loadingFavicon: false, inputUrl: val })
    }
  }

  changeMethod = () => {
    this.setState({
      fromUrl: !this.state.fromUrl,
    })
  }

  //Jan 22 - Cristian Ospina
  //Upload selected image to event files in eventtia
  uploadFavicon = () => {
    const { name, description, uploadError } = this.state;
    const { files } = this.hiddenFileInput.current;
    this.setState({
      isUploadingImage: true,
    })
    if (!!files && !!files[0] && uploadError.length === 0) {
      this.props.uploadFile(name, description, files[0]).then(action => {
        if (action.type === UPLOAD_FILE_SUCCESS) {
          const eventFile = Object.values(action.response.entities.event_files)[0]
          this.props.updateGlobalSetting('faviconUrl', eventFile.urls.original);
          this.setState({
            isUploadingImage: false,
          })
        }
      });
    }
  }

  render(){
    const { classes, t } = this.props;
    return(
      <React.Fragment>
        <div className={classes.settingHeader}>
          {this.state.loadingFavicon ? (
            <CircularProgress className='favicon-loader' size={30}/>
          ):(
            <img alt='favicon' ref={this.faviconRef} className='favicon-preview' src='https://live.eventtia.com/favicon.ico'/>
          )}
          <p className='setting-name'>Favicon</p>
        </div>
        <div className={classes.settingInputs}>
          <div className='top-panel'>
            {this.state.fromUrl ? (
              <FormControl className={classes.formControl} error aria-describedby="component-error-text">
                <TextField
                  label="URL"
                  classes={{root: classes.fullWidth}}
                  onChange={this.onChangeUrlInput}
                  value={this.state.inputUrl}
                />
              {!!this.state.urlError && (
                  <FormHelperText>{this.state.urlError}</FormHelperText>
              )}
              </FormControl>
            ) : (
              <div className={classes.fullWidth}>
                  <TextField
                    label={t('labels.name')}
                    classes={{root: classes.formControlSmall}}
                    onChange={this.onChangeTextInput('name')}
                    value={this.state.name}
                  />
                <FormControl className={classes.formControl} error aria-describedby="component-error-text">
                  <TextField
                    label={t('labels.description')}
                    classes={{root: classes.fullWidth}}
                    onChange={this.onChangeTextInput('description')}
                    value={this.state.description}
                  />
                {this.state.uploadError.length > 0 && this.state.uploadError.map(errorMessage => {
                  return(
                    <FormHelperText key={errorMessage}>{errorMessage}</FormHelperText>
                  )
                })
                }
                </FormControl>
                <input type="file" accept="image/*" className={classes.hiddenInput} ref={this.hiddenFileInput} onChange={this.onChangeFileUploader} />
                <TranslatableButton
                  leftIcon={<UploadIcon />}
                  tPath={'buttons.select_image'}
                  buttonProps={{ className: this.state.isUploadingImage ? classes.iconButtonUpload : classes.iconButton}}
                  onClick={this.triggerInput}
                />
              {this.state.isUploadingImage ? (
                  <CircularProgress className={classes.uploadingImage} size={30}/>
                ):(
                  <TranslatableIconButton
                    text="Upload"
                    onClick={this.uploadFavicon}
                    Icon={Done}
                    buttonProps={{ className: classes.iconButton, disabled: (this.state.name === '' || !this.state.selectedImage)  }}
                    divButtonProps={{ style: {display:'inline-block'}}}
                  />
                )}
            </div>
            )}
          </div>
          <div className={classes.bottonPanel} style={{marginTop: this.state.isUploadingImage ? '-0.78rem' : '1rem'}}>
            <div className='button-panel'>
              <div className='toggle-button'>
                <TranslatableButton
                  onClick={this.changeMethod}
                  tPath='buttons.url_image'
                  buttonProps={{ className: !this.state.fromUrl ? classes.toggleButton : classes.toggleButtonDisabled }}
                  disabled = {this.state.fromUrl}
                />
                <TranslatableButton
                  onClick={this.changeMethod}
                  tPath='buttons.upload_image'
                  buttonProps={{ className: this.state.fromUrl ? classes.toggleButton : classes.toggleButtonDisabled }}
                  disabled = {!this.state.fromUrl}
                />
              </div>
              <TranslatableButton
                onClick={this.discardChanges}
                leftIcon={<Close />}
                tPath="buttons.discard_changes"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}


const mapStateToProps = ({ app, globalSettings }) => ({
    uploadStatus: app.uploadFile,
    faviconUrl: globalSettings.faviconUrl
  })

const mapDispatchToProps = {
  uploadFile,
  updateGlobalSetting
}


export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(translate('Forms')(FaviconSettings)));
