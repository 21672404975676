export const
  ENGLISH = 'en',
  SPANISH = 'es',
  FRENCH = 'fr',
  GERMAN = 'de'
;

const languages = {
  [ENGLISH]: {locale: ENGLISH, name: 'English'},
  [SPANISH]: {locale: SPANISH, name: 'Español'},
  [FRENCH]: {locale: FRENCH, name: 'Français'},
  [GERMAN]: {locale: GERMAN, name: 'Deutsch'},
};

export default languages;
