import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Save from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import { saveModifiedItems } from '../actions/app';

const styles = {
  progress: {
    color: 'white'
  }
};

class SaveButton extends Component {

  render() {
    const { classes, saveModifiedItems, active, isFetching } = this.props;

    return isFetching ? (
      <CircularProgress className={classes.progress} size={25}/>
    ) : (
      <IconButton disabled={!active} color="inherit" aria-label="Save" onClick={saveModifiedItems}>
        <Save />
      </IconButton>
    );
  }
}

const mapStateToProps = ({ app }) => ({
  ...app.saveChanges
});

const mapDispatchToProps = {
  saveModifiedItems
};

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(SaveButton));
