import { UPDATE_GLOBAL_SETTING } from "../actions/globalSettings";
import { COMPOSER_SETTINGS_SUCCESS } from "composer-blocks/lib/actions/site";
import { getEntityFromResponse } from 'composer-blocks/lib/helpers/api';


const globalSettings = (state = {faviconUrl: null }, action) => {
  switch (action.type) {
    case UPDATE_GLOBAL_SETTING:
      return {
        ...state,
        [action.key]: action.value,
      }
    case COMPOSER_SETTINGS_SUCCESS:
      return getEntityFromResponse(action, 'globalSettings') || state;
    default:
      return state;
  }
};

export default globalSettings;
