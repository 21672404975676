import { CUSTOM } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'custom_18',
  type: CUSTOM,
  name: 'Custom column borders',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/custom/custom_18.jpg',
  rootContent: {
    type: contentTypes.ROW,
    properties: {
      backgroundColor: {type: 'css', value: '#040433',editable:true},
      fontWeight: {type: 'css', value: 'bold'},
      padding: {type: 'css', value: '1rem'},
      display: {type: 'css', value: 'flex'},
      justifyContent: {type: 'css', value: 'flex-start'},
      backgroundImage: {type: 'css', value: '', editable: true},
    },
    contents: [
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 4, editable: true},
          medium: {type: 'responsive', value: 8, editable: true},
          small: {type: 'responsive', value: 12, editable: true},
          display: {type: 'css', value:'flex'},
          flexDirection: {type: 'css', value: 'row'},
          justifyContent: {type: 'css', value: 'flex-start'},
          alignItems: {type: 'css', value: 'center'},
          bordersSelector: {type: 'custom',
                            value: {
                              color:{r:'255',g:'255',b:'255',a:'1'},
                              borderWidth:'thin',
                              borderStyle:'solid',
                              top: false,
                              right: false,
                              bottom: false,
                              left: false,
                              desktop: false,
                              tablet: false,
                              mobile: false},
                              editable: true},
        },
        contents: [
          {
            type: contentTypes.IMAGE,
            properties: {
              src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/icons/icon_technology_2.png', editable:true},
              objectFit: {type: 'css', value: 'contain'},
              width: {type: 'css', value:'6rem'},
              height: {type: 'css', value:'6rem'},
              marginLeft: {type: 'css', value: 'auto'},
              marginRight: {type: 'css', value: 'auto'},
              display: {type: 'css', value: 'block'}
            },
          },
          {
            type: contentTypes.TEXT,
            value: 'BEST COMPANY',
            properties: {
              margin: {type: 'css', value: '1rem auto'},
              color: {type: 'css', value: 'white', editable: true},
              fontWeight: {type: 'css', value: 'bold'},
              fontSize: {type: 'css', value: '24px'},
              textAlign: {type: 'css', value: 'center'},
            }
          },
        ]
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 2, editable: true},
          medium: {type: 'responsive', value: 4, editable: true},
          small: {type: 'responsive', value: 6, editable: true},
          bordersSelector: {type: 'custom',
                            value: {
                              color:{r:'255',g:'255',b:'255',a:'1'},
                              borderWidth:'thin',
                              borderStyle:'solid',
                              top: false,
                              right: false,
                              bottom: false,
                              left: true,
                              desktop: true,
                              tablet: true,
                              mobile: false},
                              editable: true},
        },
        contents: [

          {
            type: contentTypes.IMAGE,
            properties: {
              src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/icons/icon_technology_3.png', editable:true},
              objectFit: {type: 'css', value: 'contain'},
              width: {type: 'css', value:'6rem'},
              height: {type: 'css', value:'6rem'},
              marginLeft: {type: 'css', value: 'auto'},
              marginRight: {type: 'css', value: 'auto'},
              display: {type: 'css', value: 'block'}

            },
          },
          {
            type: contentTypes.TEXT,
            value: 'ABSOLUTE CONFIDENCE',
            properties: {
              margin: {type: 'css', value: '1rem auto'},
              color: {type: 'css', value: 'white', editable: true},
              fontWeight: {type: 'css', value: 'bold'},
              fontSize: {type: 'css', value: '16px'},
              textAlign: {type: 'css', value: 'center'},
              width: {type: 'css', value:'80%'}
            }
          },
        ]
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 2, editable: true},
          medium: {type: 'responsive', value: 4, editable: true},
          small: {type: 'responsive', value: 6, editable: true},
          bordersSelector: {type: 'custom',
                            value: {
                              color:{r:'255',g:'255',b:'255',a:'1'},
                              borderWidth:'thin',
                              borderStyle:'solid',
                              top: false,
                              right: false,
                              bottom: false,
                              left: true,
                              desktop: true,
                              tablet: false,
                              mobile: true},
                              editable: true},
       },
        contents: [
          {
            type: contentTypes.IMAGE,
            properties: {
              src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/icons/icon_technology_3.png', editable:true},
              objectFit: {type: 'css', value: 'contain'},
              width: {type: 'css', value:'6rem'},
              height: {type: 'css', value:'6rem'},
              marginLeft: {type: 'css', value: 'auto'},
              marginRight: {type: 'css', value: 'auto'},
              display: {type: 'css', value: 'block'}
            },
          },
          {
            type: contentTypes.TEXT,
            value: 'APPROVED BY MANY COMPANIES',
            properties: {
              margin: {type: 'css', value: '1rem auto'},
              color: {type: 'css', value: 'white', editable: true},
              fontWeight: {type: 'css', value: 'bold'},
              fontSize: {type: 'css', value: '16px'},
              textAlign: {type: 'css', value: 'center'},
              width: {type: 'css', value:'80%'}
            }
          },
        ]
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 2, editable: true},
          medium: {type: 'responsive', value: 4, editable: true},
          small: {type: 'responsive', value: 6, editable: true},
          bordersSelector: {type: 'custom',
                            value: {
                              color:{r:'255',g:'255',b:'255',a:'1'},
                              borderWidth:'thin',
                              borderStyle:'solid',
                              top: false,
                              right: false,
                              bottom: false,
                              left: true,
                              desktop: true,
                              tablet: true,
                              mobile: false},
                              editable: true},
       },
        contents: [
          {
            type: contentTypes.IMAGE,
            properties: {
              src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/icons/icon_technology_3.png', editable:true},
              objectFit: {type: 'css', value: 'contain'},
              width: {type: 'css', value:'6rem'},
              height: {type: 'css', value:'6rem'},
              marginLeft: {type: 'css', value: 'auto'},
              marginRight: {type: 'css', value: 'auto'},
              display: {type: 'css', value: 'block'}
            },
          },
          {
            type: contentTypes.TEXT,
            value: 'NATIONAL LEADERS',
            properties: {
              margin: {type: 'css', value: '1rem auto'},
              color: {type: 'css', value: 'white', editable: true},
              fontWeight: {type: 'css', value: 'bold'},
              fontSize: {type: 'css', value: '16px'},
              textAlign: {type: 'css', value: 'center'},
              width: {type: 'css', value:'80%'}
            }
          },
        ]
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 2, editable: true},
          medium: {type: 'responsive', value: 4, editable: true},
          small: {type: 'responsive', value: 6, editable: true},
          bordersSelector: {type: 'custom',
                            value: {
                              color:{r:'255',g:'255',b:'255',a:'1'},
                              borderWidth:'thin',
                              borderStyle:'solid',
                              top: false,
                              right: false,
                              bottom: false,
                              left: true,
                              desktop: true,
                              tablet: true,
                              mobile: true},
                              editable: true},
       },
        contents: [
          {
            type: contentTypes.IMAGE,
            properties: {
              src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/icons/icon_technology_3.png', editable:true},
              objectFit: {type: 'css', value: 'contain'},
              width: {type: 'css', value:'6rem'},
              height: {type: 'css', value:'6rem'},
              marginLeft: {type: 'css', value: 'auto'},
              marginRight: {type: 'css', value: 'auto'},
              display: {type: 'css', value: 'block'}
            },
          },
          {
            type: contentTypes.TEXT,
            value: 'GROWING DAY BY DAY',
            properties: {
              margin: {type: 'css', value: '1rem auto'},
              color: {type: 'css', value: 'white', editable: true},
              fontWeight: {type: 'css', value: 'bold'},
              fontSize: {type: 'css', value: '16px'},
              textAlign: {type: 'css', value: 'center'},
              width: {type: 'css', value:'80%'}
            }
          },
        ]
      }
    ]
  }
}
