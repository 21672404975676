import React from 'react';
import { translate } from 'react-i18next';
import CodeEditor from '../CodeEditor';
import 'codemirror/mode/javascript/javascript';

const JsEditor = ({ t, ...props }) => (
  <CodeEditor
    {...props}
    mode="javascript"
    tabName="JavaScript"
    note={t('labels.js_note')}
  />
);

export default translate('Forms')(JsEditor);
