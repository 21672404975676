import { HEADERS } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'header_5',
  type: HEADERS,
  name: 'Blue Skies',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/header/header_5.png',
  rootContent: {
    type: contentTypes.ROW,
    properties: {
      backgroundImage: {type: 'css', value: 'url(https://eventtia-composer.s3.amazonaws.com/assets/images/large/background_dark_lines.png)', editable: true},
      backgroundSize: {type: 'css', value: 'cover'},
      padding: {type: 'css', value: '2rem 0'},
      minHeight: {type: 'css', value: '500px'}
    },
    contents: [
      {
        type: contentTypes.ROW,
        properties: {
          justifyContent: {type: 'css', value: 'center'}
        },
        contents: []
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 12, editable: true },
          medium: {type: 'responsive', value: 12, editable: true },
          small: {type: 'responsive', value: 12, editable: true },
        },
        contents:

        [
          {
            type: contentTypes.EVENT_NAME,
            properties: {
              margin: {type: 'css', value: '0 2rem'},
              fontWeight: {type: 'css', value: 'thin'},
              textAlign: {type: 'css', value: 'center'},
              justifySelf:{type: 'css', value: 'end'},
              color: {type: 'css', value: '#ffffff'},
              fontFamily: {type: 'css', value: 'inherit'},
              overflowWrap: {type: 'css', value: 'break-word'},
              textTransform: {type: 'css', value: 'uppercase'},
              },
          },


          {
            type: contentTypes.TEXT,
            value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            properties: {margin: {type: 'css', value: '0 2rem'},
              textAlign: {type: 'css', value: 'center'},
              color: {type: 'css', value: '#ffffff'},
              fontFamily: {type: 'css', value: 'inherit'},
              fontSize: {type: 'css', value: '1rem'},
              marginTop: {type: 'css', value: '4rem'},
            },
          },
          {
            type: contentTypes.EVENT_ADDRESS,
            properties: {margin: {type: 'css', value: '0 2rem'},
              textAlign: {type: 'css', value: 'center'},
              color: {type: 'css', value: '#ffffff'},
              fontFamily: {type: 'css', value: 'inherit'},
              fontWeight: {type: 'css', value: 'Bold'},
              marginTop: {type: 'css', value: '1rem'},
            },
          },
          {
            type: contentTypes.ROW,
            properties: {
              justifyContent: {type: 'css', value: 'center'}
            },
            contents: [


              {
                type: contentTypes.BUTTON,
                value: 'Register',
                properties: {
                  color: {type: 'css', value: '#ffffff', editable: true},
                  borderColor: {type: 'css', value:'#ffffff', editable: true},
                  borderRadius: {type: 'css', value:'20px', editable: true},
                  backgroundColor: {type: 'css', value:'rgba(0,0,0,0)', editable: true},
                  margin: {type: 'css', value: '1rem 2rem'},
                  alignSelf: {type: 'css', value: 'center', editable: true},
                  href: {type: 'custom', value: 'javascript:alert()', editable: true},
                  minWidth: {type: 'css', value: '150px'},
                  padding: {type: 'css', value:'10px 50px'},
                }
              },

              {
                type: contentTypes.BUTTON,
                value: 'Login',
                properties: {
                  color: {type: 'css', value: '#ffffff', editable: true},
                  borderColor: {type: 'css', value:'#ffffff', editable: true},
                  borderRadius: {type: 'css', value:'20px', editable: true},
                  backgroundColor: {type: 'css', value:'rgba(0,0,0,0)', editable: true},
                  margin: {type: 'css', value: '1rem 2rem'},
                  alignSelf: {type: 'css', value: 'center', editable: true},
                  href: {type: 'custom', value: 'javascript:alert()', editable: true},
                  minWidth: {type: 'css', value: '150px'},
                  padding: {type: 'css', value:'10px 50px'},
                }
              },
            ],
          }
        ]
      },
    ]
  }
}
