import { FREE } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'free',
  type: FREE,
  name: 'Speakers tiles',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/free/free.png',
  rootContent: {
    type: contentTypes.HTML,
    properties: {
      js: {type: 'custom', value: '', editable: true},
    }
  }
}
