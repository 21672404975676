import { CUSTOM } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'custom_9',
  type: CUSTOM,
  name: 'Custom with rows',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/custom/custom_9.png',
  rootContent: {
    type: contentTypes.ROW,
    properties: {
      backgroundImage: {type: 'css', value: 'url(https://eventtia-composer.s3.amazonaws.com/assets/images/large/background_house.png)', editable:true},
      backgroundRepeat: {type: 'css', value: 'no-repeat'},
      backgroundSize: {type: 'css', value:'cover'},
      color: {type: 'css', value: ''},
      fontWeight: {type: 'css', value: 'bold'},
      padding: {type: 'css', value: '0'},
      display: {type: 'css', value: 'flex'},
      justifyContent: {type: 'css', value: 'flex-start'},

    },
    contents: [

      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 4},
          medium: {type: 'responsive', value:12 },
          small: {type: 'responsive', value: 12},
          offsetLarge: {type: 'responsive', value: 1,editable:true},
        },
        contents: [
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy',
            properties: {
              color: {type: 'css', value: 'white', editable: true},
              fontWeight: {type: 'css', value: 'normal'},
              padding: {type: 'css', value: '0 2rem 1rem 0'},
              fontSize: {type: 'css', value: '24px'},
              margin: {type:'css', value: '15rem 1rem 1rem 0'},
              width: {type:'css', value: '50%'}
              }
          },
          {
            type: contentTypes.TEXT,
            value: 'Contact Us',
            properties: {
              margin: {type:'css', value: '1rem 1rem 1rem 0'},
              linkColor: {type: 'css', value: 'white', editable: true},
              color: {type: 'css', value: 'white', editable: true},
              fontWeight: {type: 'css', value: 'normal'},
              padding: {type: 'css', value: '1rem 2rem 1rem 0'},
              textAlign: {type: 'css', value: 'center'},
              minHeight: {type: 'css', value: '2rem'},
              lineHeight: {type: 'css', value: '2rem'},
              backgroundColor: {type: 'css', value: '#FECB2F'},
              width: {type:'css', value: '40%'},
              borderRadius: {type: 'css', value: '5px'},
              fontSize: {type: 'css', value: '20px'},
            }
          }
        ]
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 7},
          medium: {type: 'responsive', value: 12},
          small: {type: 'responsive', value: 12},
          margin: {type:'css', value: 0}
        },
        contents: [
          {
            type: contentTypes.ROW,
            properties: {
              display: {type: 'css', value: 'flex'},
              flexDirection: {type: 'css', value: 'column'},
              justifyContent: {type: 'css', value: 'space-between'},
              margin: {type: 'css', value: 0},
              padding: {type: 'css', value: 0},

            },
            contents: [
              {
                type: contentTypes.COLUMN,
                properties: {
                  large: {type: 'responsive', value: 12},
                  medium: {type: 'responsive', value: 12},
                  small: {type: 'responsive', value: 12},
                  padding: {type:'css', value: '1rem'},
                  backgroundColor: {type: 'css', value: 'white'},
                  minHeight: {type:'css', value: '12rem'},
                  borderRadius: {type:'css', value: '10px'},
                  margin: {type:'css', value: '1rem 0 1rem 0'}
                },
                contents: [
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem Ipsum is simply dummy',
                    properties: {
                      color: {type: 'css', value: 'black', editable: true},
                      fontWeight: {type: 'css', value: 'bold'},
                      padding: {type: 'css', value: '0 2rem 1rem 0'},
                      fontSize: {type: 'css', value: '26px'},
                      margin: {type:'css', value: '1rem 1rem 1rem 2rem'},
                      width: {type:'css', value: '50%'}
                      }
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
                    properties: {
                      color: {type: 'css', value: 'black', editable: true},
                      fontWeight: {type: 'css', value: 'normal'},
                      padding: {type: 'css', value: '0 2rem 1rem 0'},
                      margin: {type:'css', value: '1rem 1rem 0 2rem'},
                      width: {type:'css', value: '80%'}
                      }
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'Contact Us',
                    properties: {
                      margin: {type:'css', value: '0 1rem 0 1rem'},
                      float: {type:'css', value: 'right'},
                      linkColor: {type: 'css', value: 'white', editable: true},
                      color: {type: 'css', value: 'white', editable: true},
                      fontWeight: {type: 'css', value: 'normal'},
                      padding: {type: 'css', value: '0.6rem 1rem 0.6rem 0'},
                      textAlign: {type: 'css', value: 'center'},
                      minHeight: {type: 'css', value: '2rem'},
                      lineHeight: {type: 'css', value: '2rem'},
                      backgroundColor: {type: 'css', value: '#FECB2F'},
                      width: {type:'css', value: '8rem'},
                      borderRadius: {type: 'css', value: '5px'},
                      fontSize: {type: 'css', value: '20px'}
                    }
                  }
                ]
              },
              {
                type: contentTypes.COLUMN,
                properties: {
                  large: {type: 'responsive', value: 12},
                  medium: {type: 'responsive', value: 12},
                  small: {type: 'responsive', value: 12},
                  padding: {type:'css', value: '1rem'},
                  backgroundColor: {type: 'css', value: 'white'},
                  minHeight: {type:'css', value: '12rem'},
                  borderRadius: {type:'css', value: '10px'},
                  margin: {type:'css', value: '1rem 0 1rem 0'},
                },
                contents: [
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem Ipsum is simply dummy',
                    properties: {
                      color: {type: 'css', value: 'black', editable: true},
                      fontWeight: {type: 'css', value: 'bold'},
                      padding: {type: 'css', value: '0 2rem 1rem 0'},
                      fontSize: {type: 'css', value: '26px'},
                      margin: {type:'css', value: '1rem 1rem 1rem 2rem'},
                      width: {type:'css', value: '50%'}
                      }
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
                    properties: {
                      color: {type: 'css', value: 'black', editable: true},
                      fontWeight: {type: 'css', value: 'normal'},
                      padding: {type: 'css', value: '0 2rem 1rem 0'},
                      margin: {type:'css', value: '1rem 1rem 0 2rem'},
                      width: {type:'css', value: '80%'}

                      }
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'Contact Us',
                    properties: {
                      margin: {type:'css', value: '0 1rem 0 1rem'},
                      float: {type:'css', value: 'right'},
                      linkColor: {type: 'css', value: 'white', editable: true},
                      color: {type: 'css', value: 'white', editable: true},
                      fontWeight: {type: 'css', value: 'normal'},
                      padding: {type: 'css', value: '0.6rem 1rem 0.6rem 0'},
                      textAlign: {type: 'css', value: 'center'},
                      minHeight: {type: 'css', value: '2rem'},
                      lineHeight: {type: 'css', value: '2rem'},
                      backgroundColor: {type: 'css', value: '#FECB2F'},
                      width: {type:'css', value: '8rem'},
                      borderRadius: {type: 'css', value: '5px'},
                      fontSize: {type: 'css', value: '20px'}
                    }
                  }
                ]
              },
              {
                type: contentTypes.COLUMN,
                properties: {
                  large: {type: 'responsive', value: 12},
                  medium: {type: 'responsive', value: 12},
                  small: {type: 'responsive', value: 12},
                  padding: {type:'css', value: '1rem'},
                  backgroundColor: {type: 'css', value: 'white'},
                  minHeight: {type:'css', value: '12rem'},
                  borderRadius: {type:'css', value: '10px'},
                  margin: {type:'css', value: '1rem 0 1rem 0'}
                },
                contents: [
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem Ipsum is simply dummy',
                    properties: {
                      color: {type: 'css', value: 'black', editable: true},
                      fontWeight: {type: 'css', value: 'bold'},
                      padding: {type: 'css', value: '0 2rem 1rem 0'},
                      fontSize: {type: 'css', value: '26px'},
                      margin: {type:'css', value: '1rem 1rem 1rem 2rem'},
                      }
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
                    properties: {
                      color: {type: 'css', value: 'black', editable: true},
                      fontWeight: {type: 'css', value: 'normal'},
                      padding: {type: 'css', value: '0 2rem 1rem 0'},
                      margin: {type:'css', value: '1rem 1rem 0 2rem'},
                      width: {type:'css', value: '80%'}

                      }
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'Contact Us',
                    properties: {
                      margin: {type:'css', value: '0 1rem 0 1rem'},
                      float: {type:'css', value: 'right'},
                      linkColor: {type: 'css', value: 'white', editable: true},
                      color: {type: 'css', value: 'white', editable: true},
                      fontWeight: {type: 'css', value: 'normal'},
                      padding: {type: 'css', value: '0.6rem 1rem 0.6rem 0'},
                      textAlign: {type: 'css', value: 'center'},
                      minHeight: {type: 'css', value: '2rem'},
                      lineHeight: {type: 'css', value: '2rem'},
                      backgroundColor: {type: 'css', value: '#FECB2F'},
                      width: {type:'css', value: '8rem'},
                      borderRadius: {type: 'css', value: '5px'},
                      fontSize: {type: 'css', value: '20px'}
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
}
