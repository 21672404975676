import { CUSTOM } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'custom_0',
  type: CUSTOM,
  name: 'Blank custom',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/custom/custom_0.png',
  rootContent: {
    type: contentTypes.ROW,
    properties: {
      backgroundRepeat: {type: 'css', value: 'no-repeat', editable: true},
      backgroundSize: {type: 'css', value:'cover', editable: true},
      backgroundPosition: {type: 'css', value:'center', editable: true},
      minHeight: {type: 'css', value: '3rem', editable: true}
    },
    contents: [
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 12, editable: true},
          medium: {type: 'responsive', value: 12, editable: true},
        }
      },
    ]
  }
}
