//Jan 22 - Cristian Ospina
//check if image with src = url it is valid
export const validateImageURL = (url,timeoutParam) => {
  return new Promise( (resolve,reject) => {
        let timeout = timeoutParam || 5000;
        let timer, img = new Image();
        img.onerror = img.onabort = () => {
            clearTimeout(timer);
            reject("Error");
        };
        img.onload = () => {
            clearTimeout(timer);
            resolve(url);
        };
        timer = setTimeout( () => {
            // reset .src to invalid URL so it stops previous
            // loading, but doesn't trigger new load
            img.src = "//!!!!/test.jpg";
            reject("Timeout");
        }, timeout);
        img.src = url;
    });
}

export const isNullOrUndefined= (element, checkEmpty = false) => {
    return element === null || typeof element === 'undefined' || (checkEmpty && element === '')
  }
