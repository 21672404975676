import { DELETE_PAGE } from "../actions/pages";
import { INSERT_BLOCK, DELETE_BLOCK, INSERT_NAVBAR, DELETE_NAVBAR, INSERT_FOOTER, DELETE_FOOTER } from "../actions/blocks";
import { INSERT_CONTENT, DELETE_CONTENT } from "../actions/contents";
import { PUBLISH_MODIFIED_ITEMS, SAVE_MODIFIED_ITEMS } from "../actions/app";
import { COMPOSER_SETTINGS_SUCCESS } from "composer-blocks/lib/actions/site";
import { deleteWhere } from 'composer-blocks/lib/helpers/hasMany';
import { getEntityFromResponse } from 'composer-blocks/lib/helpers/api';

const byId = (state = {}, action) => {
  switch (action.type) {
    case INSERT_NAVBAR:
    case INSERT_FOOTER:
    case INSERT_BLOCK:
    case INSERT_CONTENT:
      const { properties } = action;

      return {
        ...state,
        ...properties
      };
    case PUBLISH_MODIFIED_ITEMS:
    case SAVE_MODIFIED_ITEMS:
      return {
        ...state,
        ...action.modifiedProperties
      };
    case DELETE_PAGE:
    case DELETE_NAVBAR:
    case DELETE_FOOTER:
    case DELETE_BLOCK:
    case DELETE_CONTENT:
      const { childProperties } = action;
      return deleteWhere(state, 'id', childProperties);
    case COMPOSER_SETTINGS_SUCCESS:
      return getEntityFromResponse(action, 'properties') || state;
    default:
      return state;
  }
};

export default byId;
