import React, { Component } from 'react';
import { connect } from 'react-redux';
import TranslatableIconButton from '../components/TranslatableIconButton';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Done from '@material-ui/icons/Done';
import SettingsOverscan from '@material-ui/icons/SettingsOverscan';
import MenuItem from '@material-ui/core/MenuItem';
import { updateGlobalSetting } from "../actions/globalSettings";
import injectSheet from 'react-jss';
import { translate } from 'react-i18next';

const styles = theme => ({
  formControl:{
    margin:'1rem 1rem 0rem 1rem',
  },
  settingHeader:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
    '& .setting-name':{
      paddingLeft: '1rem',
      fontSize: '24px'
    },
  },
  settingInputs:{
    marginBottom: '3rem',
    display: 'flex',
    justifyContent: 'center',
    '& .top-panel':{
      padding:'1rem',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  inputRoot: {
    width:'50px',
  },
  unitSelector: {
    width: '80px',
    minWidth: '80px'
  }
});

const units = ['%','px','rem'];

class MaxWidthSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      inputMaxWidth: '100',
      unitType: '%',
      hasChanged: false
    };
  }

  componentDidUpdate(prevProps){
    if(!prevProps.siteMaxWidth && !!this.props.siteMaxWidth)
      this.initialFetchMaxWidth();
  }

  componentDidMount() {
    this.initialFetchMaxWidth();
  }

  initialFetchMaxWidth = () => {
    if(!!this.props.siteMaxWidth){
      const { value, unit } = this.splitSiteMaxWidth(this.props.siteMaxWidth);
      this.setState({
        inputMaxWidth: value,
        unitType: unit,
      });
    }
  }

  splitSiteMaxWidth = (maxWidth) => {
    const arrayMaxWidth = maxWidth.split(/(\d+)/);
    return {value: arrayMaxWidth[1], unit: arrayMaxWidth[2]}
  }

  onChangeInput = (name) => (event) => {
    if(name === 'inputMaxWidth' &&  isNaN(event.target.value)) return;
    this.setState({ [name]: event.target.value, hasChanged: true })
  }

  handleUpdate = () => {
    const { inputMaxWidth, unitType } = this.state;
    this.props.updateGlobalSetting('siteMaxWidth', `${inputMaxWidth.trim()}${unitType}`);
    this.setState({ hasChanged: false })

  }

  render(){
    const { classes, t } = this.props;
    return(
      <React.Fragment>
        <div className={classes.settingHeader}>
          <p className='width-icon'><SettingsOverscan/> </p>
          <p className='setting-name'>{t('labels.max_width')}</p>
        </div>
        <div className={classes.settingInputs}>
          <div className='top-panel'>
            <FormControl className={classes.formControl} error aria-describedby="component-error-text">
              <TextField
                value={this.state.inputMaxWidth}
                onChange={this.onChangeInput('inputMaxWidth')}
                classes={{root: classes.inputRoot}}
              />
            </FormControl>
            <TextField
              select
              value={this.state.unitType}
              helperText="unit"
              variant="outlined"
              onChange={this.onChangeInput('unitType')}
              classes={{root: classes.unitSelector}}
            >
            {units.map((unit, i) => (
               <MenuItem key={i} value={unit}>
                 {unit}
               </MenuItem>
             ))}
            </TextField>
            <TranslatableIconButton
              tPath={"buttons.update"}
              onClick={() => {this.handleUpdate()}}
              Icon={Done}
              buttonProps={{ disabled: (this.state.inputMaxWidth.trim() === '' || !this.state.hasChanged)}}
              divButtonProps={{ style: {display:'inline-block', height: '48px', marginLeft: '1rem'}}}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ globalSettings }) => ({
    siteMaxWidth: globalSettings.siteMaxWidth
})

const mapDispatchToProps = {
  updateGlobalSetting
}

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(translate('Forms')(MaxWidthSettings)));
