import React, { Component } from 'react';
import injectSheet from 'react-jss';
import Link from '@material-ui/icons/Link';
import Done from '@material-ui/icons/Done';
import Close from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import { translate } from 'react-i18next';
import _ from 'lodash';
import TranslatableIconButton from '../TranslatableIconButton';
import TranslatableButton from '../TranslatableButton';
import FullScreenModal from '../FullScreenModal';

const IFRAME_SETTINGS = 'Iframe Settings';

const styles = theme => ({
  IframeSettings: {
    display: 'inline-block',
  },
  iconButton: {
    marginRight: '-0.75rem'
  },
  container: {
    margin: 'auto',
  },
  inputGroup: {
    padding: '1rem 0',
    display: 'flex',
    justifyContent: 'center'
  },
  inputRoot: {
    width: '25em',
    marginRight: '2rem',
  },
  iframeNoteWrapper:{
    padding: '1rem',
  },
  iframeScript:{
    fontFamily: 'courier',
    fontSize: '12px',
    color: '#828282',
  }
});

class IframeSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      currentTab: IFRAME_SETTINGS,
      srcValue: props.value || ''
    };
  }

  openModal = () => this.setState({ open: true })
  closeModal = () => this.setState({ open: false })
  setCurrentTab = (tabId) => () => this.setState({ currentTab: tabId })

  onChange = (url) => {
    this.props.onChange(this.state.srcValue);
    this.closeModal();
  }

  discardChanges = (url) => {
    this.setState({
      srcValue: this.props.value || ''
    });
    this.closeModal();
  }

  onChangeInput = (event) => this.setState({ srcValue: event.target.value })

  render() {
    const { classes, t, note } = this.props;
    const { currentTab, srcValue } = this.state;

    const stringScript = `<script src="https://cdn.jsdelivr.net/npm/iframe-resizer@3.6.4/js/iframeResizer.contentWindow.min.js" integrity="sha256-guWM6me37H/j6pgT6W3HZNC8N1kezYf05xAK4FkfZ8o=" crossorigin="anonymous"></script>`;

    const tabs = {
      [IFRAME_SETTINGS]: (
        <div className={classes.container}>
          <div className={classes.iframeNoteWrapper}>
            <p>{t('labels.iframe_note')}</p>
            <p className={classes.iframeScript}>
              {stringScript}
            </p>
          </div>
          <div className={classes.inputGroup} >
            <TextField
              label="Url"
              onChange={this.onChangeInput}
              classes={{root: classes.inputRoot}}
              value={srcValue}
            />
            <TranslatableButton
              onClick={this.onChange}
              leftIcon={<Done />}
              tPath="buttons.apply_changes"
            />
            <TranslatableButton
              onClick={this.discardChanges}
              leftIcon={<Close />}
              tPath="buttons.discard_changes"
            />
          </div>

        </div>
      ),
    }

    return (
      <div className={classes.IframeSettings}>
        <TranslatableIconButton tPath="buttons.manage_iframe_src" onClick={this.openModal} Icon={Link} buttonProps={{className: classes.iconButton}} />
        {this.state.open && (
          <FullScreenModal
            tabs={tabs}
            currentTab={currentTab}
            closeModal={this.closeModal}
            setCurrentTab={this.setCurrentTab}
          />
        )}
      </div>
    );
  }

}

export default translate('Forms')(injectSheet(styles)(IframeSettings));
