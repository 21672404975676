import { REGISTRATION_TYPES } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'registration_types_3',
  type: REGISTRATION_TYPES,
  name: 'Registration type multicolor',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/registration_types/registration_types_3.jpg',
  rootContent: {
    type: contentTypes.ROW,
    contents: [{
      type: contentTypes.COLUMN,
      properties: {
        medium: {type: 'responsive', value: 12},
        large: {type: 'responsive', value: 12}
      },
      contents: [
        {
          type: contentTypes.REGISTRATION_TYPE_MULTICOLOR,
          properties: {
            showPrice: {type: 'custom', value: true, editable: true},
            showVAT: {type: 'custom', value: true, editable: true},
            showAvailability: {type: 'custom', value: true, editable: true},
            registerButtonLabel: {type: 'custom', value: '', editable: true},
            coloursSelector: {type: 'custom',
                              value: [
                                {r:'94',g:'52',b:'98',a:'1'},
                                {r:'192',g:'65',b:'94',a:'1'},
                                {r:'0',g:'178',b:'166',a:'1'},
                              ],
                              editable: true},
          }
        }
      ]
    }]
  }
}
