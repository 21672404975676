import Forms from '../locales/Forms';
import Misc from 'composer-blocks/lib/locales/Misc.js';

export default {
  en: {
    Forms: { ...Forms.en },
    Misc: { ...Misc.en },
  },
  es: {
    Forms: { ...Forms.es },
    Misc: { ...Misc.es },
  },
  fr: {
    Forms: { ...Forms.fr },
    Misc: { ...Misc.fr },
  },
  de: {
    Forms: { ...Forms.de },
    Misc: { ...Misc.de },
  },
}
