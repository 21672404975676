import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import UploadIcon from '@material-ui/icons/CloudUpload';
import Done from '@material-ui/icons/Done';
import Add from '@material-ui/icons/Add';
import FontDownload from '@material-ui/icons/FontDownload';
import Close from '@material-ui/icons/Close';
import TranslatableButton from '../components/TranslatableButton';
import FontInput from '../components/FontInput';
import { uploadFont, updateFont, UPLOAD_FONT_SUCCESS, UPDATE_FONT_SUCCESS} from '../actions/app';
import { updateGlobalSetting } from "../actions/globalSettings";
import injectSheet from 'react-jss';
import { translate } from 'react-i18next';
import _ from 'lodash';

const styles = theme => ({
  infoFormats:{
    display: 'flex',
    justifyContent: 'center'
  },
  globalFontWrapper:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& > button': {
      marginTop: '1rem',
    },
  },
  globalSelector:{
    marginRight: '1rem',
  },
  hiddenInput: {
    display: 'none'
  },
  selectFontButton: {
    maxWidth: '12rem',
    margin: '1.8rem auto'
  },
  uploadingFonts:{
    marginTop: '2.5rem'
  },
  fontButtonPanel:{
    marginBottom: '1rem',
    padding:'1rem',
    display: 'flex',
    justifyContent: 'center'
  },
  settingHeader:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '3rem',
    '& .font-icon':{
      width: '24px',
      height: '24px',
    },
    '& .setting-name':{
      paddingLeft: '1rem',
      fontSize: '24px'
    },
  },
  settingInputs:{
    '& .top-panel':{
      padding:'1rem',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    '& .subtitle':{
      textAlign: 'center',
      fontSize: '20px',
    },
  }
});

const splitGlobalFont = (globalFont) => {
  if (Array.isArray(globalFont)) return globalFont;
  if (!!globalFont && globalFont !== '0') return globalFont.split('-');
  return [];
};

class FontSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      globalFont: splitGlobalFont(props.globalFont),
      fonts: [],
      newFont: null,
      loadedFonts: false,
      isUploadingFont: false,
      isUpdatingFont: false,
      editedFonts: false,
      uploadError: {EmptyName: false, Invalidformat: false, size: false},
      backendError : null,
      editedGlobalFont: false,
      hasAttachment: false
    };
    this.hiddenFileInput = React.createRef();
  }

  componentDidUpdate(){
    this.initialFetchFonts();
    this.initialFetchGlobalFont();
  }

  componentDidMount() {
    this.initialFetchFonts();
    this.initialFetchGlobalFont();
  }

  //Fetch fotns from redux if them exsts
  initialFetchFonts = () => {
    const { accountFonts } = this.props;
    if(!this.state.loadedFonts && !!accountFonts){
      const newFontsValue = this.cleanFontEntities(accountFonts) || this.state.fonts;
      this.setState({
        loadedFonts: true,
        fonts: newFontsValue
      });
    }
  }

  initialFetchGlobalFont = () => {
    const { globalFont } = this.props;
    if (!!globalFont && globalFont !== '0' && !this.state.globalFont.length && !this.state.editedGlobalFont) {
      this.setState({ globalFont: splitGlobalFont(globalFont) })
    }
  }

  onChangeGlobalFont = (event) => {
    const value = event.target.value;
    const { accountFonts } = this.props;
    const validatedValue = value.filter((fontId) => !!accountFonts[fontId]);
    this.setState({
      globalFont: validatedValue,
      editedGlobalFont: true,
    });
  }

  onChangeFont = (index, key) => (event) => {
    const value = event.target.value;
    this.setState(oldState => {
      const newValue = _.cloneDeep(oldState.fonts);
      newValue[index][key] = value;
      return { fonts: newValue, editedFonts: true };
    });
  }

  onChangeNewFont = (key) => (event) => {
    const value = event.target.value;
    this.setState(oldState => {
      const newValue = _.cloneDeep(oldState.newFont);
      newValue[key] = value;
      return { newFont: newValue };
    });
  }

  addError = (type) => {
    this.setState(prevState => {
      const newValue = _.cloneDeep(prevState.uploadError);
      newValue[type] = true;
      return { uploadError: newValue };
    });
  }

  removeErrors = (types) => {
    this.setState(prevState => {
      const newValue = _.cloneDeep(prevState.uploadError);
      types.forEach((type) => { newValue[type] = false })
      return { uploadError: newValue };
    });
  }

  //when select font
  onChangeFileUploader = () => {
    const file = this.hiddenFileInput.current.files[0];
    if (!!file){
      this.removeErrors(['Invalidformat','size'])
       if(file.name.split('.').pop() !== 'ttf' && file.name.split('.').pop() !== 'woff'){
         this.addError('Invalidformat');
         return;
       }
      if(file.size && file.size > 920000){
         this.addError('size');
         return;
       }
       this.setState({
         hasAttachment: true,
       });
    }
  }

  // convert font entities (object) into array for handle it in the state
  cleanFontEntities = (accountFonts) => {
    if(Object.keys(accountFonts).length === 0)
      return null;
    return Object.values(accountFonts)
  }


  triggerFileInput = () => {
    this.hiddenFileInput.current.click();
  }

  addFont = () => {
    this.setState({
      newFont: { name: '', weight: 'normal', style: 'normal', fallback: 'sans-serif', source: null}
    });
  }

  //Upload selected font to account_fonts
  handleUploadFont = () => {

    const { files } = this.hiddenFileInput.current;
    const { newFont, uploadError } = this.state;
    const { uploadFont } = this.props;
    const { name, weight, style, fallback } = newFont;

     if(name === ''){ this.addError('EmptyName'); return}
     if(!uploadError.size && !uploadError.Invalidformat){
       this.removeErrors(['EmptyName']);
       this.setState({ isUploadingFont: true })
       if (!!files && !!files[0]) {
         uploadFont(name, weight, style, fallback, files[0]).then(action => {
           if (action.type === UPLOAD_FONT_SUCCESS) {
             this.setState({
               isUploadingFont: false,
               newFont: null,
               loadedFonts: false,
               hasAttachment: false,
               backendError : null
             })
           }
           else {
             this.setState({ backendError: action.error });
           }
         });
       }
       else {
         this.addError('Invalidformat');
       }
     }
  }

  //update font to account_fonts
  handleUpdateFont = (index) => {
    const { fonts } = this.state;
    const currentFont = fonts[index];
    const { updateFont  } = this.props;
    const { id, name, weight, style, fallback } = currentFont;
    this.setState({ isUpdatingFont: true })
    updateFont(id, name, weight, style, fallback).then(action => {
      if (action.type === UPDATE_FONT_SUCCESS) {
        this.setState({
          isUpdatingFont: false,
          editedFonts: false
        })
      }
    });
  }

  discardChanges = () => {
    this.setState({
      fonts: [],
      loadedFonts: false,
      newFont: null,
      hasAttachment: false
    });
  }

  // Two or more fonts with same name form a family
  getFontFamilies = (accountFonts) => {
    const distinctFonts = {};
    if (!!accountFonts) Object.values(accountFonts).forEach((font) => {
      if (!distinctFonts[font.name]) distinctFonts[font.name] = [font.id];
      else distinctFonts[font.name].push(font.id);
    });
    return distinctFonts;
  }

  handleUpdateGlobalFont = () => {
    const { globalFont } = this.state;
    this.props.updateGlobalSetting('globalFontId', globalFont.join('-'));
  }

  render(){
    const { classes, t, accountFonts } = this.props;
    const { fonts, loadedFonts, globalFont, hasAttachment } = this.state;
    const fontFamilies = this.getFontFamilies(accountFonts);
    return(
      <React.Fragment>
        <div className={classes.settingHeader}>
          <p className='font-icon'><FontDownload/> </p>
          <p className='setting-name'>{t('labels.fonts')}</p>
        </div>
        <div className={classes.settingInputs}>
          <div>

          </div>
          <div className={classes.globalFontWrapper}>
            {!!loadedFonts && (
              <TextField
                select
                SelectProps={{ multiple: true }}
                value={globalFont}
                label={t('labels.site_fonts')}
                helperText={t('info.first_font_is_global')}
                variant="outlined"
                onChange={this.onChangeGlobalFont}
                className={classes.globalSelector}
              >
                {Object.keys(fontFamilies).map((fontFamilyName) => (
                   <MenuItem
                      key={fontFamilyName}
                      value={fontFamilies[fontFamilyName][0]}
                    >
                     {fontFamilyName}
                   </MenuItem>
                 ))}
              </TextField>
            )}
            {!!globalFont && !!globalFont.length && accountFonts[globalFont[0]] && (
              <p className='current-font'>
                {t('info.default_font_is', { font: accountFonts[globalFont[0]].name })}
              </p>
            )}
            <TranslatableButton
              onClick={this.handleUpdateGlobalFont}
              leftIcon={<Done />}
              tPath="buttons.update_global_font"
              disabled={!this.state.editedGlobalFont}
            />
          </div>
          {(!!this.state.loadedFonts || !!this.state.newFont) && (
              <div className='top-panel'>
                <p className='subtitle'>{t('labels.fonts_in_account')}</p>
                {fonts.map((font, index) => (
                  <FontInput
                    font={font}
                    key={font.id}
                    index={index}
                    onChange={(key) => this.onChangeFont(index,key)}
                    onUpdate={(index) => this.handleUpdateFont(index)}
                    isUpdatingFont={this.state.isUpdatingFont}
                    editable
                  />
                ))}
                {!!this.state.newFont && (
                  <React.Fragment>
                    <FontInput
                      font={this.state.newFont}
                      onChange={(key) => this.onChangeNewFont(key)}
                      errors = {this.state.uploadError}
                      backendError = {this.state.backendError}
                    />
                    <input type="file" accept=".ttf,.woff" className={classes.hiddenInput} ref={this.hiddenFileInput} onChange={this.onChangeFileUploader} />
                    <TranslatableButton
                      leftIcon={<UploadIcon />}
                      tPath={hasAttachment ? 'buttons.change_font' : 'buttons.select_font'}
                      buttonProps={{ className: classes.selectFontButton}}
                      onClick={this.triggerFileInput}
                    />
                    <div className={classes.infoFormats}><p>{t('info.valid_font_formats')}</p> </div>
                  </React.Fragment>
                )}
              </div>
            )}

          <div className={classes.fontButtonPanel} style={{marginTop: this.state.isUploadingFont ? '-0.78rem' : '1rem'}}>
            <TranslatableButton
              onClick={this.addFont}
              leftIcon={<Add />}
              tPath="buttons.add_font"
              disabled={!!this.state.newFont}
            />
            <TranslatableButton
              onClick={this.handleUploadFont}
              leftIcon={<Done />}
              tPath="buttons.apply_changes"
              disabled={!this.state.newFont || this.state.editedFonts}
            />
            <TranslatableButton
              onClick={this.discardChanges}
              leftIcon={<Close />}
              tPath="buttons.discard_changes"
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ app, site, globalSettings }) => ({
    pendingChanges: app.saveChanges.active,
    uploadStatus: app.uploadFont,
    updateStatus: app.updateFont,
    accountFonts: site.entities.account_fonts,
    globalFont: globalSettings.globalFontId,
  })

const mapDispatchToProps = {
  uploadFont,
  updateFont,
  updateGlobalSetting,
}

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(translate('Forms')(FontSettings)));
