import { INSERT_PAGE, DELETE_PAGE, SET_PAGE_NAME, SET_PAGE_FRIENDLY_URL, SET_PAGE_HIDE_NAVBAR, SET_PAGE_HIDE_FROM_NAVBAR, SET_PAGE_HIDE_FOOTER, SET_PAGE_HIDE_FROM_FOOTER} from "../actions/pages";
import { INSERT_BLOCK, MOVE_BLOCK, DELETE_BLOCK } from "../actions/blocks";
import { COMPOSER_SETTINGS_SUCCESS } from "composer-blocks/lib/actions/site";
import { deleteWhere } from 'composer-blocks/lib/helpers/hasMany';
import { getEntityFromResponse } from 'composer-blocks/lib/helpers/api';

const initialState = {
};

const generatePageName = (pages) => {
  const baseName = 'Page';
  const existing_indexes = Object.keys(pages).map(k => pages[k]).map((page) => {
    const { name } = page;

    if (name.indexOf(baseName) >= 0) {
      return parseInt(name.split(baseName)[1], 10);
    }
    return 0;
  });
  let page_index = 1;

  while (existing_indexes.length > 0 && existing_indexes.indexOf(page_index) >= 0) {
    page_index++;
  }

  return `${baseName} ${page_index}`
};

const pages = (state = initialState, action) => {
  switch (action.type) {
    case INSERT_PAGE: {
      const { id, name, hideNavbar = false, hideFromNavbar = false, hideFooter = false, hideFromFooter = false} = action;

      return {
        ...state,
        [id]: {
          id,
          hideNavbar,
          hideFromNavbar,
          hideFooter,
          hideFromFooter,
          name: name || generatePageName(state),
          blocks: [],
        }
      }
    }

    case DELETE_PAGE: {
      const { id } = action;

      return deleteWhere(state, 'id', id);
    }

    case SET_PAGE_NAME: {
      const { id, name } = action;

      return {
        ...state,
        [id]: {
          ...state[id],
          name
        }
      }
    }

    case SET_PAGE_FRIENDLY_URL: {
      const { id, friendlyUrl } = action;

      return {
        ...state,
        [id]: {
          ...state[id],
          friendlyUrl
        }
      }
    }

    case SET_PAGE_HIDE_NAVBAR: {
      const { id, hideNavbar } = action;

      return {
        ...state,
        [id]: {
          ...state[id],
          hideNavbar
        }
      }
    }

    case SET_PAGE_HIDE_FROM_NAVBAR: {
      const { id, hideFromNavbar } = action;

      return {
        ...state,
        [id]: {
          ...state[id],
          hideFromNavbar
        }
      }
    }

    case SET_PAGE_HIDE_FOOTER: {
      const { id, hideFooter } = action;

      return {
        ...state,
        [id]: {
          ...state[id],
          hideFooter
        }
      }
    }

    case SET_PAGE_HIDE_FROM_FOOTER: {
      const { id, hideFromFooter } = action;

      return {
        ...state,
        [id]: {
          ...state[id],
          hideFromFooter
        }
      }
    }

    case INSERT_BLOCK: {
      const { index, block } = action;
      const { id, page } = block;
      let newBlockList = state[page].blocks ? state[page].blocks.slice() : [];

      newBlockList.splice(index, 0, id);

      return {
        ...state,
        [page]: {
          ...state[page],
          blocks: newBlockList
        },
      };
    }

    case MOVE_BLOCK: {
      const { page, newIndex, id } = action;
      let newBlockList = state[page].blocks.slice();
      const oldIndex = newBlockList.indexOf(id);

      if (oldIndex === newIndex || oldIndex === -1) return state;

      newBlockList.splice(oldIndex, 1);
      newBlockList.splice(newIndex, 0, id);

      return {
        ...state,
        [page]: {
          ...state[page],
          blocks: newBlockList
        },
      };
    }

    case DELETE_BLOCK: {
      const { id, page } = action;
      let newBlockList = state[page].blocks.slice();
      const oldIndex = newBlockList.indexOf(id);

      newBlockList.splice(oldIndex, 1);

      return {
        ...state,
        [page]: {
          ...state[page],
          blocks: newBlockList
        },
      };
    }

    case COMPOSER_SETTINGS_SUCCESS: {
      return getEntityFromResponse(action, 'pages') || state;
    }

    default:
      return state;
  }
};

export default pages;
