import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import ColorSettings from '../components/ColorSettings';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CloseIcon from '@material-ui/icons/Close';
import Done from '@material-ui/icons/Done';
import TranslatableIconButton from '../components/TranslatableIconButton';
import { updateGlobalSetting } from "../actions/globalSettings";
import injectSheet from 'react-jss';
import { translate } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import { isNullOrUndefined } from '../helpers/misc'
import FileUploader from './FileUploader';

const styles = theme => ({
  ageGateWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '6rem',
    '& > div': {
      marginBottom: '1rem',
    },
    '& .min-age': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .description': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .color-settings': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      '& .color-row': {
        width: 600,
        margin: '1rem 0',
        display: 'flex',
      },
    },
    '& .logo-wrapper': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .error-msg': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  logoPreview: {
    margin: [0, theme.spacing.unit * 2],
    maxWidth: 120
  },
  logoUploader: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 5,
    padding: theme.spacing.unit * 2,
    margin: [0, theme.spacing.unit * 2],
  },
  minAgeSelector: {
    width: 180,
    marginLeft:32,
  },
  inputMessageLabel: {
    width: 150,
    textAlign: 'right',
  },
  inputMessage: {
    width: 568,
    marginLeft:32,
  },
  colorCircleLabel: {
    width: 300,
    textAlign: 'right',
    marginRight: 16,
  },
});


class AgeGateSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      backdropColor: props.backdropColor || {r: 59, g: 47, b: 60, a: 0.6},
      popupColor: props.popupColor || {r: 255, g: 255, b: 255, a: 1},
      buttonColor: props.buttonColor || {r: 63, g: 211, b: 185, a: 1},
      hasChanged: false,
      enabled: props.enabled || false,
      minAge: props.minAge || 18,
      errorMsg: props.errorMsg || '',
      description: props.description || '',
      logo: props.logo || '',
    };
  }

  componentDidUpdate(prevProps){
     if(isNullOrUndefined(prevProps.enabled, true) && !isNullOrUndefined(this.props.enabled, true)){
       const { enabled, backdropColor, popupColor,  buttonColor, minAge, errorMsg, description, logo } = this.props;
       this.setState({
         enabled, backdropColor, popupColor,  buttonColor, minAge, errorMsg, description, logo
       });
     }
   }

   componentDidMount() {
     if(!isNullOrUndefined(this.props.enabled, true)){
       const { enabled, backdropColor, popupColor,  buttonColor, minAge, errorMsg, description, logo } = this.props;
       this.setState({
         enabled, backdropColor, popupColor,  buttonColor, minAge, errorMsg, description, logo
       });
     }
   }

  onChangeCheckboxState = (event) => this.setState({ enabled: event.target.checked, hasChanged: true })

  onChangeTextInput = (name) => (event) => this.setState({ [name]: event.target.value, hasChanged: true })

  changeColorState = (color) => (event) => this.setState({ [color]: event, hasChanged: true })

  onChangeLogo = (urls) => this.setState({ logo: urls.original, hasChanged: true })

  handleUpdate = () => {
    const { hasChanged, ...ageGate } = this.state;
    this.props.updateGlobalSetting('ageGate', ageGate );
    this.setState({ hasChanged: false })
  }

  render(){
    const { classes, t } = this.props;
    const ageLowerBound = 14;
    return(
      <div className={classes.ageGateWrapper}>
        <div className='age-gate-checker'>
          <FormGroup row >
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.enabled}
                  onChange={this.onChangeCheckboxState}
                />
              }
              label={t('labels.enable_age_gate')}
            />
            <TranslatableIconButton
              tPath={"buttons.update"}
              onClick={this.handleUpdate}
              Icon={Done}
              buttonProps={{ disabled: (!this.state.hasChanged)}}
              divButtonProps={{ style: {display:'inline-block', height: '48px', marginLeft: '1rem'}}}
            />
          </FormGroup>
        </div>
        {this.state.enabled && (
          <React.Fragment>
            <div className="min-age">
              <Typography>{t('labels.minimum_age')}</Typography>
              <TextField
                select
                value={this.state.minAge}
                variant="outlined"
                margin="dense"
                onChange={this.onChangeTextInput('minAge')}
                classes={{root: classes.minAgeSelector}}
                >
                  {[...Array(9).keys()].map((age) => (
                    <MenuItem key={age} value={age + ageLowerBound}>
                      {age + ageLowerBound}
                    </MenuItem>
                  ))}
                </TextField>
            </div>
            <div className="description">
              <Typography className={classes.inputMessageLabel}>{t('labels.description')}</Typography>
              <TextField
                fullWidth
                value={this.state.description}
                placeholder={t('placeholder.ageGateDescription')}
                onChange={this.onChangeTextInput('description')}
                classes={{root: classes.inputMessage}}
              />
            </div>
            <div className="color-settings">
              <div className="color-row">
                <Typography className={classes.colorCircleLabel}>
                  {t('labels.backdrop_color')}
                </Typography>
                <ColorSettings
                  value={this.state.backdropColor}
                  onChange={this.changeColorState('backdropColor')}
                />
              </div>
              <div className="color-row">
                <Typography className={classes.colorCircleLabel}>
                  {t('labels.popup_color')}
                </Typography>
                <ColorSettings
                  value={this.state.popupColor}
                  onChange={this.changeColorState('popupColor')}
                />
              </div>
              <div className="color-row">
                <Typography className={classes.colorCircleLabel}>
                  {t('labels.button_color')}
                </Typography>
                <ColorSettings
                  value={this.state.buttonColor}
                  onChange={this.changeColorState('buttonColor')}
                />
              </div>
            </div>
            <div className="logo-wrapper">
              <Typography className={classes.inputMessageLabel}>Logo</Typography>
              {this.state.logo ? (
                <React.Fragment>
                  <img className={classes.logoPreview} src={this.state.logo} />
                  <TranslatableIconButton
                    text="Remove"
                    onClick={() => this.onChangeLogo('')}
                    Icon={CloseIcon}
                  />
                </React.Fragment>
              ) : (
                <FileUploader onSelect={this.onChangeLogo} className={classes.logoUploader} />
              )}
            </div>
            <div className="error-msg">
              <Typography className={classes.inputMessageLabel}>{t('labels.error_msg')}</Typography>
              <TextField
                fullWidth
                value={this.state.errorMsg}
                placeholder={t('placeholder.ageGateErrorMsg')}
                onChange={this.onChangeTextInput('errorMsg')}
                classes={{root: classes.inputMessage}}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}

const mapStateToProps = ({ globalSettings: { ageGate = {} } }) => ({
  backdropColor: ageGate.backdropColor,
  popupColor: ageGate.popupColor,
  buttonColor: ageGate.buttonColor,
  enabled: ageGate.enabled,
  minAge: ageGate.minAge,
  description: ageGate.description,
  errorMsg: ageGate.errorMsg,
  logo: ageGate.logo,
})

const mapDispatchToProps = {
  updateGlobalSetting
}

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(translate('Forms')(AgeGateSettings)));
