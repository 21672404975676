import React, { Component } from 'react';
import MuiCheckbox from '@material-ui/core/Checkbox';
import injectSheet from 'react-jss';

const styles = {
  root: {
    width: 'auto',
    height: 'auto',
    padding: 0,
  }
}

class Checkbox extends Component {
  onChange = (event) => {
    const { checked } = event.target;
    this.props.onChange(checked);
  }

  render() {
    const { value, classes } = this.props;

    return (
      <MuiCheckbox
        checked={value}
        onChange={this.onChange}
        classes={{
          root: classes.root
        }}
      />
    );
  }

}

export default injectSheet(styles)(Checkbox);
