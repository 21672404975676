import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import BackIcon from '@material-ui/icons/ArrowBack';
import SaveButton from '../containers/SaveButton';
import PublishButton from '../containers/PublishButton';
import injectSheet from 'react-jss';
import TranslatableButton from '../components/TranslatableButton';
import { fetchComposerSettingsIfNeeded } from "../actions/app";
import { uploadFile } from '../actions/app';
import { translate } from 'react-i18next';
import FaviconSettings from '../containers/FaviconSettings';
import IndexingSettings from '../containers/IndexingSettings';
import FontSettings from '../containers/FontSettings';
import MaxWidthSettings from '../containers/MaxWidthSettings';
import SecuritySettings from '../components/SecuritySettings';
import Divider from '@material-ui/core/Divider';


const styles = theme => ({
  Toolbar: {
    minHeight: '48px'
  },
  GlobalSettings:{
    fontSize: '0.875rem',
    color:'white',
  },
  iconButton: {
    marginTop: '1.7rem',
  },
  iconButtonUpload: {
    marginBottom: '2rem',
  },
  uploadingImage:{
    marginTop: '2.5rem'
  },
  innerContainer: {
    display:'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: '3rem',
    bottom: '0',
    width: '100%',
    background: theme.palette.primary.dark,
    padding: '1rem 0',
    overflow: 'auto',
    flex: '1'
  },
});

const AppBarWithBackButton = injectSheet(styles)(({ classes, match }) => (
  <AppBar position="static">
    <Toolbar className={classes.Toolbar}>
      <TranslatableButton
        buttonProps={{
          component: Link,
          to: `/${match.params.locale}`
        }}
        leftIcon={<BackIcon />}
        tPath='buttons.back_to_composer'
      />
      <Tooltip title="Save All Changes">
        <span className={classes.saveButton}>
          <div>
            <SaveButton />
          </div>
        </span>
      </Tooltip>
      <Tooltip title="Publish">
        <span className={classes.saveButton}>
          <PublishButton />
        </span>
      </Tooltip>
    </Toolbar>
  </AppBar>
));

class GlobalSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      description: '',
      uploadError: [],
    };
  }

  componentWillMount() {
    const { fetchComposerSettingsIfNeeded, match } = this.props;
    fetchComposerSettingsIfNeeded(match.params.page);
  }

  onUnload = (event) => {
    if (this.props.pendingChanges)
      event.returnValue = "You have unsaved changes"
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload)
  }

  render() {
    const { classes} = this.props;
    return (
      <div className={classes.GlobalSettings}>
        <AppBarWithBackButton match={this.props.match} />
          <div className={classes.innerContainer}>
            <div className={classes.container}>
              <SecuritySettings/>
              <Divider />
              <FaviconSettings/>
              <Divider />
              <IndexingSettings/>
              <Divider />
              <FontSettings/>
              <Divider />
              <MaxWidthSettings/>
            </div>
          </div>
      </div>
    );
  }
}

const mapStateToProps = ({ app, globalSettings }) => ({
    pendingChanges: app.saveChanges.active,
    uploadStatus: app.uploadFile,
    faviconUrl: globalSettings.faviconUrl
  })

const mapDispatchToProps = {
  fetchComposerSettingsIfNeeded,
  uploadFile
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(translate('Forms')(GlobalSettings))));
