import { CUSTOM } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'custom_1',
  type: CUSTOM,
  name: 'Custom with tiles',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/custom/custom_1.png',
  rootContent: {
    type: contentTypes.ROW,
    properties: {
      backgroundColor: {type: 'css', value: '#F9F9F9', editable: true},
      color: {type: 'css', value: ''},
      padding: {type: 'css', value: '0'}
    },
    contents: [
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 3},
          padding: {type: 'css', value: 0},
          margin: {type: 'css', value: 0},
          minHeight: {type: 'css', value: '20rem'},
          backgroundImage: {type: 'css', value: 'url(https://eventtia-composer.s3.amazonaws.com/assets/images/medium/landscape.png)', editable:true},
          backgroundRepeat: {type: 'css', value: 'no-repeat'},
          backgroundSize: {type: 'css', value:'cover'},
          offsetLarge: {type: 'responsive', value: 0, editable:false},
          offsetMedium: {type: 'responsive', value: 0, editable:false},
          offsetSmall: {type: 'responsive', value: 0, editable:false}
        }
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 3},
          minHeight: {type: 'css', value: '20rem'},
          offsetLarge: {type: 'responsive', value: 0, editable:false},
          offsetMedium: {type: 'responsive', value: 0, editable:false},
          offsetSmall: {type: 'responsive', value: 0, editable:false}
        },
        contents: [
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy',
            properties: {
              margin: {type: 'css', value: '1rem'},
              color: {type: 'css', value: '#777777', editable: true},
              fontSize: {type:'css', value: '18px'},
              fontWeight: {type: 'css', value: 'bold', editable:false}
            },
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            properties: {
              margin: {type: 'css', value: '0 1rem'},
              color: {type: 'css', value: '#9F9D9D', editable: true},
              letterSpacing: {type: 'css', value: '1px'},
              fontSize: {type:'css', value: '14px'},
              fontWeight: {type: 'css', value: 'normal', editable:false}
            },
          }
        ]
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 3},
          padding: {type: 'css', value: 0},
          margin: {type: 'css', value: 0},
          minHeight: {type: 'css', value: '20rem'},
          backgroundImage: {type: 'css', value: 'url(https://eventtia-composer.s3.amazonaws.com/assets/images/medium/couple.png)', editable:true},
          backgroundRepeat: {type: 'css', value: 'no-repeat'},
          backgroundSize: {type: 'css', value:'cover'},
          offsetLarge: {type: 'responsive', value: 0, editable:false},
          offsetMedium: {type: 'responsive', value: 0, editable:false},
          offsetSmall: {type: 'responsive', value: 0, editable:false}
        }
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 3},
          minHeight: {type: 'css', value: '20rem'},
          offsetLarge: {type: 'responsive', value: 0, editable:false},
          offsetMedium: {type: 'responsive', value: 0, editable:false},
          offsetSmall: {type: 'responsive', value: 0, editable:false}
        },
        contents: [
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy',
            properties: {
              margin: {type: 'css', value: '1rem'},
              color: {type: 'css', value: '#777777', editable: true},
              fontWeight: {type: 'css', value: 'bold',editable:false},
              fontSize: {type:'css', value: '18px'}},
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            properties: {
              margin: {type: 'css', value: '0 1rem'},
              color: {type: 'css', value: '#9F9D9D', editable: true},
              letterSpacing: {type: 'css', value: '1px'},
              fontSize: {type:'css', value: '14px'},
              fontWeight: {type: 'css', value: 'normal', editable:false}
            },
          }
        ]
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 3},
          minHeight: {type: 'css', value: '20rem'},
          offsetLarge: {type: 'responsive', value: 0, editable:false},
          offsetMedium: {type: 'responsive', value: 0, editable:false},
          offsetSmall: {type: 'responsive', value: 0, editable:false}
        },
        contents: [
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy',
            properties: {
              margin: {type: 'css', value: '15% 1rem 1rem 1rem'},
              color: {type: 'css', value: '#777777', editable: true},
              fontWeight: {type: 'css', value: 'bold',editable:false},
              textAlign: {type: 'css', value: 'right'},
              fontSize: {type:'css', value: '18px'}},
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            properties: {
              margin: {type: 'css', value: '0 1rem'},
              color: {type: 'css', value: '#9F9D9D', editable: true},
              letterSpacing: {type: 'css', value: '1px'},
              fontSize: {type:'css', value: '14px'},
              textAlign: {type: 'css', value: 'right'},
              fontWeight: {type: 'css', value: 'normal', editable:false}
            },
          }
        ]
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 3},
          padding: {type: 'css', value: 0},
          margin: {type: 'css', value: 0},
          minHeight: {type: 'css', value: '20rem'},
          backgroundImage: {type: 'css', value: 'url(https://eventtia-composer.s3.amazonaws.com/assets/images/medium/car.png)', editable:true},
          backgroundRepeat: {type: 'css', value: 'no-repeat'},
          backgroundSize: {type: 'css', value:'cover'},
          offsetLarge: {type: 'responsive', value: 0, editable:false},
          offsetMedium: {type: 'responsive', value: 0, editable:false},
          offsetSmall: {type: 'responsive', value: 0, editable:false}

        }
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 3},
          minHeight: {type: 'css', value: '20rem'},
          offsetLarge: {type: 'responsive', value: 0, editable:false},
          offsetMedium: {type: 'responsive', value: 0, editable:false},
          offsetSmall: {type: 'responsive', value: 0, editable:false}
        },
        contents: [
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy',
            properties: {
              margin: {type: 'css', value: '15% 1rem 1rem 1rem'},
              color: {type: 'css', value: '#777777', editable: true},
              fontWeight: {type: 'css', value: 'bold',editable:false},
              textAlign: {type: 'css', value: 'right'},
              fontSize: {type:'css', value: '18px'}},
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            properties: {
              margin: {type: 'css', value: '0 1rem'},
              color: {type: 'css', value: '#9F9D9D', editable: true},
              letterSpacing: {type: 'css', value: '1px'},
              fontSize: {type:'css', value: '14px'},
              textAlign: {type: 'css', value: 'right'},
              fontWeight: {type: 'css', value: 'normal', editable:false}
            },
          }
        ]
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 3},
          padding: {type: 'css', value: 0},
          margin: {type: 'css', value: 0},
          minHeight: {type: 'css', value: '20rem'},
          backgroundImage: {type: 'css', value: 'url(https://eventtia-composer.s3.amazonaws.com/assets/images/medium/autumn.png)', editable:true},
          backgroundRepeat: {type: 'css', value: 'no-repeat'},
          backgroundSize: {type: 'css', value:'cover'},
          offsetLarge: {type: 'responsive', value: 0, editable:false},
          offsetMedium: {type: 'responsive', value: 0, editable:false},
          offsetSmall: {type: 'responsive', value: 0, editable:false}
        }
      }
    ]
  }
}
