import { CUSTOM } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'custom_3',
  type: CUSTOM,
  name: 'Custom with background image',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/custom/custom_3.png',
  rootContent: {
    type: contentTypes.ROW,
    properties: {
      backgroundImage: {type: 'css', value: 'url(https://eventtia-composer.s3.amazonaws.com/assets/images/large/woman_computer.png)', editable:true},
      backgroundRepeat: {type: 'css', value: 'no-repeat'},
      backgroundSize: {type: 'css', value:'cover'},
      color: {type: 'css', value: ''},
      fontWeight: {type: 'css', value: 'bold'},
      padding: {type: 'css', value: '0'},
      display: {type: 'css', value: 'flex'},
      justifyContent: {type: 'css', value: 'flex-start'}
    },
    contents: [
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 4},
          offsetLarge: {type: 'responsive', value: 5, editable: true},
          offsetMedium: {type: 'responsive', value: 5, editable: true},
          medium: {type: 'responsive', value: 6},
          small: {type: 'responsive', value: 12},
          backgroundColor: {type: 'css', value: 'white'},
          marginTop: {type:'css', value: '10rem'},
          marginBottom: {type:'css', value: '5rem'},
        },
        contents: [
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy',
            properties: {
              width: {type:'css', value: '50%'},
              margin: {type: 'css', value: '0 1rem 1rem 1rem'},
              color: {type: 'css', value: '#F75558', editable: true},
              fontWeight: {type: 'css', value: 'bold'},
              padding: {type: 'css', value: '0 2rem 1rem 0'},
              fontSize: {type: 'css', value: '28px'},
            }

          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            properties: {
              margin: {type: 'css', value: '1rem'},
              color: {type: 'css', value: '#777777', editable: true},
              fontWeight: {type: 'css', value: 'normal'},
              padding: {type: 'css', value: '0 2rem 1rem 0'},

            }

          },
          {
            type: contentTypes.LINK,
            properties: {
              color: {type: 'css', value: '#0095D9'},
              href: {type: 'custom', value: 'javascript:alert()', editable: true}
            },
            contents: [
              {
                type: contentTypes.TEXT,
                value: 'READ MORE...',
                properties: {
                  margin: {type: 'css', value: '1rem'},
                  color: {type: 'css', value: '#F75558', editable: true},
                  fontWeight: {type: 'css', value: 'bold'},
                  padding: {type: 'css', value: 0},
                  fontSize: {type: 'css', value: '20px'},
                  textAlign: {type: 'css', value: 'right'}
                }

              }
            ]
          }

        ]
      }
    ]
  }
}
