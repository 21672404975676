import React, { Component } from 'react';
import TranslatableIconButton from '../components/TranslatableIconButton';
import FontPreview from './FontPreview';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Edit from '@material-ui/icons/Edit';
import Done from '@material-ui/icons/Done';
import { translate } from 'react-i18next';
import injectSheet from 'react-jss';

const styles = {
  fontPreviewWrapper:{
    minWidth: '10rem',
  },
  fullWidth:{
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '3rem',
  },
  editButtonsWrapper:{
    minWidth: '8rem'
  },
  formControl: {
    maxWidth: '10rem'
  }
}

class FontInput extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
    };
  }

  getOptionStyle = (input, value) => {
    switch (input) {
      case 'Fallback':
        return {fontFamily: value}
      case 'Style':
        return {fontStyle: value}
      case 'Weight':
        return {fontWeight: value}
      default:
        return {}
    }
  }

  toggleEdition = () => {
    this.setState({
      editMode: !this.state.editMode,
    });
  }

  handleUpdate = () => {
    const { onUpdate, index } = this.props;
    onUpdate(index);
    this.toggleEdition();
  }
  renderEditButtons = () => {
    const { classes, font, isUpdatingFont } = this.props;
    const { name } = font;
    return (
      <React.Fragment>
        <TranslatableIconButton
          tPath={"buttons.edit"}
          onClick={this.toggleEdition}
          Icon={Edit}
          buttonProps={{ className: classes.iconButton, disabled: (isUpdatingFont)}}
          divButtonProps={{ style: {display:'inline-block', height:'48px'}}}
        />
        <TranslatableIconButton
          tPath={"buttons.update"}
          onClick={() => {this.handleUpdate()}}
          Icon={Done}
          buttonProps={{ className: classes.iconButton, disabled: (name === '' || !this.state.editMode || isUpdatingFont)}}
          divButtonProps={{ style: {display:'inline-block', height: '48px'}}}
        />

      </React.Fragment>
    )
  }

  getCurrentErrorMsg = () => {
    const { t, errors, backendError } = this.props;
    if(!errors) return [];
    const errorMsg = { EmptyName:  t('errors.empty_name'), Invalidformat: t('errors.not_font_format'), size: t('errors.exceeded_limit')}
    let currentErrors = [];
    const errorsKeys = Object.keys(errors);
    for( let i = 0; i < errorsKeys.length; i++){
      if(errors[errorsKeys[i]]) currentErrors.push(errorMsg[errorsKeys[i]])
    }
    if(!!backendError) currentErrors.push(backendError)
    return currentErrors;
  }

  render() {
    const { t, classes, font, editable, onChange } = this.props;
    const { name, source } = font;
    const fields = ['Weight','Style', 'Fallback'];
    const options = {
      weight: ['Bold','Normal'],
      style: ['Italic','Normal'],
      fallback: ['monospace','sans-serif','serif']
    }
    const currentErrors = this.getCurrentErrorMsg();
    return (
      <div className={classes.fullWidth}>
        <div className={classes.fontPreviewWrapper}>
          {!!source && (<FontPreview font={font}/>)}
        </div>
        <FormControl className={classes.formControl} error aria-describedby="component-error-text">
          <TextField
            label={t('labels.name')}
            onChange={onChange('name')}
            classes={{root: classes.inputRoot}}
            value={name}
            disabled={!this.state.editMode && editable}
          />
          {currentErrors.length > 0 && currentErrors.map(errorMessage => {
            return(
              <FormHelperText key={errorMessage}>{errorMessage}</FormHelperText>
            )
          })
          }
        </FormControl>

        {fields.map((field,i) => (
          <TextField
            key={i}
            select
            value={font[field.toLowerCase()]}
            helperText={field}
            variant="outlined"
            onChange={onChange(field.toLowerCase())}
            className={classes.selector}
            disabled={!this.state.editMode && editable}
          >
          {options[field.toLowerCase()].map((option, j) => (
             <MenuItem
                key={j}
                value={option.toLowerCase()}
                style={this.getOptionStyle(field, option)}>
               {option}
             </MenuItem>
           ))}
          </TextField>
        ))}
        <div className={classes.editButtonsWrapper}>
          {!!editable && this.renderEditButtons()}
        </div>
      </div>
    );
  }

}

export default injectSheet(styles)(translate('Forms')(FontInput));
