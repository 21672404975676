import { CUSTOM } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'custom_12',
  type: CUSTOM,
  name: 'Custom with cellphone',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/custom/custom_12.png',
  rootContent: {
    type: contentTypes.ROW,
    properties: {
      padding: {type: 'css', value: '0'}
    },
    contents: [


      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 5},
          medium: {type: 'responsive', value: 12},
          small: {type: 'responsive', value: 12},
          offsetLarge: {type: 'responsive', value: 1}
        },
        contents: [
          {
            type: contentTypes.ROW,
            properties: {

            },
            contents: [
              {
                type: contentTypes.COLUMN,
                properties: {
                  large: {type: 'responsive', value: 6},
                  medium: {type: 'responsive', value: 6},
                  small: {type: 'responsive', value: 12},
                  margin: {type:'css', value: 0},
                  padding: {type:'css', value: '1rem'}
                },
                contents: [
                  {
                    type: contentTypes.IMAGE,
                    properties: {
                      src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/icons/icon_image.png', editable:true},
                      objectFit: {type: 'css', value: 'contain'},
                      width: {type: 'css', value:'3rem'},
                      height: {type: 'css', value:'3rem'},
                      margin: {type: 'css', value: '0 1rem 1rem 1rem'},
                    },
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem Ipsum',
                    properties: {
                      margin: {type: 'css', value: '0 1rem 0 1rem'},
                      color: {type: 'css', value: '#E53F21', editable: true},
                      fontWeight: {type: 'css', value: 'bold'},
                      padding: {type: 'css', value: '0 2rem 1rem 0'},
                      fontSize: {type: 'css', value: '24px'}
                    }
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                    properties: {
                      margin: {type: 'css', value: '0 1rem 1rem 1rem'},
                      color: {type: 'css', value: '#565656', editable: true},
                      fontWeight: {type: 'css', value: 'normal'},
                      padding: {type: 'css', value: '0 2rem 1rem 0'},
                      fontSize: {type: 'css', value: '18px'}
                    }

                  }
                ]
              },
              {
                type: contentTypes.COLUMN,
                properties: {
                  large: {type: 'responsive', value: 6},
                  medium: {type: 'responsive', value: 6},
                  small: {type: 'responsive', value: 12},
                  margin: {type:'css', value: 0},
                  padding: {type:'css', value: '1rem'}
                },
                contents: [
                  {
                    type: contentTypes.IMAGE,
                    properties: {
                      src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/icons/icon_image.png', editable:true},
                      objectFit: {type: 'css', value: 'contain'},
                      width: {type: 'css', value:'3rem'},
                      height: {type: 'css', value:'3rem'},
                      margin: {type: 'css', value: '0 1rem 1rem 1rem'}
                    },
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem Ipsum',
                    properties: {
                      margin: {type: 'css', value: '0 1rem 0 1rem'},
                      color: {type: 'css', value: '#E53F21', editable: true},
                      fontWeight: {type: 'css', value: 'bold'},
                      padding: {type: 'css', value: '0 2rem 1rem 0'},
                      fontSize: {type: 'css', value: '24px'}
                    }
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                    properties: {
                      margin: {type: 'css', value: '0 1rem 1rem 1rem'},
                      color: {type: 'css', value: '#565656', editable: true},
                      fontWeight: {type: 'css', value: 'normal'},
                      padding: {type: 'css', value: '0 2rem 1rem 0'},
                      fontSize: {type: 'css', value: '18px'}
                    }

                  }
                ]
              },
              {
                type: contentTypes.COLUMN,
                properties: {
                  large: {type: 'responsive', value: 6},
                  medium: {type: 'responsive', value: 6},
                  small: {type: 'responsive', value: 12},
                  margin: {type:'css', value: 0},
                  padding: {type:'css', value: '1rem'}
                },
                contents: [

                  {
                    type: contentTypes.IMAGE,
                    properties: {
                      src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/icons/icon_image.png', editable:true},
                      objectFit: {type: 'css', value: 'contain'},
                      width: {type: 'css', value:'3rem'},
                      height: {type: 'css', value:'3rem'},
                      margin: {type: 'css', value: '0 1rem 1rem 1rem'}
                    },
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem Ipsum',
                    properties: {
                      margin: {type: 'css', value: '0 1rem 0 1rem'},
                      color: {type: 'css', value: '#E53F21', editable: true},
                      fontWeight: {type: 'css', value: 'bold'},
                      padding: {type: 'css', value: '0 2rem 1rem 0'},
                      fontSize: {type: 'css', value: '24px'}
                    }
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                    properties: {
                      margin: {type: 'css', value: '0 1rem 1rem 1rem'},
                      color: {type: 'css', value: '#565656', editable: true},
                      fontWeight: {type: 'css', value: 'normal'},
                      padding: {type: 'css', value: '0 2rem 1rem 0'},
                      fontSize: {type: 'css', value: '18px'}
                    }

                  }
                ]
              },
              {
                type: contentTypes.COLUMN,
                properties: {
                  large: {type: 'responsive', value: 6},
                  medium: {type: 'responsive', value: 6},
                  small: {type: 'responsive', value: 12},
                  margin: {type:'css', value: 0},
                  padding: {type:'css', value: '1rem'}
                },
                contents: [
                  {
                    type: contentTypes.IMAGE,
                    properties: {
                      src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/icons/icon_image.png', editable:true},
                      objectFit: {type: 'css', value: 'contain'},
                      width: {type: 'css', value:'3rem'},
                      height: {type: 'css', value:'3rem'},
                      margin: {type: 'css', value: '0 1rem 1rem 1rem'}
                    },
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem Ipsum',
                    properties: {
                      margin: {type: 'css', value: '0 1rem 0 1rem'},
                      color: {type: 'css', value: '#E53F21', editable: true},
                      fontWeight: {type: 'css', value: 'bold'},
                      padding: {type: 'css', value: '0 2rem 1rem 0'},
                      fontSize: {type: 'css', value: '24px'}
                    }
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
                    properties: {
                      margin: {type: 'css', value: '0 1rem 1rem 1rem'},
                      color: {type: 'css', value: '#565656', editable: true},
                      fontWeight: {type: 'css', value: 'normal'},
                      padding: {type: 'css', value: '0 2rem 1rem 0'},
                      fontSize: {type: 'css', value: '18px'}
                    }

                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 2},
          medium: {type: 'responsive', value:4 },
          small: {type: 'responsive', value: 10},
          padding: {type: 'css', value: '0 0 0 0'},
          margin: {type: 'css', value: '2rem'},
        },
        contents: [
          {
            type: contentTypes.IMAGE,
            properties: {
              src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/medium/eventtia_phone.png', editable:true},
              objectFit: {type: 'css', value: 'contain'},
              width: {type: 'css', value:'100%'},
            },
          },
        ]
      }
    ]
  }
}
