import { INSERT_PAGE, DELETE_PAGE, MOVE_PAGE } from "../actions/pages";
import { COMPOSER_SETTINGS_SUCCESS } from "composer-blocks/lib/actions/site";
import { getEntityFromResponse } from 'composer-blocks/lib/helpers/api';

const initialState = [
];

const pageOrder = (state = initialState, action) => {
  switch (action.type) {
    case INSERT_PAGE: {
      const { id } = action;
      const newPageOrder = state.slice();
      newPageOrder.push(id);

      return newPageOrder;
    }

    case DELETE_PAGE: {
      const { id } = action;
      const newPageOrder = state.slice();
      const index = newPageOrder.indexOf(id);
      newPageOrder.splice(index, 1);

      return newPageOrder;
    }

    case MOVE_PAGE: {
      const { id, newIndex } = action;
      const newPageOrder = state.slice();
      const oldIndex = newPageOrder.indexOf(id);

      newPageOrder.splice(oldIndex, 1);
      newPageOrder.splice(newIndex, 0, id);

      return newPageOrder;
    }

    case COMPOSER_SETTINGS_SUCCESS: {
      let newPageOrder = getEntityFromResponse(action, 'pageOrder');
      if (!newPageOrder) {
        const pages = getEntityFromResponse(action, 'pages') || {}; 
        newPageOrder = Object.keys(pages);
      }
      return newPageOrder || state;
    }

    default:
      return state;
  }
};

export default pageOrder;
