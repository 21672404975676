import React from 'react';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import responsiveModes from 'composer-blocks/lib/constants/ResponsiveModes';
import { setResponsiveMode } from 'composer-blocks/lib/actions/site';

const styles = {
  group: {
    display: 'flex'
  }
}

const ResponsiveModesMenu = injectSheet(styles)(({ classes }) => (
  <div className={classes.group}>
    {Object.keys(responsiveModes).map(k => responsiveModes[k]).map(
      ({ name, icon }) => (
        <Tooltip title={`Preview as ${name}`} key={name}>
          <span>
            <ResponsiveModeButton
              mode={name}
              IconClass={icon}
            />
          </span>
        </Tooltip>
      )
    )}
  </div>
));

const mapStateToProps = ({ site }, { mode }) => ({
  active: site.responsiveMode === mode
});

const mapDispatchToProps = (dispatch, { active, mode }) => ({
  setResponsiveMode: () => {
    if (!active) {
      dispatch(setResponsiveMode(mode));
    }
  }
});

const ResponsiveModeButton = connect(mapStateToProps, mapDispatchToProps)(({ setResponsiveMode, IconClass, active }) => (
  <IconButton
    className="ResponsiveModesMenu"
    onClick={setResponsiveMode}
    color={active ? 'secondary' : 'inherit'}
  >
    <IconClass />
  </IconButton>
));

export default ResponsiveModesMenu;
