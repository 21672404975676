import React from 'react';
import injectSheet from 'react-jss';

const styles = {
  BlockPlaceholder: {
    border: '5px dashed rgba(0, 0, 0, 0.5)',
    overflow: 'hidden',
  },
  h3: {
    margin: 10,
    height: '3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.5)',
    fontFamily: 'Quattrocento Sans, sans-serif'
  },
};

const BlockPlaceholder = ({ classes, isNavbarPlaceholder, isFooterPlaceholder }) => (
  <div className={classes.BlockPlaceholder}>
    <h3 className={classes.h3}>
      {isNavbarPlaceholder ?
        "Drag & Drop the navbar blocks here" : (isFooterPlaceholder ?
        "Drag & Drop the footer blocks here" :
        "Drag & Drop the building blocks here"
        )
      }
    </h3>
  </div>
);

export default injectSheet(styles)(BlockPlaceholder);
