import { combineReducers } from 'redux';
import app from './app';
import site from 'composer-blocks/lib/reducers/site';
import pages from './pages';
import blocks from './blocks';
import navbars from './navbars';
import footers from './footers';
import contents from './contents';
import otherData from './otherData';
import properties from './properties';
import pageOrder from './pageOrder';
import globalSettings from './globalSettings';

const composerApp = combineReducers({
  app,
  site,
  pages,
  blocks,
  navbars,
  footers,
  globalSettings,
  contents,
  otherData,
  properties,
  pageOrder,
});

export default composerApp;
