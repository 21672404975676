import React, { Component } from 'react';
import { buildStyleTagWithFontFaces } from 'composer-blocks/lib/helpers/misc'
import { translate } from 'react-i18next';
import injectSheet from 'react-jss';

const styles = {
  FontPreview: {
    fontSize: '36px',
    fontFamily: ({ font: { name, fallback } }) => `${name}, ${fallback}` ,
    fontWeight: ({ font: { weight } }) => weight,
    fontStyle: ({ font: { style } }) => style,
  },
}

class FontPreview extends Component {

  render() {
    const { t, classes, font } = this.props;
    const styleString = buildStyleTagWithFontFaces([font], font.name);
    return (
      <React.Fragment>
        {!!font.source && (
          <div
            dangerouslySetInnerHTML={{ __html: styleString }}
          />
        )}
        <div className={classes.FontPreview}>
          {t('labels.font_preview')}
        </div>
      </React.Fragment>
    );
  }

}

export default injectSheet(styles)(translate('Forms')(FontPreview));
