import generateId from '../helpers/ids';
import { idsWhere, where } from 'composer-blocks/lib/helpers/hasMany';
import { propertiesByContentType, defaultContentValues } from 'composer-blocks/lib/constants/ContentTypes';
import { setActiveContent } from "./app";

const buildAllProperties = (content, properties, results, state) => {
  let allProperties = {
    ...propertiesByContentType[content.type],
    ...properties
  };

  for (let propertyName of Object.keys(allProperties)) {
    const { type, value, editable } = allProperties[propertyName];
    const evaluatedValue = (typeof value === 'function') ? value(state) : value;
    const id = generateId();
    results[id] = {
      id,
      propertyName,
      type,
      editable,
      value: evaluatedValue,
      parentId: content.id
    };
  }
};

export const recursivelyBuildContents = (rootContent, parentId, contentDictionary, propertyDictionary, state) => {
  const { type, properties, value, contents } = rootContent;
  const id = generateId();
  const content = {
    id,
    value: value || defaultContentValues[type],
    type,
    parentId,
    childContents: []
  };
  contentDictionary[id] = content;

  if (contentDictionary.hasOwnProperty(parentId)) {
    contentDictionary[parentId].childContents.push(id);
  }

  buildAllProperties(content, properties, propertyDictionary, state);

  if (!!contents) {
    for (let childContent of contents) {
      recursivelyBuildContents(childContent, id, contentDictionary, propertyDictionary, state);
    }
  }

  return id;
};

export const recursivelyGetChildren = (id, contents, properties, childContents, childProperties) => {
  const currentChildContents = idsWhere(contents, 'parentId', id);
  const currentChildProperties = idsWhere(properties, 'parentId', id);
  childContents.push(...currentChildContents);
  childProperties.push(...currentChildProperties);
  for (let childId of currentChildContents) {
    recursivelyGetChildren(childId, contents, properties, childContents, childProperties);
  }
};

const buildTemplateFromProperty = (result, property) => {
  result[property.propertyName] = {
    type: property.type,
    value: property.value,
    editable: property.editable,
  }
  return result;
}

export const buildTemplateFromContent = (rootContentId, state) => {
  const { contents, properties, app } = state;
  const { contents: modifiedContents, properties: modifiedProperties } = app.modifiedItems;
  const rootContent = modifiedContents[rootContentId] || contents[rootContentId];
  const originalProperties = where(properties, 'parentId', rootContentId).reduce(buildTemplateFromProperty, {});
  const propertyTemplates = where(modifiedProperties, 'parentId', rootContentId).reduce(buildTemplateFromProperty, originalProperties);

  const result = {
    type: rootContent.type,
    value: rootContent.value,
    properties: propertyTemplates,
    contents: [],
  }

  if (!!rootContent.childContents) {
    for (let childContentId of rootContent.childContents) {
      result.contents.push(buildTemplateFromContent(childContentId, state))
    }
  }

  return result;
}

export const INSERT_CONTENT = 'INSERT_CONTENT';
export const insertContent = (type) => {
  return function(dispatch, getState) {
    const state = getState();
    const parentId = state.app.activeContentId;
    const contents = {};
    const properties = {};
    const contentId = recursivelyBuildContents({ type }, parentId, contents, properties, state);
    dispatch({
      type: INSERT_CONTENT,
      contentId,
      parentId,
      contents,
      properties
    });
  }
};

export const COPY_CONTENT_TO_CLIPBOARD = 'COPY_CONTENT_TO_CLIPBOARD';
export const copyContentToClipboard = (contentId) => {
  return function(dispatch, getState) {
    const state = getState();
    if (!contentId) contentId = state.app.activeContentId;
    const template = buildTemplateFromContent(contentId, state);
    return dispatch({
      type: COPY_CONTENT_TO_CLIPBOARD,
      template
    });
  }
}

export const pasteContent = (parentId) => {
  return function(dispatch, getState) {
    const state = getState();
    const { template } = state.app.clipboard;
    const contents = {};
    const properties = {};
    if (!parentId) parentId = state.app.activeContentId;
    if (!parentId) return;
    const contentId = recursivelyBuildContents(template, parentId, contents, properties, state);
    dispatch({
      type: INSERT_CONTENT,
      contentId,
      parentId,
      contents,
      properties
    });
  }
};

export const duplicateContent = (options) => {
  return function(dispatch, getState) {
    const state = getState();
    let { contentId, index } = options || {};
    if (!contentId) contentId = state.app.activeContentId;
    const originalContent = state.contents[contentId];
    const parent = state.contents[originalContent.parentId];
    if (!index) index = parent.childContents.indexOf(contentId) + 1;

    const contents = {};
    const properties = {};
    const template = buildTemplateFromContent(contentId, state);
    const newContentId = recursivelyBuildContents(template, parent.id, contents, properties, state);

    dispatch({
      type: INSERT_CONTENT,
      contentId: newContentId,
      parentId: parent.id,
      contents,
      properties,
      index
    });
  }
}

export const MOVE_CONTENT = 'MOVE_CONTENT';
export const moveContent = (id, newIndex, newParentId) => {
  return function(dispatch, getState) {
    const oldParentId = getState().contents[id].parentId;
    let action = {
      type: MOVE_CONTENT,
      oldParentId,
      newParentId,
      newIndex,
      id
    };
    dispatch(action);
  }
};

export const DELETE_CONTENT = 'DELETE_CONTENT';
export const deleteContent = (id) => {
  return function(dispatch, getState) {
    const { contents, properties } = getState();
    const { activeContentId } = getState().app;
    const childContents = [id];
    const childProperties = [];

    recursivelyGetChildren(id, contents, properties, childContents, childProperties);

    const parentId = contents[id].parentId;

    let action = {
      type: DELETE_CONTENT,
      id,
      childContents,
      childProperties,
      parentId
    };

    dispatch(action);

    if (activeContentId && activeContentId) dispatch(setActiveContent(null));
  }
};
