import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { translate } from 'react-i18next';

export default translate('Forms')(({ t, tPath, text, onClick, Icon, buttonProps, divButtonProps }) => (
  <Tooltip title={tPath ? t(tPath) : text}>
    <div
      {...divButtonProps}
      >
      <IconButton
        color="inherit"
        aria-label={tPath ? t(tPath) : text}
        onClick={onClick}
        {...buttonProps}
      >
        <Icon />
      </IconButton>
    </div>
  </Tooltip>
));
