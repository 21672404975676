import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from './Select';

class FontFamilySelector extends Component {

  render() {
    const { value, options: defaultOptions, onChange, accountFonts, globalFontId } = this.props;

    const options = [...defaultOptions];
    if (!!globalFontId && globalFontId !== '0') globalFontId.split('-').forEach((fontId) => {
      const font = accountFonts[fontId];
      if (font) options.push({
        label: font.name,
        value: `${font.name}, ${font.fallback || 'sans-serif'}`,
      });
    });

    return (
      <Select
        value={value}
        onChange={onChange}
        options={options}
      />
    );
  }
}

const mapStateToProps = ({ app, site, globalSettings }) => ({
  accountFonts: site.entities.account_fonts,
  globalFontId: globalSettings.globalFontId,
});

export default connect(mapStateToProps)(FontFamilySelector);
