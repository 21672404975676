import React, { Component } from 'react';
import injectSheet from 'react-jss';
import Image from '@material-ui/icons/Image';
import Done from '@material-ui/icons/Done';
import TextField from '@material-ui/core/TextField';
import { unwrapWithUrl } from 'composer-blocks/lib/helpers/backgrounds';
import TranslatableIconButton from '../TranslatableIconButton';
import FullScreenModal from '../FullScreenModal';
import FileUploader from '../../containers/FileUploader';
import StockImagesGallery from '../../containers/StockImagesGallery';
import UserImagesGallery from '../../containers/UserImagesGallery';

const STOCK_IMAGES = 'Stock Images';
const USER_IMAGES = 'User Images';
const UPLOAD_IMAGE = 'Upload Image';

const styles = theme => ({
  ImagePicker: {
    display: 'inline-block',
  },
  uploaderContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  customUrlContainer: {
    textAlign: 'center',
  },
  iconButton: {
    marginRight: '-0.75rem'
  },
  inputRoot: {
    width: '25em'
  }
});

class ImagePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      currentTab: STOCK_IMAGES,
      inputValue: unwrapWithUrl(props.value)
    };
  }

  openModal = () => this.setState({ open: true })
  closeModal = () => this.setState({ open: false })
  setCurrentTab = (tabId) => () => this.setState({ currentTab: tabId })
  setCustomUrl = () => {
    this.onChange(this.state.inputValue);
  }

  onChange = (url) => {
    this.props.onChange(url);
    this.setState({inputValue: url});
    this.closeModal();
  }

  onChangeInput = (event) => this.setState({ inputValue: event.target.value })

  render() {
    const { classes, value } = this.props;
    const { currentTab } = this.state;
    const selectedImage = unwrapWithUrl(value);
    const { inputValue } = this.state;

    const tabs = {
      [STOCK_IMAGES]: <StockImagesGallery selectedImage={selectedImage} onChange={this.onChange} />,
      [USER_IMAGES]: <UserImagesGallery selectedImage={selectedImage} onChange={this.onChange} />,
      [UPLOAD_IMAGE]: (
        <div className={classes.uploaderContainer}>
          <FileUploader onSelect={this.onChange} />
          <div>or</div>
          <div className={classes.customUrlContainer}>
            <TextField
              label="Custom URL / linear gradient"
              value={(typeof inputValue === 'object') ? inputValue.original : inputValue}
              onChange={this.onChangeInput}
              classes={{
                root: classes.inputRoot
              }}
            />
            <TranslatableIconButton
              text="Apply"
              onClick={this.setCustomUrl}
              Icon={Done}
            />
          </div>
        </div>
      )
    }

    return (
      <div className={classes.ImagePicker}>
        <TranslatableIconButton tPath="buttons.select_image" onClick={this.openModal} Icon={Image} buttonProps={{className: classes.iconButton}} />
        {this.state.open && (
          <FullScreenModal
            tabs={tabs}
            currentTab={currentTab}
            closeModal={this.closeModal}
            setCurrentTab={this.setCurrentTab}
          />
        )}
      </div>
    );
  }

}

export default injectSheet(styles)(ImagePicker);
