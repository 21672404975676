import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';

class DropdownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorElement: null,
    };
  }

  openMenu = (event) => {
    this.setState({ anchorElement: event.currentTarget });
  }

  closeMenu = () => {
    this.setState({ anchorElement: null });
  }

  render() {
    const { menuId, icon, menuItems, closeOnSelect = true } = this.props;
    const { anchorElement } = this.state;
    const open = !!anchorElement;
    const id = `${menuId}-menu`;

    return (
      <div className="DropdownMenu">
        <IconButton
          aria-owns={open ? id : null}
          aria-haspopup="true"
          onClick={this.openMenu}
          color="inherit"
        >
          {icon}
        </IconButton>
        <Menu
          id={id}
          anchorEl={anchorElement}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={this.closeMenu}
          onClick={closeOnSelect && this.closeMenu}
        >
          {menuItems}
        </Menu>
      </div>
    );
  }
}

export default DropdownMenu;
