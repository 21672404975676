import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import CloudUpload from '@material-ui/icons/CloudUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import { publishModifiedItems } from '../actions/app';

const styles = {
  progress: {
    color: 'white'
  }
};

class PublishButton extends Component {

  render() {
    const { classes, publishModifiedItems, active, isFetching } = this.props;
    return isFetching ? (
      <CircularProgress className={classes.progress} size={25}/>
    ) : (
      <IconButton disabled={!active} color="inherit" aria-label="Publish" onClick={publishModifiedItems}>
        <CloudUpload />
      </IconButton>
    );
  }
}

const mapStateToProps = ({ app, site }) => ({
  ...app.publishChanges,
  site: app
});

const mapDispatchToProps = {
  publishModifiedItems
};

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(PublishButton));
