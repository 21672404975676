import React, { Component } from 'react';
import { connect } from 'react-redux';

const defaultRenderer = workshopCategory => (
  <div key={workshopCategory.id}>{workshopCategory.name}</div>
)

class WorkshopCategoryList extends Component {

  render() {
    const { workshopCategories, renderItem = defaultRenderer } = this.props;

    return (
      <React.Fragment>
        {workshopCategories && Object.values(workshopCategories).map(renderItem)}
      </React.Fragment>
    );
  }

}

const mapStateToProps = ({ site: { entities: { workshop_categories } } }) => ({
  workshopCategories: workshop_categories
})

export default connect(mapStateToProps)(WorkshopCategoryList);
