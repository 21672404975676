import i18n from 'i18next';
import resources from './locales';
import { getQueryParam } from "composer-blocks/lib/helpers/misc";



const websiteData = getQueryParam('website_lang');
const websiteLangSessionStorage = sessionStorage.getItem('website_lang') || websiteData || 'en';
sessionStorage.setItem("website_lang", websiteLangSessionStorage);


const i18nSite = i18n.createInstance();

i18nSite.init({ resources: resources, lng: websiteLangSessionStorage, fallbackLng: 'en' });
export default i18nSite;
