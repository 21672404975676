import { CUSTOM } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'custom_17',
  type: CUSTOM,
  name: 'Custom block with carousel',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/custom/custom_17.png',
  rootContent: {
    type: contentTypes.CAROUSEL,
  }
}
