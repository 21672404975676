import "babel-polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import i18n from './helpers/i18n';
import configureStore from './store/configureStore';
import App from './App';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { MuiThemeProvider, createGenerateClassName } from '@material-ui/core/styles';
import composerTheme from './helpers/composerTheme';
import urls from 'composer-blocks/lib/constants/urls';
import JssProvider from 'react-jss/lib/JssProvider';

const generateClassName = createGenerateClassName({
  productionPrefix: 'cmpsr',
});
urls.setEventtiaBase(process.env.REACT_APP_BASE_URL);
const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <JssProvider generateClassName={generateClassName}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <MuiThemeProvider theme={composerTheme}>
            <div>
              <Switch>
                <Route path="/:locale(en|es|fr|de)" component={App}/>
                <Route render={({ location }) => <Redirect to={`/en${location.pathname}${location.search}${location.hash}`}/>}/>
              </Switch>
            </div>
          </MuiThemeProvider>
        </BrowserRouter>
      </I18nextProvider>
    </JssProvider>
  </Provider>,
  document.getElementById('root')
);
