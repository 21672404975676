import { SPEAKERS } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'speakers_2',
  type: SPEAKERS,
  name: 'Speakers tiles',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/speakers/speakers_2.png',
  rootContent: {
    type: contentTypes.SPEAKER_TILES,
    properties: {
      backgroundColor: {type: 'css', value: '#FFFFFF', editable: true},
      speakerPaginationSize: {type: 'custom', value: 8, editable: true},
      coloursSelector: {type: 'custom',
                        value: [
                          {r:'245',g:'245',b:'245',a:'1'},
                          {r:'255',g:'198',b:'69',a:'1'},
                          {r:'208',g:'220',b:'225',a:'1'},
                          {r:'33',g:'33',b:'33',a:'1'},
                          {r:'97',g:'77',b:'174',a:'1'},
                          {r:'254',g:'95',b:'55',a:'1'},
                          {r:'80',g:'227',b:'194',a:'1'},
                          {r:'245',g:'166',b:'35',a:'1'}
                        ],
                        editable: true},

    },
  }
}
