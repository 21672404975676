import React, { Component } from 'react';
import injectSheet from 'react-jss';
import Grid from '@material-ui/core/Grid';
import Paginator from 'composer-blocks/lib/components/Paginator';

const styles = theme => ({
  Gallery: {
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    cursor: 'pointer',
  },
  selectedThumbnail: {
    boxShadow: `0 0 0 0.25rem ${theme.palette.primary.contrastText}`,
  },
});

class Gallery extends Component {
  setImage = (imageUrl) => () => this.props.onChange(imageUrl)

  isSelected = (imgUrl) => {
    if (typeof imgUrl === 'object') {
      imgUrl = imgUrl.original
    }
    let { selectedImage } = this.props;
    if (typeof selectedImage === 'object') {
      selectedImage = selectedImage.original
    }
    return imgUrl === selectedImage;
  }

  render() {
    const { classes, images, fetchPage, links } = this.props;

    return (
      <React.Fragment>
        <Grid container spacing={16} className={classes.Gallery}>
          {images.map((image, index) => (
            <Grid item xs={6} sm={4} md={3} lg={2} key={(typeof image.url === 'object') ? image.url.original : image.url}>
              <img
                onClick={this.setImage(image.url)}
                src={image.thumb}
                alt={image.name}
                className={`${classes.thumbnail} ${this.isSelected(image.url) ? classes.selectedThumbnail : ''}`}
              />
            </Grid>
          ))}
        </Grid>
        {(!!images && !!images.length && (!!links.next || !!links.prev)) && (
          <Paginator fetchPage={fetchPage} {...links}/>
        )}
      </React.Fragment>
    );
  }

}


export default injectSheet(styles)(Gallery);
