import { CUSTOM } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

const columnProps = {
  large: {type: 'responsive', value: 3},
  medium: {type: 'responsive', value: 10},
  display: {type: 'css', value: 'flex'},
  justifyContent: {type: 'css', value: 'flex-start'},
}

const innerRowProps = {
  display: {type: 'css', value: 'flex'},
  justifyContent: {type: 'css', value: 'space-between'},
  flexDirection: {type: 'css', value: 'column'},
  padding: {type: 'css', value: '1rem'},
  large: {type: 'responsive', value: 12},
  medium: {type: 'responsive', value: 12},
  small: {type: 'responsive', value: 12},
  flexGrow: {type: 'css', value:1},
}

const innerColumnProps = {
  display: {type: 'css', value: 'flex'},
  justifyContent: {type: 'css', value: 'space-around'},
  padding: {type: 'css', value: '0rem'},
  large: {type: 'responsive', value: 12},
  medium: {type: 'responsive', value: 12},
  small: {type: 'responsive', value: 12},
}

const imageProps = {
  width: {type: 'css', value: '100%'},
  minHeight: {type: 'css', value: '13rem'},
  height: {type: 'css', value: '13rem'},
  objectFit: {type: 'css', value: 'cover'},
  marginBottom: {type: 'css', value: '1rem'}
}

const textProps = {
  color: {type: 'css', value: '#000000', editable: true},
}

const boldTextProps = {
  ...textProps,
  fontWeight: {type: 'css', value: 'bold'},
}

export default {
  id: 'custom_15',
  type: CUSTOM,
  name: 'Custom background figure',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/custom/custom_15.png',
  rootContent: {
    type: contentTypes.ROW_WITH_STRIPE,
    contents: [
      {
        type: contentTypes.COLUMN,
        properties: {
          ...columnProps
        },
        contents: [
          {
            type: contentTypes.IMAGE,
            properties: {
              src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/medium/woman_face.png', editable:true},
              ...imageProps
            }
          },
          {
            type: contentTypes.ROW,
            properties: {
              ...innerRowProps
            },
            contents: [
              {
                type: contentTypes.COLUMN,
                properties: {
                  ...innerColumnProps
                },
                contents: [
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem Ipsum is simply dummy text',
                    properties: {
                      ...boldTextProps
                    }
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                    properties: {
                      ...textProps
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          ...columnProps
        },
        contents: [
          {
            type: contentTypes.IMAGE,
            properties: {
              src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/medium/woman_window.png', editable:true},
              ...imageProps
            },
          },
          {
            type: contentTypes.ROW,
            properties: {
              ...innerRowProps
            },
            contents: [
              {
                type: contentTypes.COLUMN,
                properties: {
                  ...innerColumnProps
                },
                contents: [
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem Ipsum is simply dummy text',
                    properties: {
                      ...boldTextProps
                    },
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                    properties: {
                      ...textProps
                    },
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          ...columnProps
        },
        contents: [
          {
            type: contentTypes.IMAGE,
            properties: {
              src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/medium/woman.png', editable:true},
              ...imageProps
            },
          },
          {
            type: contentTypes.ROW,
            properties: {
              ...innerRowProps
            },
            contents: [
              {
                type: contentTypes.COLUMN,
                properties: {
                  ...innerColumnProps
                },
                contents: [
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem Ipsum is simply dummy text',
                    properties: {
                      ...boldTextProps
                    },
                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                    properties: {
                      ...textProps
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
}
