import { NAVBAR } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'navbar_23',
  type: NAVBAR,
  name: 'Navbar 23',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/navbar/navbar_23.png',
  rootContent: {
    type: contentTypes.NAVBAR,
    properties: {
      backgroundColor: {type: 'css', value: '#A671D1',editable: true},
      color: {type: 'css', value: '#fcfef7', editable: true},
      padding: {type: 'css', value: '1rem 1rem'},
      fontSize: {type: 'css', value: 'small', editable: true},
      logoSrc: {type: 'custom', value: '', editable: true},
      logoHeight: {type: 'custom', value: '1rem'},
      navItemHoverUnderline: {type: 'custom', value: '#fcfef7', editable: true},
      navItemActiveUnderline: {type: 'custom', value: '#fcfef7', editable: true},
      navItemPadding: {type: 'custom', value: '0.5rem'},
      navItemMargin: {type: 'custom', value: '0rem 0.5rem'},
      navItemActiveTextColor: {type: 'custom', value: 'white'},
      fontWeight: {type: 'css', value: 'normal'},
      navListItemSeparator: {type: 'custom', value: 'true'},
      logoPosition: {type: 'custom', value: 'top-left'},
      logoAlignSelf: {type: 'custom', value: 'flex-start'},
      navItemsAlignSelf: {type: 'custom', value: 'flex-start'},
    },
    contents: []
  }
}
