import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { translate } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import TranslatableButton from '../components/TranslatableButton';
import Done from '@material-ui/icons/Done';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import isEmpty from 'lodash/isEmpty';


const styles = {
  selectorWrapper:{
    display: 'flex',

  },
  visibleParticipantsWrapper:{
    marginBottom: '1rem',
    padding: '0.8rem',
    backgroundColor:'#343d4b',
  },
  typesToShowWrapper:{
    width:'42%',
    marginRight:'0.8rem',
  },
  typesToShow:{
    marginTop: '0rem',
    color:'#a9a9a9',
  },
  notConference:{
    margin:'5rem 2rem',
    fontSize: '2em',
    color: '#999',
  },
  typeSelector:{
    width:'60%',
    height:'3.5rem',
  },
  innerContainer:{
    display: 'flex',
    justifyContent:'flex-start',
    height: '100%',
    width: '100%',
    '& .left-panel':{
      padding:'0.2rem 1rem 1rem 0.5rem',
      width:'17rem',
      borderRight: '1px solid #373b43',
      overflowY:'auto',
      '& .left-list':{
        '& .business-conference-name':{
          borderBottom: '1px solid #373b43',
          marginLeft:'-1rem',
          paddingLeft: '1rem',
          paddingBottom: '0.5rem',
        }
      }
    },
    '& .right-panel':{
      padding:'1rem 1rem 1rem 4rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      minWidth: '65%',
    }
  },
  draggableItem:{
    margin: '0rem',
    padding: '0.35rem',
    cursor: 'pointer',
    '&:hover':{
      backgroundColor: '#343d4b',
    }
  },
  titleFields:{
    fontSize: '17px',
    padding: '0.3rem 0rem 0.25rem 0rem',
  },
  checkboxLabel:{
    display: 'inline-flex !important',
  }
}

// Feb 18 - Cristian Ospina
// Regular expression for get all the words inside ${}
const fieldsRegex = /\${(.+?)\}/gm;
const DEFAULT_ATTENDEE =  'default_attendee';
const CUSTOM_ATTENDEE = 'custom_attendee';
const CUSTOM_PARTICIPANT = 'custom_participant';

class DraggableParticipantContainer extends Component {

  // atype will store the attendee type id for the current dropbox selected.
  // The same for ptype with participant type id.
  constructor(props){
    super(props);
    this.state = {
      ptype: null,
      atype: null,
    };
    this.textarea = React.createRef();
  }

  // Author: Cristian Ospina
  // Date: 8 / Feb / 19
  // Last modified: 22/Feb/19
  // when mount its neccesary to fill the menu of attendee type selector base on full information available
  // (obtained in getSidebarInfo). Then when initial attendee type is loaded, textarea value is filled
  componentDidMount(){
    this.setInitialState();
  }

  setInitialState = () => {
    const { entities } = this.props;
    const { business_conferences, business_conference_participant_types, attendee_types } = entities;
    if(!!business_conferences){
      let firstParticipantType = Object.keys(business_conference_participant_types || {}).map(id => business_conference_participant_types[id])[0];
      let firstAttendeeTypeId = !firstParticipantType ? null : firstParticipantType.attendeeTypes[0].id;
      this.setState({
        ptype: !!firstParticipantType ? firstParticipantType.id : null,
        atype: firstAttendeeTypeId
      })
    }
  }

  componentDidUpdate(prevProps){
    const { entities } = this.props;
    const { business_conference_participant_types, attendee_types } = entities;
    if((!prevProps.entities.business_conference_participant_types || !prevProps.entities.attendee_types)
        && (!!business_conference_participant_types && !!attendee_types)){
          this.setInitialState();
    }
  }

  // Feb 18 - Cristian Ospina
  // Helper function for getting data structure (collection of objects)
  // of fields found in current usable fields (sidebar fields)
  // In an input string first search for words inside ${} after that search
  // if these matches are fields and then return information for each field
  getFieldMatches = (currentAttendeeTypeId, value) => {
    let matches = [];
    let match, field;
    while ((match = fieldsRegex.exec(value)) != null) {
      field = this.findFieldByName(currentAttendeeTypeId, match[1]);
      if(!field) continue;
      matches.push({
        index: match.index,
        type: field.type,
        id: field.id,
        patternString: match[0],
      });
    }
    return matches;
  }


  // Author: Cristian Ospina
  // Date: 7 / Feb / 19
  // Get the complete list of participant types with the id and name
  getParticipantTypes = (participantTypes) => {
    if(!participantTypes) return [{ id:null, name:null }];
    let ids = [], names = [];
    ids = Object.keys(participantTypes);
    for (let i = 0; i < ids.length; i++) { names.push({id: ids[i], name: participantTypes[ids[i]].name}) }
    return names;
  }

  // this can be replace by the below function
  getAllAttendeeTypeIds = () => {
    const { entities } = this.props;
    const { attendee_types } = entities;
    if(!attendee_types) return [];
    return Object.keys(attendee_types);
  }

  // Author: Cristian Ospina
  // Date: 22 / Feb / 19
  // Get the complete list of attendee types
  getAttendeeTypes = (attendeeTypes ) => {
    if(!attendeeTypes) return [{ id:null, name:null }];
    let ids = [], names = [];
    ids = Object.keys(attendeeTypes);
    for (let i = 0; i < ids.length; i++) { names.push({id: ids[i], name: attendeeTypes[ids[i]].name}) }
    return names;
  }

  // Author: Cristian Ospina
  // Date: 7 / Feb / 19
  // Get the complete list of attendee types related to a pareticular participant type
  getAttendeeTypesByParticipantType = (participantTypeId, participantTypes, attendeeTypes ) => {
    if(!participantTypeId) return [{ id:null, name:null }];
    let attendeeTypesIds = participantTypes[participantTypeId].attendeeTypes;
    let ids = [], names = [];
    for (let i = 0; i < attendeeTypesIds.length; i++) { ids.push(attendeeTypesIds[i].id) }
    //attendeeTypesIds.map(attendeeTypeId => { ids.push(attendeeTypeId.id) });
    for (let i = 0; i < ids.length; i++) { names.push({id:ids[i], name: attendeeTypes[ids[i]].name}) }
    //ids.map(id => { names.push({id:id, name: attendeeTypes[id].name})})
    return names;
  }

  // Author: Cristian Ospina
  // Date: 7 / Feb / 19
  // Get the attendee custom and default fields name
  getAttendeeTypeCustomFields = (attendeeType, fields) => {
    if(!attendeeType) return [{ id:null, name:null, type:null }];
    let ids = [], names = [], inputNameBlackList = ['city_id','headshot'], inputTypeWhiteList = [1,2,3,4,6,9,10], atCustomFields = attendeeType.attendeeTypeCustomFields;
    if(!atCustomFields) return [{ id:null, name:null, type:null }];
    for (var i = 0; i < atCustomFields.length; i++) { ids.push(atCustomFields[i].id)}
    ids.filter(id => inputTypeWhiteList.indexOf(fields[id].inputType) > -1
            && fields[id].availablePublicListings
            && inputNameBlackList.indexOf(fields[id].name) === -1)
        .map(id => {
            names.push({id:id,
            name: (fields[id].alias || fields[id].name),
            type: (fields[id].default ? DEFAULT_ATTENDEE : CUSTOM_ATTENDEE)})
    })
    return names;
  }

  // Author: Cristian Ospina
  // Date: 7 / Feb / 19
  // Get the participant custom fields name
  getParticipantTypeCustomFields = (participantType, fields) => {
    if(!participantType) return [{ id:null, name:null, type:null }];
    let ids = [], names = [], inputTypeWhiteList = [1,2,3,4,6,9,10], bcProfileFields =  participantType.businessConferenceProfileFields;
    for (let i = 0; i < bcProfileFields.length; i++) { ids.push(bcProfileFields[i].id)}
    ids.filter(id => fields[id].displayOnProfile && inputTypeWhiteList.indexOf(fields[id].type) > -1)
       .map(id => { names.push( {id:id, name:fields[id].name, type: CUSTOM_PARTICIPANT })
    })
    return names;
  }

  // Author: Cristian Ospina
  // Date: 21 / Feb / 19
  // Get the corresponding participant type id from an attendee type id
  getPTypeFromAType = (atype) => {
    if(!atype) return null;
    const { entities } = this.props;
    const { business_conference_participant_types } = entities;
    if(!business_conference_participant_types) return null;
    let participant_types = Object.keys(business_conference_participant_types);
    let ptype = null;
    return participant_types.find(ptype => {
      const atypes = business_conference_participant_types[ptype].attendeeTypes.map(at => at.id);
      return atypes.indexOf(atype) !== -1;
    })
  }

  // Author: Cristian Ospina
  // Date: 7 / Feb / 19
  // Get the information that is displayed in the sidebar ( participant type names, attendee type names
  // for those participant type, attendee custom fields and networking custom fields)
  getSidebarInfo = (atype) => {
    const { entities } = this.props;
    const { business_conference_participant_types, attendee_types, attendee_type_custom_fields, business_conference_profile_fields } = entities;
    // Get the information to populate the two selectors in side bar
    //let participantTypesName =
    //this.getParticipantTypes(business_conference_participant_types);
    let participantTypesName = Object.keys(business_conference_participant_types || {}).map(id => business_conference_participant_types[id]);
    let attendeeTypesName = this.getAttendeeTypesByParticipantType(this.state.ptype, business_conference_participant_types,attendee_types);
    // Get the information to populate draggable fields
    let attendeeFields = this.getAttendeeTypeCustomFields(attendee_types[atype || this.state.atype || attendeeTypesName[0].id], attendee_type_custom_fields);
    let participantFields = this.getParticipantTypeCustomFields(business_conference_participant_types[this.getPTypeFromAType(atype) || this.state.ptype || participantTypesName[0].id], business_conference_profile_fields);

    return [participantTypesName, attendeeTypesName, attendeeFields, participantFields];
  }

  // Author: Cristian Ospina
  // Date: 7 / Feb / 19
  // Search a field by name (first search in networking fields after in attendee fields)
  // if find return the id and type of that field. This method is called when Apply changes
  // in order to get the ids of the fields dropped or wrote in textarea
  findFieldByName = (currentAttendeeTypeId, name) => {
    let [,,aFields, pFields] = this.getSidebarInfo(currentAttendeeTypeId);
    for (let i = 0; i < pFields.length; i++) {
      if(pFields[i].name === name) return {id: pFields[i].id, type: pFields[i].type}
    }
    for (let i = 0; i < aFields.length; i++) {
      if(aFields[i].name === name) return {id: aFields[i].id, type: aFields[i].type}
    }
    return null;
  }

  // Author: Cristian Ospina
  // Date: 7 / Feb / 19
  // Search a field by name (first search in networking fields after in attendee fields)
  // if find return the id and type of that field. This method is called when Apply changes
  // in order to get the ids of the fields dropped or wrote in textarea
  checkTextArea = (attendeeTypeId) => {
    let attendeeTypeIds = this.getAllAttendeeTypeIds();
    let matches = {};
    for (let i = 0; i < attendeeTypeIds.length; i++) {
      if(attendeeTypeIds[i] === attendeeTypeId){
        matches[attendeeTypeId] = this.getFieldMatches(attendeeTypeId, this.textarea.current.value)
        continue;
      }
      matches[attendeeTypeIds[i]] = this.getFieldMatches(attendeeTypeIds[i], this.props.textAreaValue(attendeeTypeIds[i]))
    }
    //let matches = this.getFieldMatches(this.textarea.current.value)
    this.props.onApply(matches,attendeeTypeIds)
  }

  handleTextArea = (attendeeTypeId) => (event) => {
    this.props.changeState(event.target.value, attendeeTypeId);
  }

  handleCheck = (participantTypeId) => (e) =>{
    this.props.onCheck(e.target.checked,participantTypeId);
  }

  handleDropItem = (attendeeTypeId) => (e) =>{
    this.props.onDropItem(e,this.textarea,attendeeTypeId);
  }

  // determines whether a checkbox is checked or not. Initially all the
  // checkboxes are checked (even those whose id is not known)
  checkField = (selectedParticipant) => {
    if(selectedParticipant === undefined) return true;
    return selectedParticipant;
  }

  // Author: Cristian Ospina
  // Date: 11 / Feb / 19
  // Last Modified: 22 / Feb / 19
  // When change the dropdown the current participant type depends on the selected
  // attendee type.
  onChangeType = event => {
    const { entities} = this.props;
    const { business_conference_participant_types, attendee_types } = entities;
    this.setState({
      atype: event.target.value,
      ptype: this.getPTypeFromAType(event.target.value)
    })
  }

  renderParticipantTypesCheckboxes = (participantTypesName) => {
    const { selectedParticipants, classes, t } = this.props;
    return(
      <div className={classes.visibleParticipantsWrapper}>
        <p className={classes.typesToShow}>{t('labels.types_to_show')}</p>
        {participantTypesName.map(field => (
            <FormControlLabel
              key = {field.id}
              classes={{ root:classes.checkboxLabel}}
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  onChange={this.handleCheck(field.id)}
                  checked={this.checkField(selectedParticipants[field.id])}
                  classes={{ root:classes.checkboxLabel}}
                />
              }
              label={field.name}
            />
        ))}
      </div>
    )
  }

  combineTypes = (attendeeTypesNames) => {
    const { entities } = this.props;
    const { business_conference_participant_types: participantTypes } = entities;
    let participantTypeId, combinedTypes = [];
    for (var i = 0; i < attendeeTypesNames.length; i++) {
      participantTypeId = this.getPTypeFromAType(attendeeTypesNames[i].id);
      if(!participantTypes[participantTypeId] || !attendeeTypesNames[i]) continue;
      combinedTypes.push({id: attendeeTypesNames[i].id,
                        name: `${participantTypes[participantTypeId].name} - ${attendeeTypesNames[i].name}`})
    }
    return combinedTypes;
  }

  renderTypeSelector = () => {
    const { classes, t, entities } = this.props;
    const { attendee_types } = entities;
    const attendeeTypesName = this.getAttendeeTypes(attendee_types);
    const combinedTypes = this.combineTypes(attendeeTypesName);
    return(
      <div className={classes.selectorWrapper}>
        <div className={classes.typesToShowWrapper}>
          <p className={classes.typesToShow}>{t('labels.b2b_how_to')}</p>
        </div>
        <TextField
          select
          value={this.state.atype || combinedTypes[0].id}
          helperText={t('labels.networking_profile')}
          margin="dense"
          variant="outlined"
          className={classes.typeSelector}
          onChange={this.onChangeType}
        >
        {combinedTypes.map(type => (
           <MenuItem key={type.id} value={type.id}>
             {type.name}
           </MenuItem>
         ))}
        </TextField>
      </div>
    )
  }

  renderDraggableItems = (attendeeFields, participantFields) => {
    const { classes, t} = this.props;
    return (
      <React.Fragment>
        <div className='attendee-list'>
          <p className={classes.titleFields}><strong>{t('labels.attendee_fields')}</strong></p>
          {attendeeFields.map(field => (
            <div
              className={classes.draggableItem}
              draggable='true'
              id={field.id}
              key={field.id}
              onDragStart={(e) => this.props.onDragItem(e,field.name)}
              >
              {field.name}
            </div>
          ))}

        </div>
        <div className='participant-list'>
          <p className={classes.titleFields}><strong>{t('labels.b2b_fields')}</strong></p>
            {participantFields.map(field => (
              <div
                className={classes.draggableItem}
                draggable='true'
                id={field.id}
                key={field.id}
                onDragStart={(e) => this.props.onDragItem(e,field.name)}
                >
                {field.name}
              </div>
            ))}
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { classes, entities, t} = this.props;
    const { business_conferences, business_conference_participant_types, attendee_types } = entities;
    let participantTypesName, attendeeTypesName, attendeeFields, participantFields;

    // Get the information to populate the sidebar
    if(!!business_conferences && !!business_conference_participant_types)
      [participantTypesName, attendeeTypesName, attendeeFields, participantFields] = this.getSidebarInfo(null);

    return (
      <div className={classes.innerContainer}>
        <div className='left-panel'>
          <div className='left-list'>

            {!!business_conferences && !!Object.keys(business_conferences)[0] && (
              <p className='business-conference-name'>{business_conferences[Object.keys(business_conferences)[0]].name.toUpperCase()}</p>
            )}

            {!!business_conferences && !!business_conference_participant_types &&
              this.renderParticipantTypesCheckboxes(participantTypesName)}

            {!!business_conferences && !!attendeeFields && !!participantFields &&
              this.renderDraggableItems(attendeeFields, participantFields) }

          </div>
        </div>


        {!!business_conferences && !!attendeeTypesName && !!participantTypesName
          && !!attendeeFields && !!participantFields ?
          (
            <div className='right-panel'>

              {!!business_conference_participant_types &&
                this.renderTypeSelector() }

              <TextField
                id="outlined-full-width"
                onChange={this.handleTextArea(this.state.atype)}
                style={{ margin: 8 }}
                placeholder={t('labels.placeholder_b2b_drop')}
                helperText={`Informacion a mostrar para los attendees de tipo `}
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
                rows="17"
                inputRef={this.textarea}
                onDragOver={(e)=>this.props.onDragOver(e)}
                onDragEnter={(e)=>this.props.onDragEnter(e)}
                onDrop={this.handleDropItem(this.state.atype)}
                value={this.props.textAreaValue(this.state.atype,attendeeFields,participantFields)}
              />
              <TranslatableButton
                onClick={() => this.checkTextArea(this.state.atype)}
                leftIcon={<Done />}
                tPath="buttons.apply_changes"
              />
            </div>
          ) : (<p className={classes.notConference}>{t('warnings.create_conference_and_participant')}</p>)
        }
      </div>
    );
  }
}

const mapStateToProps = ({ site }) => ({
    entities: site.entities,
  })

export default connect(mapStateToProps)(translate('Forms')(injectSheet(styles)(DraggableParticipantContainer)));
