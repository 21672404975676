import { CUSTOM } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'custom_7',
  type: CUSTOM,
  name: 'Custom with cards2',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/custom/custom_7.png',
  rootContent: {
    type: contentTypes.ROW,
    properties: {
      backgroundColor: {type: 'css', value: '#FFFFFF', editable: true},
      color: {type: 'css', value: ''},
      fontWeight: {type: 'css', value: 'bold'},
      padding: {type: 'css', value: '0'},
      display: {type: 'css', value: 'flex'},
      justifyContent: {type: 'css', value: 'center'}
    },
    contents: [
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 3},
          medium: {type: 'responsive', value: 9},
          padding: {type: 'css', value: 0},
          margin: {type: 'css', value: '2rem 1rem 2rem 1rem'},
          backgroundColor: {type: 'css', value: '#F7F8F9'},
          borderRadius: {type: 'css', value: '10px'},
          display: {type: 'css', value: 'flex'},
          justifyContent: {type: 'css', value: 'space-between'},
        },
        contents: [
          {
            type: contentTypes.IMAGE,
            properties: {
              src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/medium/structure.png', editable:true},
              width: {type: 'css', value:'100%'},
              minHeight: {type:'css', value:'20rem'},
              height: {type: 'css', value:'20rem'},
              objectFit: {type:'css', value:'cover'},
              borderStyle: {type:'css', value:'none none solid none'},
              borderColor: {type:'css' , value: '#E4E8E8'},
              borderTopLeftRadius: {type: 'css', value: '10px'},
              borderTopRightRadius: {type: 'css', value: '10px'}
            },
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy',
            properties: {
              padding: {type: 'css', value: '0 2.2rem'},
              margin: {type: 'css', value: '1rem auto'},
              color: {type: 'css', value: '#323232', editable: true},
              fontWeight: {type: 'css', value: 'bold'},
              fontSize: {type: 'css', value: '22px'},
              width: {type: 'css', value: '100%'},
              boxSizing: {type: 'css', value: 'border-box'},
            },
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            properties: {
              padding: {type: 'css', value: '0 2.2rem'},
              margin: {type: 'css', value: '1rem auto'},
              color: {type: 'css', value:'#AAAAAA', editable: true},
              fontWeight: {type: 'css', value: 'normal'},
              width: {type: 'css', value: '100%'},
              lineHeight: {type: 'css', value: '1.6'},
              letterSpacing: {type: 'css', value: '1px'},
              fontSize: {type:'css', value: '14px'},
              boxSizing: {type: 'css', value: 'border-box'},
            },
          }
        ]
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 3},
          medium: {type: 'responsive', value: 9},
          padding: {type: 'css', value: 0},
          margin: {type: 'css', value: '2rem 1rem 2rem 1rem'},
          backgroundColor: {type: 'css', value: '#F7F8F9'},
          borderRadius: {type: 'css', value: '10px'},
          display: {type: 'css', value: 'flex'},
          justifyContent: {type: 'css', value: 'space-between'},
        },
        contents: [
          {
            type: contentTypes.IMAGE,
            properties: {
              src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/medium/field.png', editable:true},
              width: {type: 'css', value:'100%'},
              minHeight: {type: 'css', value:'20rem'},
              height: {type: 'css', value:'20rem'},
              borderStyle: {type:'css', value:'none none solid none'},
              borderColor: {type:'css' , value: '#E4E8E8'},
              objectFit: {type:'css', value:'cover'},
              borderTopLeftRadius: {type: 'css', value: '10px'},
              borderTopRightRadius: {type: 'css', value: '10px'}
            },
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy',
            properties: {
              padding: {type: 'css', value: '0 2.2rem'},
              margin: {type: 'css', value: '1rem auto'},
              color: {type: 'css', value: '#323232', editable: true},
              fontWeight: {type: 'css', value: 'bold'},
              fontSize: {type: 'css', value: '22px'},
              width: {type: 'css', value: '100%'},
              boxSizing: {type: 'css', value: 'border-box'},
            },
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            properties: {
              padding: {type: 'css', value: '0 2.2rem'},
              margin: {type: 'css', value: '1rem auto'},
              color: {type: 'css', value:'#AAAAAA', editable: true},
              fontWeight: {type: 'css', value: 'normal'},
              width: {type: 'css', value: '100%'},
              lineHeight: {type: 'css', value: '1.6'},
              letterSpacing: {type: 'css', value: '1px'},
              fontSize: {type:'css', value: '14px'},
              boxSizing: {type: 'css', value: 'border-box'},
            },
          }
        ]
      },
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 3},
          medium: {type: 'responsive', value: 9},
          padding: {type: 'css', value: 0},
          margin: {type: 'css', value: '2rem 1rem 2rem 1rem'},
          backgroundColor: {type: 'css', value: '#F7F8F9'},
          borderRadius: {type: 'css', value: '10px'},
          display: {type: 'css', value: 'flex'},
          justifyContent: {type: 'css', value: 'space-between'},
        },
        contents: [
          {
            type: contentTypes.IMAGE,
            properties: {
              src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/medium/winter.png', editable:true},
              width: {type: 'css', value:'100%'},
              minHeight: {type: 'css', value:'20rem'},
              height: {type: 'css', value:'20rem'},
              borderStyle: {type:'css', value:'none none solid none'},
              borderColor: {type:'css' , value: '#E4E8E8'},
              objectFit: {type:'css', value:'cover'},
              borderTopLeftRadius: {type: 'css', value: '10px'},
              borderTopRightRadius: {type: 'css', value: '10px'}
            },
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy',
            properties: {
              padding: {type: 'css', value: '0 2.2rem'},
              margin: {type: 'css', value: '1rem auto'},
              color: {type: 'css', value: '#323232', editable: true},
              fontWeight: {type: 'css', value: 'bold'},
              fontSize: {type: 'css', value: '22px'},
              width: {type: 'css', value: '100%'},
              boxSizing: {type: 'css', value: 'border-box'},
            },
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
            properties: {
              padding: {type: 'css', value: '0 2.2rem'},
              margin: {type: 'css', value: '1rem auto'},
              color: {type: 'css', value:'#AAAAAA', editable: true},
              fontWeight: {type: 'css', value: 'normal'},
              width: {type: 'css', value: '100%'},
              lineHeight: {type: 'css', value: '1.6'},
              letterSpacing: {type: 'css', value: '1px'},
              fontSize: {type:'css', value: '14px'},
              boxSizing: {type: 'css', value: 'border-box'},
            },
          }
        ]
      }
    ]
  }
}
