import { CALL_API } from "composer-blocks/lib/middleware/api";
import { getEventUri } from 'composer-blocks/lib/helpers/api';
import { getToken } from "../helpers/auth";
import { getComposerCategory } from '../helpers/api';
import { GALLERY_PAGE_SIZE} from '../constants/api';

const getEventFilesEndpoint = (state) => `events/${getEventUri(state)}/event_file_categories/${getComposerCategory(state)}/event_files?page[size]=${GALLERY_PAGE_SIZE}`;

export const EVENT_FILES_REQUEST = 'EVENT_FILES_REQUEST';
export const EVENT_FILES_SUCCESS = 'EVENT_FILES_SUCCESS';
export const EVENT_FILES_FAILURE = 'EVENT_FILES_FAILURE';
export const fetchEventFiles = (endpoint) => {
  return {
    [CALL_API]: {
      types: [ EVENT_FILES_REQUEST, EVENT_FILES_SUCCESS, EVENT_FILES_FAILURE ],
      endpoint: endpoint || getEventFilesEndpoint,
      options: {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': getToken()
        },
      }
    }
  }
};
