import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleContentList } from '../actions/app';
import { insertContent } from '../actions/contents';
import injectSheet from 'react-jss';
import TranslatableButton from '../components/TranslatableButton';
import { where } from 'composer-blocks/lib/helpers/hasMany';

const styles = {
  button: {
    width: '100%'
  }
};

class ContentList extends Component {

  render() {
    const { allowedContents, toggleContentList, insertContent, classes } = this.props;
    return (
      <div className="ContentList">
        {allowedContents && allowedContents.map(content =>
          <TranslatableButton
            key={content}
            text={content}
            buttonProps={{ className: classes.button }}
            onClick={() => insertContent(content)}
          />
        )}
        <TranslatableButton tPath="buttons.close" onClick={toggleContentList} />
      </div>
    );
  }

}

const mapStateToProps = ({ app, properties }) => ({
  allowedContents: where(properties, 'parentId', app.activeContentId).filter(prop => prop.propertyName === 'allowedContents')[0].value
});

const mapDispatchToProps = {
  toggleContentList,
  insertContent
};

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(ContentList));
