import React, { Component } from 'react';
import injectSheet from 'react-jss';
import ArtTrack from '@material-ui/icons/ArtTrack';
import { translate } from 'react-i18next';
import _ from 'lodash';
import TranslatableIconButton from '../TranslatableIconButton';
import FullScreenModal from '../FullScreenModal';
import DraggableParticipantContainer from '../../containers/DraggableParticipantContainer';


const PARTICIPANT_INFORMATION = 'Participant Information';
const styles = theme => ({
  ParticipantInformationManager: {
    display: 'inline-block',
  },
  iconButton: {
    marginRight: '-0.75rem'
  },
  container: {
    display: 'flex',
    justifyContent:'flex-start',
    height: '100%',
  },
});

class ParticipantInformationManager extends Component {

  //selectedFields state stores an array with the text for each row (line)
  //that will be displayed in each participant card. Each Field is stored
  // as a stringify json. label attribute is the string that is displayed
  // in the droppable text area and the sidebar ( DraggableParticipantSideBar Component)
  // the type says if the field is default or custom and the id is important to get
  // the value of each field for each participant card
  constructor(props) {
    super(props);
    this.state = {
      pendingValidation: true,
      open: false,
      currentTab: PARTICIPANT_INFORMATION,
      inputValue: props.value || {selectedParticipants:{}, selectedFields:{}, displayedValue:{}},
    };
  }

  openModal = () => this.setState({ open: true })
  closeModal = () => this.setState({ open: false })
  setCurrentTab = (tabId) => () => this.setState({ currentTab: tabId })

  onChange = () => {
    this.closeModal();
  }

  handleApplyButton = () => {
    this.props.onChange(this.state.inputValue);
  }

  discardChanges = () => {
    this.setState({
      inputValue: this.props.value || {selectedParticipants:{}, selectedFields:{}, displayedValue:{} },
    });
    this.closeModal();
  }

  getTextAreaValue = (id) => {
    if(!id || !this.state.inputValue.displayedValue[id]) return '';
    return this.state.inputValue.displayedValue[id].join('\n')
  }

  onDragStart = (ev,textValue) => {
    ev.dataTransfer.setData('text/plain',textValue);
  }

  onDragOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
  }

  onDragEnter = (ev) => {
    ev.preventDefault();
  }

  onDrop = (ev,textarea,attendeeTypeId) => {
    if(!attendeeTypeId) return;
    let data = ev.dataTransfer.getData("text/plain");
    let text = `\${${data}}`;
    let field = textarea.current;
    textarea.current.value = field.value.substring(0,field.selectionStart) + text + field.value.substring(field.selectionStart,field.value.length);
    this.setState(oldState => {
      const newValue = _.cloneDeep(oldState.inputValue);
      newValue['displayedValue'][attendeeTypeId] = textarea.current.value.split('\n');
      return {  inputValue: newValue  };
    });
  }

  //changeState = (value, attendeeTypeId, matches) => {
  changeState = (value, attendeeTypeId) => {
    if(!attendeeTypeId) return;
    this.setState( oldState => {
      const newValue = _.cloneDeep(oldState.inputValue);
      newValue['displayedValue'][attendeeTypeId] = value.split('\n');
      //newValue['selectedFields'][attendeeTypeId] = matches;
      return {  inputValue: newValue  };
    });
  }

  // Author: Cristian Ospina
  // Date: 11 / Feb / 19
  changeCheckboxState = (value,participantTypeId)  => {
    //const value = ev.target.checked;
    if(!participantTypeId) return;
    this.setState(oldState => {
      const newValue = _.cloneDeep(oldState.inputValue);
      newValue['selectedParticipants'][participantTypeId] = value;
      return { inputValue: newValue };
    });
  }

  saveMatches = (matches, attendeeTypeIds) => {
    if(!attendeeTypeIds) return;
    this.setState( oldState => {
      const newValue = _.cloneDeep(oldState.inputValue);
      for (let i = 0; i < attendeeTypeIds.length; i++) {
        newValue['selectedFields'][attendeeTypeIds[i]] = matches[attendeeTypeIds[i]];
      }
      return {  inputValue: newValue  };
    },this.handleApplyButton);
  }

  render() {
    const { classes } = this.props;
    const { currentTab, inputValue } = this.state;
    const tabs = {
      [PARTICIPANT_INFORMATION]: (
        <div className={classes.container}>
          <DraggableParticipantContainer
            selectedParticipants={inputValue.selectedParticipants}
            onDragItem={this.onDragStart}
            onDropItem={this.onDrop}
            onCheck={this.changeCheckboxState}
            onDragOver={this.onDragOver}
            onDragEnter={this.onDragEnter}
            changeState={this.changeState}
            textAreaValue={(id) => this.getTextAreaValue(id)}
            onApply={(matches,attendeeTypeIds) => this.saveMatches(matches,attendeeTypeIds)}
            />
        </div>
      ),
    }

    return (
      <div className={classes.ParticipantInformationManager}>
        <TranslatableIconButton tPath="buttons.manage_information" onClick={this.openModal} Icon={ArtTrack} buttonProps={{className: classes.iconButton}} />
        {this.state.open && (
          <FullScreenModal
            tabs={tabs}
            currentTab={currentTab}
            closeModal={this.onChange}
            setCurrentTab={this.setCurrentTab}
          />
        )}
      </div>
    );
  }

}

export default translate('Forms')(injectSheet(styles)(ParticipantInformationManager));
