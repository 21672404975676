import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import injectSheet from 'react-jss';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

const styles = {
  TabDropZone: {
    position: 'relative',
    overflow: 'visible',
    paddingRight: ({ canDrop, hovered, index, otherItemIndex }) => (canDrop && hovered && otherItemIndex < index) ? '1rem' : '0',
    paddingLeft: ({ canDrop, hovered, index, otherItemIndex }) => (canDrop && hovered && otherItemIndex > index) ? '1rem' : '0',
  },
  arrow: {
    position: 'absolute',
    top: 0,
    width: '1rem',
    height: '1rem',
    '&.left': {
      left: 0
    },
    '&.right': {
      right: 0
    }
  }
};

const targetSpec = {
  drop(props, monitor, component) {
    const { index } = props;
    if (!monitor.didDrop()) {
      return { index };
    }
  },
  canDrop(props, monitor) {
    return monitor.getItem().tabIndex !== props.index;
  }
};

const collectDrop = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop(),
    hovered: monitor.isOver(),
    otherItemIndex: monitor.getItem() && monitor.getItem().tabIndex,
    itemType: monitor.getItemType()
  }
};

const propTypes = {
  index: PropTypes.number.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  canDrop: PropTypes.bool.isRequired,
  hovered: PropTypes.bool.isRequired,
  isPlaceholder: PropTypes.bool,
};

class TabDropZone extends Component {

  render() {
    const { hovered, canDrop, connectDropTarget, index, otherItemIndex, classes } = this.props;
    const showPlaceholder = hovered && canDrop;
    const showPlaceholderAfter = showPlaceholder && otherItemIndex !== undefined && otherItemIndex < index;

    return connectDropTarget(
      <div className={classes.TabDropZone}>
        {showPlaceholder && <ArrowDownward color="inherit" className={`${classes.arrow} ${showPlaceholderAfter ? 'right' : 'left'}`} />}
        {this.props.children}
      </div>
    );
  }

}

TabDropZone.propTypes = propTypes;

const droppableTypes = 'page-tab';

const enhance = compose(
  DropTarget(droppableTypes, targetSpec, collectDrop),
  injectSheet(styles)
);

export default enhance(TabDropZone);
