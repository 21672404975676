export const saveToken = (token) => {
  sessionStorage.token = token;
};

export const logOut = () => {
  sessionStorage.removeItem('token');
  window.location = '/';
}

export const loggedIn = () => !!sessionStorage.token;

export const getToken = () => {
  return sessionStorage.token;
};

export const getTokenContent = () => {
  const { token } = sessionStorage;
  const decodedContent = atob(token.split('.')[1]);

  return JSON.parse(decodedContent);
};
