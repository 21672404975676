import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { SketchPicker } from 'react-color';
import BackIcon from '@material-ui/icons/ArrowBack';
import checkers from '../../assets/input/checkers.png';
import cssConstants from '../../constants/css';
import TranslatableButton from '../../components/TranslatableButton';

const transparentRegex = /(?:transparent|rgba\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*,\s*0\s*\))/i;

const styles = theme => ({
  ColorPicker: {
    display: 'inline-block',
    height: '1.5rem',
    width: '1.5rem',
    background: `url(${checkers})`,
    borderRadius: '1rem',
    border: '0.125rem solid white',
    boxSizing: 'border-box',
    position: 'relative'
  },
  colorSample: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    cursor: 'pointer',
    background: ({ value }) => value,
  },
  pickerContainer: {
    position: 'fixed',
    top: cssConstants.navBar.height,
    width: cssConstants.sideBar.width,
    boxSizing: 'border-box',
    bottom: 0,
    left: 0,
    background: theme.palette.primary.dark,
    padding: '2rem',
    zIndex: 1050,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    marginBottom: '1rem',
    alignSelf: 'baseline'
  }
});

class ColorPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  openModal = () => this.setState({ open: true })
  closeModal = () => this.setState({ open: false })

  onChange = (color) => {
    const {r, g, b, a} = color.rgb;
    const wasTransparent = transparentRegex.test(this.props.value);
    this.props.onChange(`rgba(${r}, ${g}, ${b}, ${wasTransparent ? 1 : a})`);
  }

  render() {
    const { classes, value } = this.props;

    return (
      <div className={classes.ColorPicker}>
        <div className={classes.colorSample} onClick={this.openModal}></div>
        {this.state.open && (
          <div className={classes.pickerContainer}>
            <TranslatableButton
              onClick={this.closeModal}
              leftIcon={<BackIcon />}
              tPath={'buttons.back'}
              buttonProps={{ className: classes.button }}
            />
            <SketchPicker
              color={value}
              onClick={(event) => event.stopPropagation}
              onChangeComplete={this.onChange}
            />
          </div>
        )}
      </div>
    );
  }

}

export default injectSheet(styles)(ColorPicker);
