import { CUSTOM } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'custom_10',
  type: CUSTOM,
  name: 'Custom with one modal column',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/custom/custom_10.png',
  rootContent: {
    type: contentTypes.ROW,
    properties: {
      backgroundImage: {type: 'css', value: 'url(https://eventtia-composer.s3.amazonaws.com/assets/images/large/background_speaker.png)', editable:true},
      backgroundRepeat: {type: 'css', value: 'no-repeat'},
      backgroundSize: {type: 'css', value:'cover'},
      padding: {type: 'css', value: '0'}
    },
    contents: [
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 8},
          medium: {type: 'responsive', value: 10},
          offsetLarge: {type: 'css', value: 2, editable: true},
          offsetMedium: {type: 'css', value: 1, editable: true},
          small: {type: 'responsive', value: 12},
          marginTop: {type:'css', value: '8rem'},
          marginBottom: {type:'css', value: '8rem'},
          minHeight: {type: 'css', value: '30rem'},
          padding:{type: 'css', value: 0},

        },
        contents: [
          {
            type: contentTypes.ROW,
            properties: {
              padding: {type: 'css', value: 0},
              margin: {type: 'css', value: 0}
            },
            contents: [
              {
                type: contentTypes.COLUMN,
                properties: {
                  large: {type: 'responsive', value: 5},
                  medium: {type: 'responsive', value: 8},
                  small: {type: 'responsive', value: 12},
                  backgroundImage: {type: 'css', value: 'url(https://eventtia-composer.s3.amazonaws.com/assets/images/medium/meeting.png)', editable:true},
                  backgroundRepeat: {type: 'css', value: 'no-repeat'},
                  backgroundSize: {type: 'css', value:'cover'},
                  minHeight: {type: 'css', value: '30rem'},
                  padding: {type: 'css', value: 0},
                  margin: {type: 'css', value: 0}
                }
              },
              {
                type: contentTypes.COLUMN,
                properties: {
                  large: {type: 'responsive', value: 7},
                  medium: {type: 'responsive', value: 8},
                  small: {type: 'responsive', value: 12},
                  backgroundColor: {type: 'css', value: 'white'},
                  minHeight: {type: 'css', value: '30rem'},
                  margin: {type: 'css', value: '0'}
                },
                contents: [
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem Ipsum is simply dummy',
                    properties: {
                      width: {type:'css', value: '90%'},
                      margin: {type: 'css', value: '1rem 4rem 2rem 1rem'},
                      color: {type: 'css', value: 'black', editable: true},
                      fontWeight: {type: 'css', value: 'bold'},
                      padding: {type: 'css', value: '1rem 0 1rem 0'},
                      fontSize: {type: 'css', value: '26px'},
                      borderStyle: {type:'css', value:'none none solid none'},
                      borderColor: {type:'css' , value: '#57E2C2'}
                    }

                  },
                  {
                    type: contentTypes.TEXT,
                    value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat..',
                    properties: {
                      width: {type:'css', value: '90%'},
                      margin: {type: 'css', value: '1rem 4rem 2rem 1rem'},
                      color: {type: 'css', value: 'black', editable: true},
                      fontWeight: {type: 'css', value: 'normal'},
                      padding: {type: 'css', value: '1rem 0 2rem 0'},
                      fontSize: {type: 'css', value: '20px'},
                    }

                  },
                  {
                    type: contentTypes.ROW,
                    properties: {
                      padding: {type: 'css', value: 0},
                      margin: {type: 'css', value: 0},
                      display: {type: 'css', value: 'flex'},
                      justifyContent:{type: 'css', value: 'center'}
                    },
                    contents: [
                      {
                        type: contentTypes.COLUMN,
                        properties: {
                          large: {type: 'responsive', value: 3},
                          medium: {type: 'responsive', value: 8},
                          small: {type: 'responsive', value: 8},
                          offsetMedium: {type: 'responsive', value: 2,editable:true},
                          offsetSmall: {type: 'responsive', value: 2,editable:true}
                        },
                        contents: [
                          {
                            type: contentTypes.IMAGE,
                            properties: {
                              src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/medium/people.png', editable:true},
                              objectFit: {type: 'css', value: 'cover'},
                              width: {type: 'css', value:'8rem'},
                              height: {type: 'css', value:'8rem'},
                              borderRadius: {type: 'css', value:'150px'}
                            },
                      }
                        ]
                      },
                      {
                        type: contentTypes.COLUMN,
                        properties: {
                          large: {type: 'responsive', value: 3},
                          medium: {type: 'responsive', value: 8},
                          small: {type: 'responsive', value: 8},
                          offsetMedium: {type: 'responsive', value: 2,editable:true},
                          offsetSmall: {type: 'responsive', value: 2,editable:true}
                        },
                        contents: [
                          {
                            type: contentTypes.IMAGE,
                            properties: {
                              src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/medium/office_man.png', editable:true},
                              objectFit: {type: 'css', value: 'cover'},
                              width: {type: 'css', value:'8rem'},
                              height: {type: 'css', value:'8rem'},
                              borderRadius: {type: 'css', value:'150px'}
                            },
                      }
                        ]
                      },
                      {
                        type: contentTypes.COLUMN,
                        properties: {
                          large: {type: 'responsive', value: 3},
                          medium: {type: 'responsive', value: 8},
                          small: {type: 'responsive', value: 8},
                          offsetMedium: {type: 'responsive', value: 2,editable:true},
                          offsetSmall: {type: 'responsive', value: 2,editable:true}
                        },
                        contents: [
                          {
                            type: contentTypes.IMAGE,
                            properties: {
                              src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/medium/idea.png', editable:true},
                              objectFit: {type: 'css', value: 'cover'},
                              width: {type: 'css', value:'8rem'},
                              height: {type: 'css', value:'8rem'},
                              borderRadius: {type: 'css', value:'150px'}
                            },
                          }
                        ]
                      }
                    ]
                  }

                ]
              }
            ]


          }

        ]
      }
    ]
  }
}
