import generateId from '../helpers/ids';
import BlockTemplates from '../constants/BlockTemplates';
import { NAVBAR, FOOTER } from 'composer-blocks/lib/constants/BlockTypes';
import { recursivelyBuildContents, recursivelyGetChildren, buildTemplateFromContent } from './contents';
import { setActiveContent } from "./app";

const isNavbar = (templateId) => (BlockTemplates[templateId].type === NAVBAR);
const isFooter = (templateId) => (BlockTemplates[templateId].type === FOOTER);

const buildBlock = (blockTemplate, page, state) => {
  const id = generateId();

  const contents = {};
  const properties = {};
  const rootContentId = recursivelyBuildContents(blockTemplate.rootContent, id, contents, properties, state);

  const block = {
    page,
    id,
    templateId: blockTemplate.id,
    type: blockTemplate.type,
    rootContentId
  };

  return {
    block,
    properties,
    contents,
  }
};

const buildTemplateFromBlock = (blockId, state) => {
  const { blocks } = state;
  const block = blocks[blockId];
  return {
    id: block.templateId,
    type: block.type,
    rootContent: buildTemplateFromContent(block.rootContentId, state)
  }
}

export const INSERT_BLOCK = 'INSERT_BLOCK';
export const INSERT_NAVBAR = 'INSERT_NAVBAR';
export const INSERT_FOOTER = 'INSERT_FOOTER';
export const insertBlock = (templateId, index) => {
  return function(dispatch, getState) {
    const state = getState();
    const page = state.site.currentPageId;
    const blockTemplate = BlockTemplates[templateId];

    dispatch({
      type: isNavbar(templateId) ? INSERT_NAVBAR : (isFooter(templateId) ? INSERT_FOOTER : INSERT_BLOCK),
      index,
      ...buildBlock(blockTemplate, page, state)
    });
  }
};

export const duplicateBlock = (options) => {
  return function(dispatch, getState) {
    const state = getState();
    let { blockId, page, index } = options || {};
    const activeContent = state.contents[state.app.activeContentId];
    if (!blockId) blockId = activeContent.parentId;
    if (!page) page = state.site.currentPageId;
    if (!index) index = state.pages[page].blocks.indexOf(blockId) + 1;
    const blockToClone = state.blocks[blockId];
    const template = buildTemplateFromBlock(blockToClone.id, state);

    dispatch({
      type: isNavbar(blockToClone.templateId) ? INSERT_NAVBAR : (isFooter(blockToClone.templateId) ? INSERT_FOOTER : INSERT_BLOCK),
      index,
      ...buildBlock(template, page, state)
    });
  }
}

export const COPY_BLOCK_TO_CLIPBOARD = 'COPY_BLOCK_TO_CLIPBOARD';
export const copyBlockToClipboard = (contentId) => {
  return function(dispatch, getState) {
    const state = getState();
    if (!contentId) contentId = state.app.activeContentId;
    const template = buildTemplateFromBlock(state.contents[contentId].parentId, state);
    return dispatch({
      type: COPY_BLOCK_TO_CLIPBOARD,
      template
    });
  }
}

export const pasteBlock = (options) => {
  return function(dispatch, getState) {
    const state = getState();
    let { page, index } = options || {};
    if (!page) page = state.site.currentPageId;
    if (!index) index = !!state.pages[page].blocks ? state.pages[page].blocks.length : 0;
    const { template } = state.app.clipboard;

    dispatch({
      type: isNavbar(template.id) ? INSERT_NAVBAR : (isFooter(template.id) ? INSERT_FOOTER : INSERT_BLOCK),
      index,
      ...buildBlock(template, page, state)
    });
  }
}

export const MOVE_BLOCK = 'MOVE_BLOCK';
export const moveBlock = (id, newIndex) => {
  return function(dispatch, getState) {
    const page = getState().site.currentPageId;

    dispatch({
      type: MOVE_BLOCK,
      page,
      newIndex,
      id
    });
  }
};

export const DELETE_BLOCK = 'DELETE_BLOCK';
export const DELETE_NAVBAR = 'DELETE_NAVBAR';
export const DELETE_FOOTER = 'DELETE_FOOTER';
export const deleteBlock = (id) => {
  return function(dispatch, getState) {
    const page = getState().site.currentPageId;
    const { activeContentId } = getState().app;
    const { contents, properties, blocks } = getState();
    const childContents = [];
    const childProperties = [];
    const templateId = blocks[id].templateId;

    recursivelyGetChildren(id, contents, properties, childContents, childProperties);

    dispatch({
      type: isNavbar(templateId) ? DELETE_NAVBAR : (isFooter(templateId) ? DELETE_FOOTER : DELETE_BLOCK),
      id,
      childContents,
      childProperties,
      page
    });

    if (activeContentId && (activeContentId === id || childContents.includes(activeContentId))) dispatch(setActiveContent(null));
  }
};
