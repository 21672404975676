import { NAVBAR } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'navbar_26',
  type: NAVBAR,
  name: 'Navbar 26',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/navbar/navbar_26.png',
  rootContent: {
    type: contentTypes.NAVBAR,
    properties: {
      backgroundColor: {type: 'css', value: 'white',editable: true},
      color: {type: 'css', value: 'black', editable: true},
      padding: {type: 'css', value: '0.5rem 1rem'},
      fontSize: {type: 'css', value: 'small', editable: true},
      logoSrc: {type: 'custom', value: '', editable: true},
      logoHeight: {type: 'custom', value: '3.5rem'},
      navItemPadding: {type: 'custom', value: '0.5rem'},
      navItemMargin: {type: 'custom', value: '0rem 0.5rem'},
      navItemActiveBorderThickness: {type: 'custom', value:'10px', editable: true },
      navItemActiveTextColor: {type: 'custom', value: '#F75457'},
      navItemActiveOverline: {type: 'custom', value: '#F75457'},
      fontWeight: {type: 'css', value: 'Bold'},
      navListItemSeparator: {type: 'custom', value: 'true'},
      socialMedia: {type: 'custom', value: true, editable: true},
      facebookLink: {type: 'custom', value: 'facebook.com', editable: true},
      twitterLink: {type: 'custom', value: 'twitter.com', editable: true},
      linkedInLink: {type: 'custom', value: 'linkedin.com', editable: true},
      socialMediaIconColor: {type: 'custom', value: '#F75457', editable: true},
    },
    contents: []
  }
}
