import { CUSTOM } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'custom_19',
  type: CUSTOM,
  name: 'Custom block with video',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/custom/custom_19.jpg',
  rootContent: {
    type: contentTypes.VIDEO_PLAYER,
    properties: {
      backgroundColor: {type: 'css', value: '#fff', editable: true},
    },
  }
}
