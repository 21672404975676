import { MAPS } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'maps_1',
  type: MAPS,
  name: 'Map 1',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/maps/maps_1.png',
  rootContent: {
    type: contentTypes.MAP,
    properties: {},
    contents: []
  }
}
