import React, { Component } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Done from '@material-ui/icons/Done';
import Delete from '@material-ui/icons/Delete';
import Paste from '@material-ui/icons/AssignmentReturned'; // There is no paste icon in the package
import Duplicate from '@material-ui/icons/Filter2'; // There is no duplicate icon in the package
import { compose } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import injectSheet from 'react-jss';
import { makeFriendlyUrl, findPageByName } from 'composer-blocks/lib/helpers/pages';
import { openModal, setActivePage } from '../actions/app';
import { pasteBlock } from '../actions/blocks';
import { setPageName, setPageFriendlyUrl, setPageHideNavbar, setPageHideFromNavbar, setPageHideFooter, setPageHideFromFooter, deletePage, duplicatePage } from '../actions/pages';
import TranslatableButton from '../components/TranslatableButton';
import TranslatableIconButton from '../components/TranslatableIconButton';

const styles = {
  inputContainer: {
    width: '100%',
    marginBottom: '1rem',
  },
  input: {
    flex: 1,
  },
}

class PageEditor extends Component {
  constructor(props) {
    super(props);

    const { activePageId, pages } = this.props;
    const page = pages[activePageId];

    this.state = {
      name: page.name,
      friendlyUrl: page.friendlyUrl || ''
    }
  }

  componentWillReceiveProps(nextProps) {
    const old_page_id = this.props.activePageId;
    const new_page_id = nextProps.activePageId;

    if (old_page_id !== new_page_id) {
      this.setState({
        name: this.props.pages[new_page_id].name,
        friendlyUrl: this.props.pages[new_page_id].friendlyUrl || '',
      })
    }
  }

  isInvalid = (term) => {
    const { activePageId, pages } = this.props;
    const foundPage = findPageByName(term, pages);
    return foundPage !== activePageId && foundPage;
  }

  hasNameChanged = () => {
    const { activePageId, pages } = this.props;
    const page = pages[activePageId];
    return this.state.name !== page.name;
  }

  hasUrlChanged = () => {
    const { activePageId, pages } = this.props;
    const page = pages[activePageId];
    return (page.friendlyUrl || this.state.friendlyUrl) && (page.friendlyUrl !== this.state.friendlyUrl);
  }

  setValue = (key) => (event) => {
    this.setState({
      [key]: event.target.value
    });
  };

  setPageName = () => {
    this.props.setPageName(this.state.name);
  };

  setPageFriendlyUrl = () => {
    this.props.setPageFriendlyUrl(this.state.friendlyUrl);
  };

  setPageHideNavbar = (event, checked) => {
    this.props.setPageHideNavbar(checked);
  };

  setPageHideFromNavbar = (event, checked) => {
    this.props.setPageHideFromNavbar(checked);
  };

  setPageHideFooter = (event, checked) => {
    this.props.setPageHideFooter(checked);
  };

  setPageHideFromFooter = (event, checked) => {
    this.props.setPageHideFromFooter(checked);
  };

  render() {
    const { t, classes, activePageId, deletePage, duplicatePage, canPaste, pasteBlock, closeEditor, pages } = this.props;
    const page = pages[activePageId];
    const withSameName = this.isInvalid(this.state.name) || this.isInvalid(makeFriendlyUrl(this.state.name));
    const withSameFriendlyUrl = this.isInvalid(this.state.friendlyUrl);
    const urlContainsInvalidCharacters = this.state.friendlyUrl !== makeFriendlyUrl(this.state.friendlyUrl);

    return (
      <div>
        <FormGroup row className={classes.inputContainer}>
          <TextField
            label="Page name"
            error={!!withSameName || !this.state.name}
            helperText={!this.state.name ?
              'Page name can\'t be empty' :
              withSameName ?
                `Page "${pages[withSameName].name}" has the same name or URL` :
                ''
            }
            value={this.state.name}
            onChange={this.setValue('name')}
            className={classes.input}
          />
          {!withSameName && this.hasNameChanged() && (
            <TranslatableIconButton text="Change Name" onClick={this.setPageName} Icon={Done} />
          )}
        </FormGroup>
        <FormGroup row className={classes.inputContainer}>
          <TextField
            label="Friendly URL"
            InputLabelProps={{
              shrink: true
            }}
            error={!!withSameFriendlyUrl || !!urlContainsInvalidCharacters}
            helperText={urlContainsInvalidCharacters ?
              'The URL contains invalid characters' :
              withSameFriendlyUrl ?
                `Page "${pages[withSameFriendlyUrl].name}" has the same name or URL` :
                ''
            }
            placeholder={makeFriendlyUrl(this.state.name)}
            value={this.state.friendlyUrl}
            onChange={this.setValue('friendlyUrl')}
            className={classes.input}
          />
          {!(withSameFriendlyUrl || urlContainsInvalidCharacters) && this.hasUrlChanged() && (
            <TranslatableIconButton tPath="buttons.change_friendly_url" onClick={this.setPageFriendlyUrl} Icon={Done} />
          )}
        </FormGroup>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={page.hideNavbar || false}
                onChange={this.setPageHideNavbar}
              />
            }
            label={t('labels.hide_navbar')}
          />
        </FormGroup>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={page.hideFromNavbar || false}
                onChange={this.setPageHideFromNavbar}
              />
            }
            label={t('labels.hide_from_navbar')}
          />
        </FormGroup>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={page.hideFooter || false}
                onChange={this.setPageHideFooter}
              />
            }
            label={t('labels.hide_footer')}
          />
        </FormGroup>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={page.hideFromFooter || false}
                onChange={this.setPageHideFromFooter}
              />
            }
            label={t('labels.hide_from_footer')}
          />
        </FormGroup>
        <TranslatableButton
          onClick={duplicatePage}
          tPath="buttons.duplicate_page"
          buttonProps={{ fullWidth: true }}
          leftIcon={<Duplicate />}
        />
        {canPaste && (
          <TranslatableButton
            onClick={pasteBlock}
            tPath="buttons.paste_block"
            buttonProps={{ fullWidth: true }}
            leftIcon={<Paste />}
          />
        )}
        <TranslatableButton
          onClick={closeEditor}
          tPath="buttons.close"
        />
        <TranslatableButton
          onClick={deletePage}
          tPath="buttons.delete_page"
          icon={<Delete />}
          buttonProps={{
            color: 'secondary',
          }}
        />
      </div>
    );
  }
}

const canPaste = (clipboard) => {
  const { templateType } = clipboard;
  return !!templateType && templateType === 'block';
}

const mapStateToProps = ({ app, pages }) => ({
  activePageId: app.activePageId,
  canPaste: canPaste(app.clipboard),
  pages
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  deletePage: () => {
    dispatch(openModal(
      'Delete page',
      'Are you sure you want to delete this page?',
      deletePage(),
      null
    ));
  },
  setPageName: (value) => {
    dispatch(setPageName(value));
  },
  duplicatePage: () => {
    dispatch(duplicatePage());
  },
  pasteBlock: () => {
    dispatch(pasteBlock());
  },
  setPageFriendlyUrl: (value) => {
    dispatch(setPageFriendlyUrl(value));
  },
  setPageHideNavbar: (value) => {
    dispatch(setPageHideNavbar(value));
  },
  setPageHideFromNavbar: (value) => {
    dispatch(setPageHideFromNavbar(value));
  },
  setPageHideFooter: (value) => {
    dispatch(setPageHideFooter(value));
  },
  setPageHideFromFooter: (value) => {
    dispatch(setPageHideFromFooter(value));
  },
  closeEditor: () => {
    dispatch(setActivePage(null));
  },
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectSheet(styles),
  translate('Forms')
);

export default enhance(PageEditor);
