import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';
import injectSheet from 'react-jss';
import { insertBlock } from '../actions/blocks';
import BlockTemplates from '../constants/BlockTemplates';
import { NAVBAR, FOOTER } from 'composer-blocks/lib/constants/BlockTypes';

const styles = {
  thumbnail: {
    margin: '1rem',
  },
  thumbnailImg: {
    maxWidth: '100%',
    display: 'block',
  }
};

const sourceSpec = {
  beginDrag(props) {
    return {
      blockTemplateId: props.blockTemplateId,
    }
  },
  endDrag(props, monitor) {
    const result = monitor.getDropResult()
    if (monitor.didDrop()) {
      props.insertBlock(props.blockTemplateId, result.index)
    }
  }
};

const collectDrag = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }
};

const propTypes = {
  blockTemplateId: PropTypes.string.isRequired,
  // Injected by React DnD:
  isDragging: PropTypes.bool.isRequired,
  connectDragSource: PropTypes.func.isRequired,
};

class DraggableThumbnail extends Component {

  render() {
    const { connectDragSource, blockTemplateId, classes } = this.props;
    const { thumbnail, name } = BlockTemplates[blockTemplateId];
    const options = {
      dropEffect: 'copy'
    }
    return connectDragSource((
      <div className={classes.thumbnail}>
        <img className={classes.thumbnailImg} src={thumbnail} alt={name} />
      </div>
    ), options);
  }
}

DraggableThumbnail.propTypes = propTypes;

const mapDispatchToProps = {
  insertBlock
};

const getSourceType = ({ blockTemplateId }) => (
  (BlockTemplates[blockTemplateId].type === NAVBAR) ? 'navbar-thumbnail' : (BlockTemplates[blockTemplateId].type === FOOTER ? 'footer-thumbnail' : 'thumbnail')
);

const enhance = compose(
  connect(null, mapDispatchToProps),
  DragSource(getSourceType, sourceSpec, collectDrag),
  injectSheet(styles)
);

export default enhance(DraggableThumbnail);
