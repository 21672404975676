import { EXHIBITORS } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'exhibitors_0',
  type: EXHIBITORS,
  name: 'Exhibitor grid',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/exhibitors/exhibitors_0.png',
  rootContent: {
    type: contentTypes.EXHIBITORS_LOGOS,
    properties: {
      itemsPerRowDesktop: {type: 'custom', value: 3, editable: true},
      itemsPerRowTablet: {type: 'custom', value: 6, editable: true},
      itemsPerRowMobile: {type: 'custom', value: 12, editable: true},

    },
    contents: []
  }
}
