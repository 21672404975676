import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

class Select extends Component {
  onChange = (event) => {
    const { value } = event.target;
    this.props.onChange(value);
  }

  render() {
    const { value, options } = this.props;

    return (
      <TextField
        select
        value={value}
        onChange={this.onChange}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    );
  }

}

export default Select;
