import { connect } from 'react-redux';
import Modal from '../components/Modal';
import { closeModal } from "../actions/app";

const mapDispatchToProps = (dispatch, ownProps) => {
  const { accept, cancel } = ownProps.actions;

  return {
    onAccept: () => {
      if (accept) dispatch(accept);
      dispatch(closeModal());
    },
    onCancel: () => {
      if (cancel) dispatch(cancel);
      dispatch(closeModal());
    }
  }
};

export default connect(null, mapDispatchToProps)(Modal);
