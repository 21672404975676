import { FOOTER } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'footer_2',
  type: FOOTER,
  name: 'Footer 2',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/footer/footer_2.jpg',
  rootContent: {
    type: contentTypes.FOOTER_INLINE,
    properties: {
      minHeight: {type: 'css', value: '6.5rem', editable: false},
      backgroundColor: {type: 'css', value: '#dedede',editable: true},
      color: {type: 'css', value: '#313131', editable: true},
      padding: {type: 'css', value: '0.5rem 0rem'},
      fontSize: {type: 'css', value: '14px'},
      fontWeight: {type: 'css', value: 'bold'},
      logoSrc: {type: 'custom', value: '', editable: true},
      logoHeight: {type: 'custom', value: '1.2rem'},
      socialMedia: {type: 'custom', value: true, editable: true},
      hideCopyrightMsg: {type: 'custom', value: false, editable: true},
      facebookLink: {type: 'custom', value: 'facebook.com', editable: true},
      socialMediaIconColor: {type: 'custom', value: '#313131', editable: true},
      socialMediaIconSize: {type: 'custom', value: '1.5rem', editable: true},
    },
    contents: []
  }
}
