import { COOKIE_CONSENT } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'cookie_consent_1',
  type: COOKIE_CONSENT,
  name: 'Cookie Consent 1',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/cookie_consent/cookie_consent_1.jpg',
  rootContent: {
    type: contentTypes.COOKIE_CONSENT_SMALL,
    properties: {
      backgroundColor: {type: 'css', value: '#fff', editable: true},
      color: {type: 'css', value: '#333', editable: true},
      buttonColor: {type: 'custom', value: '#447', editable: true},
      buttonTextColor: {type: 'custom', value: '#fff', editable: true},
      principalText: {type: 'custom', value: '', editable: true},
      privacyPolicyUrl: {type: 'custom', value: '', editable: true},
      buttonLabel: {type: 'custom', value: '', editable: true},
      secondaryButtonLabel: {type: 'custom', value: '', editable: true},
    },
    contents: []
  }
}
