import React, { Component } from 'react';
import injectSheet from 'react-jss';
import Link from '@material-ui/icons/Link';
import Done from '@material-ui/icons/Done';
import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { translate } from 'react-i18next';
import _ from 'lodash';
import TranslatableIconButton from '../TranslatableIconButton';
import TranslatableButton from '../TranslatableButton';
import FullScreenModal from '../FullScreenModal';

const LINKS = 'Links';

const styles = theme => ({
  LinksManager: {
    display: 'inline-block',
  },
  uploaderContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  customUrlContainer: {
    textAlign: 'center',
  },
  iconButton: {
    marginRight: '-0.75rem'
  },
  container: {
    margin: 'auto',
  },
  inputGroup: {
    padding: '1rem 0',
    display: 'flex',
  },
  inputRoot: {
    width: '25em',
    marginRight: '2rem',
  }
});

class LinksManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      currentTab: LINKS,
      inputValue: props.value || [{text: '', link: '', newTab: true}]
    };
  }

  openModal = () => this.setState({ open: true })
  closeModal = () => this.setState({ open: false })
  setCurrentTab = (tabId) => () => this.setState({ currentTab: tabId })
  setCustomUrl = () => {
    this.onChange(this.state.inputValue);
  }

  onChange = (url) => {
    this.props.onChange(this.state.inputValue);
    this.closeModal();
  }

  discardChanges = (url) => {
    this.setState({
      inputValue: this.props.value || [{text: '', link: '', newTab: true}]
    });
    this.closeModal();
  }

  onChangeInput = (event) => this.setState({ inputValue: event.target.value })

  addLink = () => {
    this.setState(oldState => {
      const newValue = _.cloneDeep(oldState.inputValue);
      newValue.push({text: '', link: '', newTab: true});
      return { inputValue: newValue };
    });
  }

  deleteLink = (index) => () => {
    this.setState(oldState => {
      const newValue = _.cloneDeep(oldState.inputValue);
      newValue.splice(index, 1);
      return { inputValue: newValue };
    });
  }

  changeState = (index, key) => (event) => {
    const value = event.target.value;
    this.setState(oldState => {
      const newValue = _.cloneDeep(oldState.inputValue);
      newValue[index][key] = value;
      return { inputValue: newValue };
    });
  }

  changeCheckboxState = (index, key) => (event) => {
    const value = event.target.checked;
    this.setState(oldState => {
      const newValue = _.cloneDeep(oldState.inputValue);
      newValue[index][key] = value;
      return { inputValue: newValue };
    });
  }

  render() {
    const { classes, t } = this.props;
    const { currentTab, inputValue } = this.state;

    const tabs = {
      [LINKS]: (
        <div className={classes.container}>
          {inputValue.map((item, index) => (
            <div className={classes.inputGroup} key={index}>
              <TextField
                label={t('labels.text')}
                onChange={this.changeState(index, 'text')}
                classes={{root: classes.inputRoot}}
                value={item.text}
              />
              <TextField
                label="URL"
                onChange={this.changeState(index, 'link')}
                classes={{root: classes.inputRoot}}
                value={item.link}
                multiline
              />
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={this.changeCheckboxState(index, 'newTab')}
                      classes={{root: classes.root}}
                      checked={item.newTab}
                    />
                  }
                  label={t('labels.open_in_new_tab')}
                />
              </FormGroup>
              <TranslatableIconButton
                tPath="buttons.delete"
                onClick={this.deleteLink(index)}
                Icon={Close}
              />
            </div>
          ))}
          <TranslatableButton
            onClick={this.addLink}
            leftIcon={<Add />}
            tPath="buttons.add_link"
          />
          <TranslatableButton
            onClick={this.onChange}
            leftIcon={<Done />}
            tPath="buttons.apply_changes"
          />
          <TranslatableButton
            onClick={this.discardChanges}
            leftIcon={<Close />}
            tPath="buttons.discard_changes"
          />
        </div>
      ),
    }

    return (
      <div className={classes.LinksManager}>
        <TranslatableIconButton tPath="buttons.manage_links" onClick={this.openModal} Icon={Link} buttonProps={{className: classes.iconButton}} />
        {this.state.open && (
          <FullScreenModal
            tabs={tabs}
            currentTab={currentTab}
            closeModal={this.closeModal}
            setCurrentTab={this.setCurrentTab}
          />
        )}
      </div>
    );
  }

}

export default translate('Forms')(injectSheet(styles)(LinksManager));
