import { B2B_MATCHMAKING } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'b2b_matchmaking_1',
  type: B2B_MATCHMAKING,
  name: 'B2B Matchmaking',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/b2b/b2b_matchmaking_1.jpg',
  rootContent: {
    type: contentTypes.B2B_MATCHMAKING_GRID,
    properties: {
      backgroundColor: {type: 'custom', value: '#d4d4d4' , editable:true},
      borderColor: {type: 'custom', value: '#fff' , editable:true},
      itemBackgroundColor: {type: 'custom', value: '#fff' , editable:true},
      itemOuterShadow: {type: 'custom', value: '#bdbdbd' , editable:true},
      secondaryBackgroundColor: {type: 'custom', value: '#fff' , editable:true},
      color: {type: 'custom', value: '#333' , editable:true},
      fontSize: {type:'custom', value:'14px', editable:true},
      participantsPerRowDesktop: {type: 'custom', value: 4, editable: true},
      participantsPerRowTablet: {type: 'custom', value: 2, editable: true},
      participantsPerRowMobile: {type: 'custom', value: 1, editable: true},
      participantInformation: {type: 'custom', value: '', editable: true},
    },
    contents: []
  }
}
