import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setModifiedProperty, setActiveContent, toggleContentList, openModal, restoreContent } from '../actions/app';
import { normalizedWhere } from 'composer-blocks/lib/helpers/hasMany';
import PropertyInput from '../components/PropertyInput';
import TranslatableButton from '../components/TranslatableButton';
import { translate } from 'react-i18next';
import injectSheet from 'react-jss';

const styles = {
  Properties: {
  },
  button: {
    width: '50%'
  }
};

class Properties extends Component {

  render() {
    const { t, classes, properties, onPropertyChange, toggleContentList, closeProperties, restoreContentProperties, isContentModified } = this.props;

    return (
      <div className={classes.Properties}>
        {Object.keys(properties).map(k => properties[k]).map((property) => {
          return property.editable && (
            <PropertyInput
              key={property.id}
              id={property.id}
              propertyName={property.propertyName}
              value={property.value}
              onChange={onPropertyChange}
              toggleContentList={toggleContentList}
            />
          );
        })}

        <TranslatableButton
          onClick={closeProperties}
          tPath="buttons.close"
          buttonProps={{ className: classes.button }}
        />
        <TranslatableButton
          onClick={restoreContentProperties}
          tPath="buttons.restore"
          buttonProps={{
            disabled: !isContentModified,
            className: classes.button
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ app, properties }) => {
  const { activeContentId, modifiedItems } = app;
  const { contents, properties: allModifiedProperties } = modifiedItems;
  const modifiedProperties = normalizedWhere(allModifiedProperties, 'parentId', activeContentId);
  const isContentModified = !!contents[activeContentId] || Object.keys(modifiedProperties).length > 0;

  return {
    properties: {
      ...normalizedWhere(properties, 'parentId', activeContentId),
      ...modifiedProperties
    },
    isContentModified
  };
};

const mapDispatchToProps = (dispatch) => ({
  onPropertyChange: (propertyId, value) => {
    dispatch(setModifiedProperty(propertyId, value));
  },
  closeProperties: () => {
    dispatch(setActiveContent(null));
  },
  restoreContentProperties: () => {
    dispatch(openModal(
      'Restore Content Properties',
      'Are you sure you want to restore this content? All changes will be lost.',
      restoreContent(),
      null
    ));
  },
  toggleContentList: () => {
    dispatch(toggleContentList())
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(translate('Forms')(injectSheet(styles)(Properties)));
