import { HEADERS } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'header_4',
  type: HEADERS,
  name: 'Header Coffee Table',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/header/header_4.png',
  rootContent: {
    type: contentTypes.ROW,
    properties: {
      backgroundImage: {type: 'css', value: 'url(https://eventtia-composer.s3.amazonaws.com/assets/images/large/people_coffee.png)', editable: true},
      backgroundSize: {type: 'css', value: 'cover'},
      padding: {type: 'css', value: '2rem 0'},
      minHeight: {type: 'css', value: '500px'}
    },
    contents: [
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 8, editable: true },
        },
        contents: [
          {
            type: contentTypes.EVENT_NAME,
            properties: {
              margin: {type: 'css', value: '4rem 2rem 0 2rem'},
              fontWeight: {type: 'css', value: 'bold'},
              textAlign: {type: 'css', value: 'center'},
              justifySelf:{type: 'css', value: 'end'},
              color: {type: 'css', value: '#ffffff'},
              fontFamily: {type: 'css', value: 'inherit'},
              overflowWrap: {type: 'css', value: 'break-word'},
            },
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            properties: {margin: {type: 'css', value: '0 2rem'},
              textAlign: {type: 'css', value: 'center'},
              color: {type: 'css', value: '#ffffff'},
              fontFamily: {type: 'css', value: 'inherit'},
              fontSize: {type: 'css', value: '0.8rem'},
              marginTop: {type: 'css', value: '2rem'},
            },
          },




          {
            type: contentTypes.COUNTDOWN,
            properties: {
              margin: {type: 'css', value: '2rem 2rem 0 2rem'},
              color: {type: 'css', value: '#ffffff'},
              fontFamily: {type: 'css', value: 'inherit'},
              textAlign: {type: 'css', value: 'center'},
              fontSize: {type: 'css', value: '0.83rem'}
            },
          },

          {
            type: contentTypes.EVENT_ADDRESS,
            properties: {margin: {type: 'css', value: '0 2rem'},
              textAlign: {type: 'css', value: 'center'},
              color: {type: 'css', value: '#ffffff'},
              fontFamily: {type: 'css', value: 'inherit'},
              fontWeight: {type: 'css', value: 'Bold'},
              marginTop: {type: 'css', value: '2rem'},
            },
          },
          {
            type: contentTypes.ROW,
            properties: {
              justifyContent: {type: 'css', value: 'center'}
            },
            contents: [


              {
                type: contentTypes.BUTTON,
                value: 'Register',
                properties: {
                  color: {type: 'css', value: '#ffffff', editable: true},
                  borderColor: {type: 'css', value:'#ffffff', editable: true},
                  borderRadius: {type: 'css', value:'2px', editable: true},
                  backgroundColor: {type: 'css', value:'rgba(0,0,0,0)', editable: true},
                  margin: {type: 'css', value: '1rem 2rem'},
                  alignSelf: {type: 'css', value: 'center', editable: true},
                  href: {type: 'custom', value: 'javascript:alert()', editable: true},
                  minWidth: {type: 'css', value: '150px'},
                  padding: {type: 'css', value:'10px 50px'},
                }
              },

              {
                type: contentTypes.BUTTON,
                value: 'Login',
                properties: {
                  color: {type: 'css', value: '#ffffff', editable: true},
                  borderColor: {type: 'css', value:'#ffffff', editable: true},
                  borderRadius: {type: 'css', value:'2px', editable: true},
                  backgroundColor: {type: 'css', value:'rgba(0,0,0,0)', editable: true},
                  margin: {type: 'css', value: '1rem 2rem'},
                  alignSelf: {type: 'css', value: 'center', editable: true},
                  href: {type: 'custom', value: 'javascript:alert()', editable: true},
                  minWidth: {type: 'css', value: '150px'},
                  padding: {type: 'css', value:'10px 50px'},
                }
              },
            ],
          }
        ]
      },
    ]
  }
}
