import React, { Component } from 'react';
import cssConstants from '../constants/css';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import ArrowIcon from '@material-ui/icons/ChevronRight';
import BlockThumbnails from '../components/BlockThumbnails';
import TranslatableButton from '../components/TranslatableButton';
import BlockTypes from 'composer-blocks/lib/constants/BlockTypes';
import ContentList from './ContentList';
import Properties from './Properties';
import PageEditor from './PageEditor';

const styles = (theme) => ({
  sideBar: {
    width: cssConstants.sideBar.width,
    flexShrink: 0,
    flexGrow: 0,
    padding: '1rem',
    boxSizing: 'border-box',
    minHeight: '100%',
    overflow: 'auto',
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  blockTypeIcon: {
    position: 'absolute',
    textAlign: 'center',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '-1.5rem',
    '& img': {
      height: '2rem',
      width: '1.5rem',
      objectFit: 'contain',
      display: 'block'
    }
  },
  clipboardContainer: {
    paddingTop: '1rem',
  },
  clipboardContent: {
    paddingTop: '1rem',
  },
});

const buttonStyles = {
  root: {
    width: '100%'
  },
  label: {
    justifyContent: 'space-between',
    marginLeft: '2rem',
    position: 'relative'
  }
}

class SideBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedBlockTypeId: null
    };
  }

  closeThumbnails = () => {
    this.setState({
      selectedBlockTypeId: null
    });
  };

  openThumbnails = (blockTypeId) => () => this.setState({
    selectedBlockTypeId: blockTypeId
  });

  render() {
    const { classes, showContentList, activeContentId, activePageId } = this.props;
    const { selectedBlockTypeId } = this.state;

    return (
      <div className={classes.sideBar}>
        {showContentList ? (
          <ContentList />
        ) : activeContentId ? (
          <Properties />
        ) : activePageId ? (
          <PageEditor />
        ) : selectedBlockTypeId ? (
          <BlockThumbnails blockTypeId={selectedBlockTypeId} closeThumbnails={this.closeThumbnails}/>
        ) : (
          <div>
            {Object.keys(BlockTypes).map(k => BlockTypes[k]).map((blockType) => (
              <TranslatableButton
                key={blockType.id}
                onClick={this.openThumbnails(blockType.id)}
                tPath={`block_types.${blockType.id}`}
                leftIcon={
                  <span className={classes.blockTypeIcon}>
                    <img src={blockType.icon} alt={blockType.id} />
                  </span>
                }
                icon={<ArrowIcon />}
                buttonStyles={buttonStyles}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ app, pages }) => ({
  activePageId: app.activePageId,
  activeContentId: app.activeContentId,
  showContentList: app.showContentList,
  pages
});

export default connect(mapStateToProps)(injectSheet(styles)(SideBar));
