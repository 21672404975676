import { FREE } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'free_iframe',
  type: FREE,
  name: 'Free iframe',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/free/free_iframe.jpg',
  rootContent: {
    type: contentTypes.IFRAME,
  }
}
