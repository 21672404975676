import { NETWORKING_LOGIN } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'networking_login_link',
  type: NETWORKING_LOGIN,
  name: 'Networking Login Link',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/networking_login/networking_login_link.png',
  rootContent: {
    type: contentTypes.ROW,
    contents: [{
      type: contentTypes.COLUMN,
      properties: {
        offsetSmall: {type: 'responsive', value: 0},
        offsetMedium: {type: 'responsive', value: 0},
        offsetLarge: {type: 'responsive', value: 0},
        small: {type: 'responsive', value: 12},
        medium: {type: 'responsive', value: 12},
        large: {type: 'responsive', value: 12}
      },
      contents: [
        {
          type: contentTypes.NETWORKING_LOGIN_LINK
        }
      ]
    }]
  }
}
