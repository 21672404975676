import React, { Component } from 'react';
import { connect } from 'react-redux';

const defaultRenderer = attendeeType => (
  <div key={attendeeType.id}>{attendeeType.name}</div>
)

class AttendeeTypesList extends Component {

  render() {
    const { attendeeTypes, renderItem = defaultRenderer } = this.props;

    return (
      <React.Fragment>
        {attendeeTypes && Object.values(attendeeTypes).map(renderItem)}
      </React.Fragment>
    );
  }

}

const mapStateToProps = ({ site: { entities: { attendee_types } } }) => ({
  attendeeTypes: attendee_types
})

export default connect(mapStateToProps)(AttendeeTypesList);
