import { NAVBAR } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'navbar_11',
  type: NAVBAR,
  name: 'Navbar 11',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/navbar/navbar_11.png',
  rootContent: {
    type: contentTypes.NAVBAR,
    properties: {
      backgroundColor: {type: 'css', value: '#343A40',editable: true},
      color: {type: 'css', value: '#E9EAEA',editable: true},
      padding: {type: 'css', value: '0.5rem 1rem'},
      fontSize: {type: 'css', value: 'small', editable: true},
      logoSrc: {type: 'custom', value: '', editable: true},
      logoHeight: {type: 'custom', value: '3.5rem'},
      navItemHoverUnderline: {type: 'custom', value: '#FC5658', editable: true},
      navItemActiveUnderline: {type: 'custom', value: '#FC5658', editable: true},
      navItemPadding: {type: 'custom', value: '0.5rem'},
    },
    contents: []
  }
}
