import { combineReducers } from 'redux';
import {
  SET_ACTIVE_PAGE,
  SET_ACTIVE_CONTENT,
  SET_MODIFIED_CONTENT,
  SET_MODIFIED_PROPERTY,
  RESTORE_CONTENT,
  SAVE_MODIFIED_ITEMS,
  TOGGLE_CONTENT_LIST,
  OPEN_MODAL,
  CLOSE_MODAL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SAVE_COMPOSER_SETTING_SUCCESS,
  SAVE_COMPOSER_SETTING_FAILURE,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  SET_SNACKBAR_PROPS,
  PUBLISH_MODIFIED_ITEMS,
  PUBLISH_SITE_SUCCESS,
  PUBLISH_SITE_FAILURE
} from "../actions/app";
import { INSERT_BLOCK, MOVE_BLOCK, DELETE_BLOCK, INSERT_NAVBAR, DELETE_NAVBAR, INSERT_FOOTER, DELETE_FOOTER, COPY_BLOCK_TO_CLIPBOARD } from '../actions/blocks';
import { INSERT_CONTENT, MOVE_CONTENT, DELETE_CONTENT, COPY_CONTENT_TO_CLIPBOARD } from '../actions/contents';
import { INSERT_PAGE, MOVE_PAGE, SET_PAGE_NAME, SET_PAGE_FRIENDLY_URL, SET_PAGE_HIDE_NAVBAR, SET_PAGE_HIDE_FOOTER, DELETE_PAGE, SET_PAGE_HIDE_FROM_NAVBAR, SET_PAGE_HIDE_FROM_FOOTER } from '../actions/pages';
import { UPDATE_GLOBAL_SETTING } from '../actions/globalSettings';
import { SET_CURRENT_PAGE, COMPOSER_SETTINGS_SUCCESS } from 'composer-blocks/lib/actions/site';
import { deleteWhere } from 'composer-blocks/lib/helpers/hasMany';
import { saveToken } from "../helpers/auth";
import { getPublishedDraftFromResponse } from 'composer-blocks/lib/helpers/api';


const activePageId = (state = null, action) => {
  switch (action.type) {
    case SET_ACTIVE_PAGE:
      return action.activePageId;
    case SET_CURRENT_PAGE:
      return (action.id === state) ? state : null;
    case DELETE_PAGE:
      return (action.id === state) ? null : state;
    default:
      return state;
  }
};

const activeContentId = (state = null, action) => {
  switch (action.type) {
    case SET_ACTIVE_CONTENT:
      return action.activeContentId;
    case DELETE_CONTENT:
    case DELETE_BLOCK:
      if (!!state && action.childContents.indexOf(state.id) > -1) return null;
      return state;
    case SET_CURRENT_PAGE:
      return null;
    default:
      return state;
  }
};

const contents = (state = {}, action) => {
  switch (action.type) {
    case SET_MODIFIED_CONTENT:
      const { originalContent, value } = action;
      const modifiedContent = {
        ...originalContent,
        value
      };

      return {
        ...state,
        [originalContent.id]: modifiedContent
      };
    case RESTORE_CONTENT:
      const modifiedContents = { ...state };

      delete modifiedContents[action.contentId];

      return modifiedContents;
    case INSERT_CONTENT: {
      const { contentId, parentId } = action;

      const isParentModified = !!state[parentId];
      if (!isParentModified) {
        return state;
      }

      const newChildContents = !!state[parentId].childContents ? [
        ...state[parentId].childContents,
        contentId
      ] : [contentId];

      const newParent = {
        [parentId]: {
          ...state[parentId],
          childContents: newChildContents
        }
      };

      return {
        ...state,
        ...newParent
      };
    }
    case MOVE_CONTENT: {
      const { oldParentId, newParentId, newIndex, id } = action;

      const isOldParentModified = !!state[oldParentId];
      const isNewParentModified = !!state[newParentId];

      if (!isOldParentModified && !isNewParentModified) {
        return state;
      }

      const newState = {...state};

      if (isOldParentModified) {
        let newChildrenOfOldParent = [...state[oldParentId].childContents];
        const oldIndex = newChildrenOfOldParent.indexOf(id);

        newChildrenOfOldParent.splice(oldIndex, 1);

        if (oldParentId === newParentId) {
          newChildrenOfOldParent.splice(newIndex, 0, id);
        }

        newState[oldParentId] = {
          ...state[oldParentId],
          childContents: newChildrenOfOldParent
        };
      }

      if (isNewParentModified && oldParentId !== newParentId) {
        let newChildrenOfNewParent = state[newParentId].childContents ? [...state[newParentId].childContents] : [];
        newChildrenOfNewParent.splice(newIndex, 0, id);

        newState[newParentId] = {
          ...state[newParentId],
          childContents: newChildrenOfNewParent
        };
        newState[id] = {
          ...state[id],
          parentId: newParentId
        }
      }

      return newState;
    }
    case PUBLISH_MODIFIED_ITEMS:
    case SAVE_MODIFIED_ITEMS:
      return {};
    case DELETE_CONTENT: {
      const { id, childContents, parentId } = action;
      const isParentModified = !!state[parentId];
      const isModified = !!state[id];
      if (!isModified && !isParentModified) {
        return state;
      }

      const newState = {...deleteWhere(state, 'id', childContents)};

      if (isParentModified) {
        const newParent = {
          ...state[parentId],
        }

        const newChildContents = [...newParent.childContents];

        const index = newChildContents.indexOf(id);
        newChildContents.splice(index, 1);
        newParent.childContents = newChildContents;

        newState[parentId] = newParent;
      }

      return newState;
    }
    case DELETE_BLOCK:
      const { childContents } = action;
      return deleteWhere(state, 'id', childContents);
    default:
      return state;
  }
};

const properties = (state = {}, action) => {
  switch (action.type) {
    case SET_MODIFIED_PROPERTY:
      const { originalProperty, value } = action;
      const modifiedProperty = {
        ...originalProperty,
        value
      };

      return {
        ...state,
        [originalProperty.id]: modifiedProperty
      };
    case RESTORE_CONTENT:
      return deleteWhere(state, 'parentId', action.contentId);
    case PUBLISH_MODIFIED_ITEMS:
    case SAVE_MODIFIED_ITEMS:
      return {};
    case DELETE_CONTENT:
    case DELETE_BLOCK:
      const { childProperties } = action;
      return deleteWhere(state, 'id', childProperties);
    default:
      return state;
  }
};

const modifiedItems = combineReducers({
  contents,
  properties
});

const showContentList = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_CONTENT_LIST:
      return !state;
    case SET_ACTIVE_CONTENT:
    case SET_CURRENT_PAGE:
      return false;
    default:
      return state;
  }
};

const modalProps = (state = null, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...action.modalProps
      };
    case CLOSE_MODAL:
      return null;
    default:
      return state
  }
};

const login = (state = { logged: false, isFetching: false, error: null }, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case LOGIN_SUCCESS:
      saveToken(action.response.auth_token);

      return {
        ...state,
        logged: true,
        isFetching: false
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    default:
      return state;
  }
};

const saveChanges = (state = { active: false, isFetching: false, error: null }, action) => {
  switch (action.type) {
    case SET_MODIFIED_CONTENT:
    case SET_MODIFIED_PROPERTY:
    case INSERT_BLOCK:
    case MOVE_BLOCK:
    case DELETE_BLOCK:
    case INSERT_NAVBAR:
    case DELETE_NAVBAR:
    case INSERT_FOOTER:
    case DELETE_FOOTER:
    case INSERT_CONTENT:
    case MOVE_CONTENT:
    case DELETE_CONTENT:
    case INSERT_PAGE:
    case MOVE_PAGE:
    case SET_PAGE_NAME:
    case SET_PAGE_FRIENDLY_URL:
    case SET_PAGE_HIDE_NAVBAR:
    case SET_PAGE_HIDE_FROM_NAVBAR:
    case SET_PAGE_HIDE_FOOTER:
    case SET_PAGE_HIDE_FROM_FOOTER:
    case UPDATE_GLOBAL_SETTING:
      return {
        ...state,
        active: true
      };
    case DELETE_PAGE:
      return {
        ...state,
        active: true
      };
    case SAVE_MODIFIED_ITEMS:
      return {
        ...state,
        isFetching: true
      };
    case SAVE_COMPOSER_SETTING_SUCCESS:
    case PUBLISH_SITE_SUCCESS:

      return {
        active: false,
        isFetching: false,
        error: null
      };
    case SAVE_COMPOSER_SETTING_FAILURE:
      return {
        active: true,
        isFetching: false,
        error: action.error
      };
    default:
      return state;
  }
};

// getPublishedDraftFromResponse allows to know if the publish button must be activated at start
// because there are changes saved but not published
const publishChanges = (state = { active: false, isFetching: false, error: null }, action) => {
  switch (action.type) {
    case COMPOSER_SETTINGS_SUCCESS:
      return {
        ...state,
        active: !getPublishedDraftFromResponse(action)
      };
    case SET_MODIFIED_CONTENT:
    case SET_MODIFIED_PROPERTY:
    case INSERT_BLOCK:
    case MOVE_BLOCK:
    case DELETE_BLOCK:
    case INSERT_NAVBAR:
    case DELETE_NAVBAR:
    case INSERT_FOOTER:
    case DELETE_FOOTER:
    case INSERT_CONTENT:
    case MOVE_CONTENT:
    case DELETE_CONTENT:
    case INSERT_PAGE:
    case MOVE_PAGE:
    case SET_PAGE_NAME:
    case SET_PAGE_FRIENDLY_URL:
    case SET_PAGE_HIDE_NAVBAR:
    case SET_PAGE_HIDE_FROM_NAVBAR:
    case SET_PAGE_HIDE_FOOTER:
    case SET_PAGE_HIDE_FROM_FOOTER:
    case UPDATE_GLOBAL_SETTING:
    case DELETE_PAGE:
      return {
        ...state,
        active: true
      };
    case PUBLISH_MODIFIED_ITEMS:
      return {
        ...state,
        isFetching: true
      };
    case PUBLISH_SITE_SUCCESS:
    case PUBLISH_SITE_FAILURE:
      return {
        active: false,
        isFetching: false,
        error: null
      };
    case SAVE_MODIFIED_ITEMS:
    case SAVE_COMPOSER_SETTING_SUCCESS:
    case SAVE_COMPOSER_SETTING_FAILURE:
    default:
      return state;
  }
};

const uploadFile = (state = { isFetching: false, error: null }, action) => {
  switch (action.type) {
    case UPLOAD_FILE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    default:
      return state;
  }
};

const snackbarProps = (state = { message: null, error: false }, action) => {
  switch (action.type) {
    case SAVE_COMPOSER_SETTING_SUCCESS:
      return {
        message: 'Changes successfully saved!',
        error: false
      };
    case PUBLISH_SITE_SUCCESS:
      return {
        message: 'Your changes will be successfully published in approximately two minutes.',
        error: false
      }
    case PUBLISH_SITE_FAILURE:
    case SAVE_COMPOSER_SETTING_FAILURE:
      return {
        message: action.error,
        error: true
      };
    case SET_SNACKBAR_PROPS:
      return {
        message: action.message,
        error: action.error
      };
    default:
      return state;
  }
};

const clipboard = (state = { template: null, templateType: null }, action) => {
  switch (action.type) {
    case COPY_BLOCK_TO_CLIPBOARD:
      return {
        template: action.template,
        templateType: 'block',
      }
    case COPY_CONTENT_TO_CLIPBOARD:
      return {
        template: action.template,
        templateType: 'content',
      }
    default:
      return state;
  }
}

export default combineReducers({
  activeContentId,
  activePageId,
  modifiedItems,
  showContentList,
  modalProps,
  login,
  saveChanges,
  publishChanges,
  uploadFile,
  snackbarProps,
  clipboard,
});
