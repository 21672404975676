import React from 'react';
import { translate } from 'react-i18next';
import injectSheet from 'react-jss';
import BackIcon from '@material-ui/icons/ArrowBack';
import TranslatableButton from './TranslatableButton';
import DraggableThumbnail from '../containers/DraggableThumbnail';
import BlockTypes from 'composer-blocks/lib/constants/BlockTypes';
import { blocksByType } from '../constants/BlockTemplates';

const styles = {
  title: {
    width: 'fit-content',
    margin: '1rem auto',
    position: 'relative'
  },
  blockTypeIcon: {
    position: 'absolute',
    textAlign: 'center',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '-2.5rem',
    '& img': {
      height: '2rem',
      width: '1.5rem',
      objectFit: 'contain',
      display: 'block'
    }
  }
}

export default injectSheet(styles)(translate('Forms')(({ blockTypeId, closeThumbnails, classes, t }) => {
  const blocks = blocksByType[blockTypeId];
  const blockType = BlockTypes[blockTypeId];

  return (
    <div className={`${blockTypeId}-thumbnails`}>
      <div className={classes.title}>
        {t(`block_types.${blockType.id}`)}
        <span className={classes.blockTypeIcon}>
          <img src={blockType.icon} alt={blockType.id} />
        </span>
      </div>
      <TranslatableButton
        onClick={closeThumbnails}
        leftIcon={<BackIcon />}
        tPath="buttons.back"
      />
      {blocks.map(blockTemplateId =>
        <DraggableThumbnail
          key={blockTemplateId}
          blockTemplateId={blockTemplateId}
        />
      )}
    </div>
  );
}));
