import { CUSTOM } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'custom_16',
  type: CUSTOM,
  name: 'Custom block with expandable row',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/custom/custom_16.png',
  rootContent: {
    type: contentTypes.ROW,
    properties: {
      backgroundRepeat: {type: 'css', value: 'no-repeat', editable: true},
      backgroundSize: {type: 'css', value:'cover', editable: true},
      backgroundPosition: {type: 'css', value:'center', editable: true},
      minHeight: {type: 'css', value: '3rem', editable: true}
    },
    contents: [
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 12, editable: true},
          medium: {type: 'responsive', value: 12, editable: true},
        },
        contents: [
          {
            type: contentTypes.EXPANDABLE_ROW,
            properties: {
              titleColor: {type: 'custom', value: '#00BD9C', editable: true},
              buttonColor: {type: 'custom', value: '#00BD9C', editable: true},
            },
            contents: [
              {
                type: contentTypes.COLUMN,
                properties: {
                  large: {type: 'responsive', value: 10, editable: true},
                  medium: {type: 'responsive', value: 10, editable: true},
                  offsetLarge: {type: 'responsive', value: 1, editable: true},
                  offsetMedium: {type: 'responsive', value: 1, editable: true},
                },
                contents: [
                  {
                    type: contentTypes.TEXT,
                    properties: {
                      textAlign: {type: 'css', value: 'justify', editable: true},
                      fontSize: {type: 'css', value: '1em', editable: true},
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
    ]
  }
}
