import React, { Component } from 'react';
import i18n from './helpers/i18n';
import Composer from './pages/Composer';
import Preview from './pages/Preview';
import GlobalSettings from './pages/GlobalSettings';
import Errors from './pages/Errors';
import Login from './containers/Login';
import { loggedIn } from "./helpers/auth";
import { Switch, Route } from 'react-router-dom';

class App extends Component {
  componentWillMount() {
    i18n.changeLanguage(this.props.match.params.locale);
  }

  componentWillReceiveProps(nextProps) {
    const old_locale = this.props.match.params.locale;
    const new_locale = nextProps.match.params.locale;

    if (old_locale !== new_locale) i18n.changeLanguage(new_locale);
  }

  render() {
    const { match } = this.props;

    return (
      <div className="App">
        <Route path={`${match.path}/eventtia-sso/:sso_token`} component={Login}/>
        {loggedIn() ? (
          <Switch>
            <Route exact path={`${match.path}`} component={Composer}/>
            <Route exact path={`${match.path}/preview/:page?`} component={Preview}/>
            <Route exact path={`${match.path}/settings`} component={GlobalSettings}/>
          </Switch>
        ) : (
          <Route render={() => <Errors message="Please log in through Eventtia to use composer" />} />
        )}
      </div>
    );
  }
}

export default App;
