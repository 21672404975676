import { CONTACT } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'contact_1',
  type: CONTACT,
  name: 'Basic Contact Form',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/contact/contact_1.png',
  rootContent: {
    type: contentTypes.ROW,
    contents: [
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 6, editable: true},
          offsetLarge: {type: 'responsive', value: 3, editable: true},
          medium: {type: 'responsive', value: 6, editable: true},
          offsetMedium: {type: 'responsive', value: 3, editable: true},
        },
        contents: [
          {
            type: contentTypes.CONTACT_FORM,
          }
        ]
      },
    ]
  }
}
