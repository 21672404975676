import React, { Component } from 'react';
import Code from '@material-ui/icons/Code';
import injectSheet from 'react-jss';
import FullScreenModal from './FullScreenModal';
import TranslatableIconButton from './TranslatableIconButton';
import CodeMirror from 'react-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';

const styles = {
  CodeEditor: {
    display: 'inline-block',
  },
  input: {
    textAlign: 'right'
  },
  iconButton: {
    marginRight: '-0.75rem'
  },
  visualContainer: {
    background: 'white',
    color: 'black'
  },
  codeContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  notes: {
    marginTop: 0,
    marginBottom: '1rem',
  },
  editor: {
    fontSize: 'medium',
    height: '100%',
    flex: '1',
    position: 'relative',
    '& .CodeMirror': {
      position: 'absolute',
      height: '100%',
      width: '100%',
    }
  },
  applyButton: {
    marginTop: '1rem'
  }
}

class CodeEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      currentTab: props.tabName,
    };
  }

  openModal = () => this.setState({ open: true })
  closeModal = () => this.setState({ open: false })
  setCurrentTab = (tabId) => () => this.setState({ currentTab: tabId })

  render() {
    const { classes, value, tabName, note, mode } = this.props;
    const { currentTab } = this.state;

    const options = {
      mode,
			lineNumbers: true,
      theme: 'material'
		};
    const tabs = {
      [tabName]: (
        <div className={classes.codeContainer}>
          {!!note && (
            <p className={classes.notes}>{note}</p>
          )}
          <CodeMirror
            value={value}
            onChange={this.props.onChange}
            options={options}
            className={classes.editor}
          />
        </div>
      )
    }

    return (
      <div className={classes.CodeEditor}>
        <TranslatableIconButton tPath="buttons.edit_code" onClick={this.openModal} Icon={Code} buttonProps={{className: classes.iconButton}} />
        {this.state.open && (
          <FullScreenModal
            tabs={tabs}
            currentTab={currentTab}
            closeModal={this.closeModal}
            setCurrentTab={this.setCurrentTab}
          />
        )}
      </div>
    );
  }

}

export default injectSheet(styles)(CodeEditor);
