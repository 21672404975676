import { setCurrentPage } from "composer-blocks/lib/actions/site";
import { duplicateBlock } from './blocks';
import { recursivelyGetChildren } from './contents';
import generateId from '../helpers/ids';

export const INSERT_PAGE = 'INSERT_PAGE';
export const insertPage = (options) => {
  return function(dispatch) {
    const id = generateId();
    const { hideNavbar, hideFromNavbar, hideFooter, hideFromFooter, name } = options || {};

    dispatch({
      type: INSERT_PAGE,
      id,
      hideNavbar,
      hideFromNavbar,
      hideFooter,
      hideFromFooter,
      name
    });

    dispatch(setCurrentPage(id));

    return Promise.resolve(id);
  }
};

export const duplicatePage = (originalPageId) => {
  return function (dispatch, getState) {
    const state = getState();
    if (!originalPageId) originalPageId = state.app.activePageId;
    const originalPage = state.pages[originalPageId];
    const options = {
      hideNavbar: originalPage.hideNavbar,
      hideFromNavbar: originalPage.hideFromNavbar,
      hideFooter: originalPage.hideFooter,
      hideFromFooter: originalPage.hideFromFooter,
    };
    dispatch(insertPage(options)).then(newPageId => {
      if (!!originalPage.blocks) {
        for (var i = 0; i < originalPage.blocks.length; i++) {
          dispatch(duplicateBlock({
            blockId: originalPage.blocks[i],
            page: newPageId,
            index: i
          }))
        }
      }
    })
  }
}

export const MOVE_PAGE = 'MOVE_PAGE';
export const movePage = (id, newIndex) => ({
  type: MOVE_PAGE,
  id,
  newIndex
});

export const DELETE_PAGE = 'DELETE_PAGE';
export const deletePage = () => {
  return function(dispatch, getState) {
    const { app, pages, contents, properties } = getState();
    const { activePageId } = app;
    const childBlocks = pages[activePageId].blocks || [];
    const childContents = [];
    const childProperties = [];
    for (let i = 0; i < childBlocks.length; i++) {
      recursivelyGetChildren(childBlocks[i], contents, properties, childContents, childProperties);
    }

    if (Object.keys(pages).length === 1) {
      dispatch(insertPage());
    } else {
      const pageToSwitchTo = Object.keys(pages).filter(pageId => pageId !== activePageId)[0];
      dispatch(setCurrentPage(pageToSwitchTo));
    }

    dispatch({
      type: DELETE_PAGE,
      id: activePageId,
      childBlocks,
      childContents,
      childProperties
    });
  }
};

export const SET_PAGE_NAME = 'SET_PAGE_NAME';
export const setPageName = (name) => {
  return function (dispatch, getState) {
    dispatch({
      type: SET_PAGE_NAME,
      id: getState().app.activePageId,
      name
    })
  }
};

export const SET_PAGE_FRIENDLY_URL = 'SET_PAGE_FRIENDLY_URL';
export const setPageFriendlyUrl = (friendlyUrl) => {
  return function (dispatch, getState) {
    dispatch({
      type: SET_PAGE_FRIENDLY_URL,
      id: getState().app.activePageId,
      friendlyUrl
    })
  }
};

export const SET_PAGE_HIDE_NAVBAR = 'SET_PAGE_HIDE_NAVBAR';
export const setPageHideNavbar = (hideNavbar) => {
  return function (dispatch, getState) {
    dispatch({
      type: SET_PAGE_HIDE_NAVBAR,
      id: getState().app.activePageId,
      hideNavbar
    })
  }
};

export const SET_PAGE_HIDE_FROM_NAVBAR = 'SET_PAGE_HIDE_FROM_NAVBAR';
export const setPageHideFromNavbar = (hideFromNavbar) => {
  return function (dispatch, getState) {
    dispatch({
      type: SET_PAGE_HIDE_FROM_NAVBAR,
      id: getState().app.activePageId,
      hideFromNavbar
    })
  }
};


export const SET_PAGE_HIDE_FOOTER = 'SET_PAGE_HIDE_FOOTER';
export const setPageHideFooter = (hideFooter) => {
  return function (dispatch, getState) {
    dispatch({
      type: SET_PAGE_HIDE_FOOTER,
      id: getState().app.activePageId,
      hideFooter
    })
  }
};

export const SET_PAGE_HIDE_FROM_FOOTER = 'SET_PAGE_HIDE_FROM_FOOTER';
export const setPageHideFromFooter = (hideFromFooter) => {
  return function (dispatch, getState) {
    dispatch({
      type: SET_PAGE_HIDE_FROM_FOOTER,
      id: getState().app.activePageId,
      hideFromFooter
    })
  }
};
