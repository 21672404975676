import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import BackIcon from '@material-ui/icons/ArrowBack';
import injectSheet from 'react-jss';
import TranslatableButton from '../components/TranslatableButton';
import ResponsiveModesMenu from '../containers/ResponsiveModesMenu';
import { fetchComposerSettingsIfNeeded } from "../actions/app";
import LivePreview from '../containers/LivePreview';
import { I18nextProvider } from 'react-i18next';
import  i18nSite  from '../helpers/i18nSite.js';

const styles = {
  Toolbar: {
    minHeight: 'initial'
  }
}

const AppBarWithBackButton = injectSheet(styles)(({ classes, match }) => (
  <AppBar position="fixed">
    <Toolbar className={classes.Toolbar}>
      <TranslatableButton
        buttonProps={{
          component: Link,
          to: `/${match.params.locale}`
        }}
        leftIcon={<BackIcon />}
        tPath='buttons.back_to_composer'
      />
      <ResponsiveModesMenu />
    </Toolbar>
  </AppBar>
));

class Preview extends Component {
  componentWillMount() {
    const { fetchComposerSettingsIfNeeded, match } = this.props;

    fetchComposerSettingsIfNeeded(match.params.page);



  }

  onUnload = (event) => {
    if (this.props.pendingChanges) {
      event.returnValue = "You have unsaved changes"
    }
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload)
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload)
  }


  render() {
    const { match } = this.props;

    return (
      <div className="Preview">
        <AppBarWithBackButton match={match} />
        <I18nextProvider i18n={i18nSite}>
          <LivePreview editable={false} preview={true}/>
        </I18nextProvider>
      </div>
    );
  }
}

const mapStateToProps = ({ app }) => ({
  pendingChanges: app.saveChanges.active,
});

const mapDispatchToProps = {
  fetchComposerSettingsIfNeeded
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Preview));
