import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import injectSheet from 'react-jss';

const styles = {
  ContentDropZone: {
    position: 'relative',
    minHeight: '100%',
    pointerEvents: ({ canDrop }) => canDrop ? 'auto' : 'none',
    '&.canDrop': {
      background: 'rgba(255, 255, 255, 0.3)'
    },
    '&.hovered': {
      background: 'rgba(255, 255, 100, 0.3)'
    },
    '&.left': {
      borderLeft: ({ canDrop, hovered }) => (canDrop && hovered) ? '5px solid rgba(50, 100, 255, 0.5)' : 'none',
    },
    '&.right': {
      borderRight: ({ canDrop, hovered }) => (canDrop && hovered) ? '5px solid rgba(50, 100, 255, 0.5)' : 'none',
    },
    '&.top': {
      borderTop: ({ canDrop, hovered }) => (canDrop && hovered) ? '5px solid rgba(50, 100, 255, 0.5)' : 'none',
    },
    '&.bottom': {
      borderBottom: ({ canDrop, hovered }) => (canDrop && hovered) ? '5px solid rgba(50, 100, 255, 0.5)' : 'none',
    },
  },
};

const targetSpec = {
  drop(props, monitor, component) {
    const { index, parentId } = props;
    if (!monitor.didDrop()) {
      return { index, parentId };
    }
  },
  canDrop(props, monitor) {
    return (monitor.getItem().contentId !== props.contentId) && (monitor.getItem().parentId === props.parentId);
  }
};

const collectDrop = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop(),
    hovered: monitor.isOver(),
    otherItemIndex: monitor.getItem() && monitor.getItem().contentIndex,
    otherItemParentId: monitor.getItem() && monitor.getItem().parentId,
  }
};

class ContentDropZone extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    canDrop: PropTypes.bool.isRequired,
    hovered: PropTypes.bool.isRequired,
  }

  render() {
    const { hovered, canDrop, connectDropTarget, index, parentId,
      otherItemIndex, otherItemParentId, isColumn, classes, className, children,
      style, onClick
    } = this.props;
    const showBorder = hovered && canDrop;
    const showBorderAfter = showBorder && (parentId === otherItemParentId) && children && otherItemIndex !== undefined && otherItemIndex < index;
    const showBorderBefore = showBorder && !showBorderAfter;
    const borderClass = showBorderBefore ? (isColumn ? 'left' : 'top') : showBorderAfter ? (isColumn ? 'right' : 'bottom') : '';
    return connectDropTarget(
      <div
        className={
          classes.ContentDropZone + ' '
          + (className || '') + ' '
          + borderClass + ' '
          + (canDrop ? 'canDrop' : '') + ' '
          + (showBorder ? 'hovered' : '')
        }
        style={style}
        onClick={onClick}
      >
        {children}
      </div>
    );
  }

}

const droppableTypes = ({ isColumn }) => (isColumn ? 'column' : 'content');

const enhance = compose(
  DropTarget(droppableTypes, targetSpec, collectDrop),
  injectSheet(styles)
);

export default enhance(ContentDropZone);
