import { WORKSHOPS } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'workshops_1',
  type: WORKSHOPS,
  name: 'Activity cards',
  thumbnail: 'https://eventtia-lobbies.s3.amazonaws.com/workshops_1v2.png',
  // thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/workshops/workshops_1.png',
  rootContent: {
    type: contentTypes.WORKSHOP_LIST,
    properties: {
      backgroundColor: {type: 'css', value: '#FFFFFF', editable: true},
    },
  }
}
