import { SPONSORS } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'sponsors_2',
  type: SPONSORS,
  name: 'Sponsors cards',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/sponsors/sponsors_2.jpg',
  rootContent: {
    type: contentTypes.SPONSOR_CARDS,
    properties: {
      backgroundColor: {type: 'custom', value: '#F6F6FA', editable: true},
      secondaryBackgroundColor: {type: 'custom', value: '#FFFFFF', editable: true},
      color: {type: 'custom', value: '#555555', editable: true},
      descriptionText: {type: 'custom', value: '', editable: true},
    }
  }
}
