import { NAVBAR } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'navbar_2',
  type: NAVBAR,
  name: 'Navbar 2',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/navbar/navbar_2.png',
  rootContent: {
    type: contentTypes.NAVBAR,
    properties: {
      backgroundColor: {type: 'css', value: 'white', editable: true},
      color: {type: 'css', value: '#000000', editable: true},
      padding: {type: 'css', value: '0.5rem 1rem'},
      fontSize: {type: 'css', value: 'small', editable: true},
      logoSrc: {type: 'custom', value: '', editable: true},
      logoHeight: {type: 'custom', value: '3.5rem'},
      navItemFont: {type: 'custom', value: '\'Raleway\', sans-serif;'},
      navItemFontWeight: {type: 'custom', value: '500'},
      navItemHoverBorder: {type: 'custom', value: '#000000', editable: true},
      navItemActiveBorder: {type: 'custom', value: '#E0E0E0', editable: true},
      navItemPadding: {type: 'custom', value: '0.5rem 0.5rem'},
      navItemHoverTextColor: {type: 'custom', value: '#717470'},
      navItemBorderRadius: {type: 'custom', value: '4px', editable: true},
      navItemActiveBorderThickness: {type: 'custom', value:'1.5px', editable: true },
      navItemActiveTextColor: {type: 'custom', value: '#947f42', editable: true},
      socialMedia: {type: 'custom', value: true, editable: true},
      facebookLink: {type: 'custom', value: 'facebook.com', editable: true},
      twitterLink: {type: 'custom', value: 'twitter.com', editable: true},
      linkedInLink: {type: 'custom', value: 'linkedin.com', editable: true},
      instagramLink: {type: 'custom', value: 'instagram.com', editable: true},
      socialMediaIconColor: {type: 'custom', value: '#947f42', editable: true}
    },
    contents: []
  }
}
