import { WORKSHOPS } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'workshops_2',
  type: WORKSHOPS,
  name: 'Activity zebra',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/workshops/workshops_2.png',
  rootContent: {
    type: contentTypes.WORKSHOP_ZEBRA,
    properties: {
      backgroundColor: {type: 'css', value: '#FFFFFF', editable: true},
      workshopTextColor: {type: 'custom', value: '#4A4A4A', editable: true},
      workshopColor: {type: 'custom', value: '#7C139E', editable: true},
    }
  }
}
