import { SPEAKERS } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'speakers_1',
  type: SPEAKERS,
  name: 'Speakers list',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/speakers/speakers_1.png',
  rootContent: {
    type: contentTypes.SPEAKER_LIST,
    properties: {
      padding: { type: 'css', value: 24 },
      backgroundColor: {type: 'css', value: '#FFFFFF', editable: true},
      speakerTextColor: {type: 'custom', value: '#000000', editable: true},
      speakerSubTextColor: {type: 'custom', value: '#000000', editable: true},
      speakerCardBackgroundColor: {type: 'custom', value: '#FFFFFF', editable: true},
      speakerHoverTextColor: {type: 'custom', value: '#FFFFFF', editable: true},
      speakerHoverSubTextColor: {type: 'custom', value: '#FFFFFF', editable: true},
      speakerCardHoverBackgroundColor: {type: 'custom', value: '#F84A4A', editable: true},
      speakerCardSocialMediaColor: {type: 'custom', value: '#000000', editable: true},
      speakerPaginationSize: {type: 'custom', value: 8, editable: true},
      itemsPerRowDesktop: {type: 'custom', value: 3, editable: true},
      itemsPerRowTablet: {type: 'custom', value: 6, editable: true},
      itemsPerRowMobile: {type: 'custom', value: 12, editable: true},

    }
  }
}
