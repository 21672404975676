import { NAVBAR } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'navbar_4',
  type: NAVBAR,
  name: 'Navbar 4',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/navbar/navbar_4.png',
  rootContent: {
    type: contentTypes.NAVBAR,
    properties: {
      backgroundColor: {type: 'css', value: 'white',editable: true},
      color: {type: 'css', value: '#b9b9b9', editable: true},
      padding: {type: 'css', value: '0.5rem 1rem'},
      fontSize: {type: 'css', value: 'small', editable: true},
      logoSrc: {type: 'custom', value: '', editable: true},
      logoHeight: {type: 'custom', value: '3.5rem'},
      navItemHoverBorder: {type: 'custom', value: '#000000', editable: true},
      navItemActiveBorder: {type: 'custom', value: '#c3c3c3', editable: true},
      navItemPadding: {type: 'custom', value: '0.5rem'},
      navItemMargin: {type: 'custom', value: '0rem 0.5rem'},
      navItemBorderRadius: {type: 'custom', value: '2px', editable: true},
      navItemActiveBorderThickness: {type: 'custom', value:'1.5px', editable: true },
      logoPosition: {type: 'custom', value: 'middle'}
    },
    contents: []
  }
}
