import { CUSTOM } from 'composer-blocks/lib/constants/BlockTypes';
import * as contentTypes from 'composer-blocks/lib/constants/ContentTypes';

export default {
  id: 'custom_13',
  type: CUSTOM,
  name: 'Custom background purple',
  thumbnail: 'https://eventtia-composer.s3.amazonaws.com/assets/images/thumbnails/custom/custom_13.png',
  rootContent: {
    type: contentTypes.ROW,
    properties: {
      color: {type: 'css', value: ''},
      fontWeight: {type: 'css', value: 'bold'},
      padding: {type: 'css', value: '1rem'},
      display: {type: 'css', value: 'flex'},
      justifyContent: {type: 'css', value: 'flex-end'},
      backgroundImage: {type: 'css', value: 'linear-gradient(45deg, rgb(60, 14, 73) 0%, rgb(73, 14, 60) 100%)', editable: true}
    },
    contents: [
      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 4, editable: true},
          medium: {type: 'responsive', value: 12, editable: true},
        },
        contents: [
          {
            type: contentTypes.IMAGE,
            properties: {
              src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/icons/icon_technology_1.png', editable:true},
              objectFit: {type: 'css', value: 'contain'},
              width: {type: 'css', value:'6rem'},
              height: {type: 'css', value:'6rem'},
              marginLeft: {type: 'css', value: 'auto'},
              marginRight: {type: 'css', value: 'auto'},
              display: {type: 'css', value: 'block'}

            },
          },
          {
            type: contentTypes.TEXT,
            value: 'LOREM IPSUM',
            properties: {
              margin: {type: 'css', value: '1rem auto'},
              color: {type: 'css', value: 'white', editable: true},
              fontWeight: {type: 'css', value: 'bold'},
              padding: {type: 'css', value: '0 2rem 1rem 0'},
              fontSize: {type: 'css', value: '24px'},
              textAlign: {type: 'css', value: 'center'},
              width: {type: 'css', value:'80%'}


            }
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer lacinia tortor at blandit condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur eu pharetra libero. Aenean vestibulum vitae ligula sed tempor. Nulla euismod orci nec dui mattis mattis. Suspendisse sed sapien eu sapien eleifend consectetur vitae vel erat.',
            properties: {
              margin: {type: 'css', value: '1rem auto'},
              color: {type: 'css', value: '#ddd3e2', editable: true},
              fontWeight: {type: 'css', value: 'normal'},
              padding: {type: 'css', value: '0 1rem 1rem 0'},
              fontSize: {type: 'css', value: '14px'},
              textAlign: {type: 'css', value: 'center'},
              width: {type: 'css', value: '80%'},

            }

          },
          {
            type: contentTypes.TEXT,
            value: 'CONTACT US',
            properties: {
              margin: {type: 'css', value: '3rem auto'},
              padding: {type: 'css', value: '2px 2rem 2px 2rem'},
              textAlign: {type: 'css', value: 'center'},
              color: {type: 'css', value: '#3C0E49', editable: true},
              backgroundColor: {type: 'css', value: 'white', editable: true},
              fontWeight: {type: 'css', value: 'bold'},
              width: {type: 'css', value: '25%'},
              borderRadius: {type: 'css', value: '5rem'},
              minHeight: {type: 'css', value: '2rem'},
              lineHeight: {type: 'css', value: '2rem'}
            },
          }

        ]
      },




      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 4, editable: true},
          medium: {type: 'responsive', value: 12, editable: true},
        },
        contents: [

          {
            type: contentTypes.IMAGE,
            properties: {
              src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/icons/icon_technology_2.png', editable:true},
              objectFit: {type: 'css', value: 'contain'},
              width: {type: 'css', value:'6rem'},
              height: {type: 'css', value:'6rem'},
              marginLeft: {type: 'css', value: 'auto'},
              marginRight: {type: 'css', value: 'auto'},
              display: {type: 'css', value: 'block'}

            },
          },
          {
            type: contentTypes.TEXT,
            value: 'LOREM IPSUM',
            properties: {
              margin: {type: 'css', value: '1rem auto'},
              color: {type: 'css', value: 'white', editable: true},
              fontWeight: {type: 'css', value: 'bold'},
              padding: {type: 'css', value: '0 2rem 1rem 0'},
              fontSize: {type: 'css', value: '24px'},
              textAlign: {type: 'css', value: 'center'},
              width: {type: 'css', value:'80%'}


            }
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer lacinia tortor at blandit condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur eu pharetra libero. Aenean vestibulum vitae ligula sed tempor. Nulla euismod orci nec dui mattis mattis. Suspendisse sed sapien eu sapien eleifend consectetur vitae vel erat.',
            properties: {
              margin: {type: 'css', value: '1rem auto'},
              color: {type: 'css', value: '#ddd3e2', editable: true},
              fontWeight: {type: 'css', value: 'normal'},
              padding: {type: 'css', value: '0 1rem 1rem 0'},
              fontSize: {type: 'css', value: '14px'},
              textAlign: {type: 'css', value: 'center'},
              width: {type: 'css', value: '80%'},

            }

          },
          {
            type: contentTypes.TEXT,
            value: 'CONTACT US',
            properties: {
              margin: {type: 'css', value: '3rem auto'},
              padding: {type: 'css', value: '2px 2rem 2px 2rem'},
              textAlign: {type: 'css', value: 'center'},
              color: {type: 'css', value: '#450E49', editable: true},
              backgroundColor: {type: 'css', value: 'white', editable: true},
              fontWeight: {type: 'css', value: 'bold'},
              width: {type: 'css', value: '25%'},
              borderRadius: {type: 'css', value: '5rem'},
              minHeight: {type: 'css', value: '2rem'},
              lineHeight: {type: 'css', value: '2rem'}
            },
          }


        ]
      },




      {
        type: contentTypes.COLUMN,
        properties: {
          large: {type: 'responsive', value: 4, editable: true},
          medium: {type: 'responsive', value: 12, editable: true},
       },
        contents: [
          {
            type: contentTypes.IMAGE,
            properties: {
              src: {type: 'custom', value: 'https://eventtia-composer.s3.amazonaws.com/assets/images/icons/icon_technology_3.png', editable:true},
              objectFit: {type: 'css', value: 'contain'},
              width: {type: 'css', value:'6rem'},
              height: {type: 'css', value:'6rem'},
              marginLeft: {type: 'css', value: 'auto'},
              marginRight: {type: 'css', value: 'auto'},
              display: {type: 'css', value: 'block'}

            },
          },
          {
            type: contentTypes.TEXT,
            value: 'LOREM IPSUM',
            properties: {
              margin: {type: 'css', value: '1rem auto'},
              color: {type: 'css', value: 'white', editable: true},
              fontWeight: {type: 'css', value: 'bold'},
              padding: {type: 'css', value: '0 2rem 1rem 0'},
              fontSize: {type: 'css', value: '24px'},
              textAlign: {type: 'css', value: 'center'},
              width: {type: 'css', value:'80%'}


            }
          },
          {
            type: contentTypes.TEXT,
            value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer lacinia tortor at blandit condimentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur eu pharetra libero. Aenean vestibulum vitae ligula sed tempor. Nulla euismod orci nec dui mattis mattis. Suspendisse sed sapien eu sapien eleifend consectetur vitae vel erat.',
            properties: {
              margin: {type: 'css', value: '1rem auto'},
              color: {type: 'css', value: '#ddd3e2', editable: true},
              fontWeight: {type: 'css', value: 'normal'},
              padding: {type: 'css', value: '0 1rem 1rem 0'},
              fontSize: {type: 'css', value: '14px'},
              textAlign: {type: 'css', value: 'center'},
              width: {type: 'css', value: '80%'},

            }

          },
          {
            type: contentTypes.TEXT,
            value: 'CONTACT US',
            properties: {
              margin: {type: 'css', value: '3rem auto'},
              padding: {type: 'css', value: '2px 2rem 2px 2rem'},
              textAlign: {type: 'css', value: 'center'},
              color: {type: 'css', value: '#490E3C', editable: true},
              backgroundColor: {type: 'css', value: 'white', editable: true},
              fontWeight: {type: 'css', value: 'bold'},
              width: {type: 'css', value: '25%'},
              borderRadius: {type: 'css', value: '5rem'},
              minHeight: {type: 'css', value: '2rem'},
              lineHeight: {type: 'css', value: '2rem'}
            },
          }


        ]
      }

    ]
  }
}
