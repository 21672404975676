import React, { Component } from 'react';
import injectSheet from 'react-jss';

const styles = {
  Errors: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#ff9999',
    color: '#550000'
  }
};

class Errors extends Component {

  render() {
    const { classes, message } = this.props;

    return (
      <div className={classes.Errors}>
        <h2>{message}</h2>
      </div>
    );
  }

}

export default injectSheet(styles)(Errors);
